import { useState, useEffect } from 'react'
import { AutoSizer } from 'react-virtualized'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { useQueryClient } from 'react-query'

import {
  Box,
  useTheme,
  IconButton,
  useMediaQuery,
  Theme,
  TablePagination,
  Paper,
} from '@material-ui/core'
import { Cached as CachedIcon } from '@material-ui/icons'

import { CopyLabel, ToolsDataTable } from '~/components'

import TableEventos from './TableEventos'

import useAmbiente from '~/hooks/useAmbiente'

import {
  queryUseObterEventosTransmissaoAcompanhamento,
  useObterEventosTransmissaoAcompanhamento,
} from '~/hooks/queries/ESocial/useObterEventosTransmissaoAcompanhamento'
import { useStepperContext } from '~/components/StepperForm'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { queryUseObterTotalEventosGrupoAcompanhamento } from '~/hooks/queries/ESocial/useObterTotalEventosGrupoAcompanhamento'
import { DataContextProps } from '../..'
import { ESocialGrupoEventoConsultaEnum } from '~/@types/enums/ESocialGrupoEventoConsultaEnum'

interface AcompanhamentoAuditoriaProps {
  grupo: ESocialGrupoEventoConsultaEnum
}

export default function AcompanhamentoAuditoria(props: AcompanhamentoAuditoriaProps) {
  const { grupo } = props

  const [rowsExpanded, setRowExpanded] = useState<number[]>([])

  const [pagination, setPagination] = useState<{
    page: number
    pageSize: number
  }>({
    page: 0,
    pageSize: 25,
  })

  const { dataControlled: dataContext } = useStepperContext<DataContextProps>()
  const { query, eventosFilter, indApuracao, grupoId } = dataContext

  const isXSmallWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const { anoMes, empregador } = useAmbiente()
  const queryClient = useQueryClient()
  const theme: FixLater = useTheme()

  const { data, isLoading, isFetching } = useObterEventosTransmissaoAcompanhamento({
    params: {
      anoMes,
      grupoEvento: grupo,
      grupoId,
      indApuracao,
      page: pagination.page + 1,
      pageSize: pagination.pageSize,
      empregadorNome: query,
    },
    data: eventosFilter,
  })

  const _d = data?.data || []

  const totalRecords = data?.totalRecords || 0

  const allAuditoriasIds: string[] = []

  _d.forEach((empregador) => {
    empregador.grupoEventos.forEach((grupo) =>
      grupo.eventos.forEach((auditoria) => allAuditoriasIds.push(auditoria.auditoriaId)),
    )
  })

  useEffect(() => {
    setRowExpanded([])
  }, [anoMes])

  function refetchRequests() {
    queryClient.invalidateQueries(queryUseObterTotalEventosGrupoAcompanhamento)
    queryClient.invalidateQueries(queryUseObterEventosTransmissaoAcompanhamento)
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'empregadorId',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'empregadorNrInscricao',
      label: 'Número de Inscrição',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'empregadorNome',
      label: 'Descrição',
    },
    {
      name: 'totalEventos',
      label: 'Quantidade',
      options: {
        setCellProps: () => ({
          style: {
            width: isXSmallWidth ? undefined : '80px',
          },
        }),
      },
    },
  ]

  return (
    <Box height="100%">
      <AutoSizer>
        {({ height, width }) => (
          <Box height={height} width={width}>
            <ToolsDataTable
              data={_d}
              disableAutoHeight
              isLoading={isLoading}
              isFetching={isFetching}
              columns={columns}
              optionsExpandable={{
                type: 'single',
                expandedChildren: (index: number) => {
                  const empregadorCurrent = _d[index]
                  return <TableEventos data={empregadorCurrent.grupoEventos} grupo={grupo} />
                },
                onRowExpanded: setRowExpanded,
                rowsExpanded: rowsExpanded,
              }}
              options={{
                setRowProps: (row: any) => {
                  const empregadorId = row[0]
                  const isHightLightRow = empregador?.id === empregadorId ? true : false
                  return {
                    style: {
                      backgroundColor: isHightLightRow
                        ? theme.palette.secondary.lighter
                        : 'inherit',
                    },
                  }
                },
              }}
              pagination={false}
            />

            <Box component={Paper}>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                labelRowsPerPage="Linhas por página:"
                component="div"
                count={totalRecords}
                rowsPerPage={pagination.pageSize}
                page={pagination.page}
                onChangePage={(_, page) =>
                  setPagination((prev) => ({
                    ...prev,
                    page,
                  }))
                }
                onChangeRowsPerPage={(e) =>
                  setPagination((prev) => ({
                    ...prev,
                    pageSize: +e.target.value,
                    page: 0,
                  }))
                }
              />
            </Box>

            {!isLoading && (
              <Box position="absolute" left={9} top={14} zIndex={999}>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => refetchRequests()}
                  title="Recarregar"
                >
                  <CachedIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        )}
      </AutoSizer>
    </Box>
  )
}
