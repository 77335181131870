import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useClientObterModelos } from './useObterModelos'

import { notifyPost } from '~/utils/notification'

import { ModeloRelatorioRequestDTO } from './dtos/ModeloRelatorioRequestDTO'

interface RequestProps {
  data: ModeloRelatorioRequestDTO
}

export function useCriar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useClientObterModelos()

  async function handleRequest({ data }: RequestProps) {
    const response = await api.post<{ data: string }>('/ModeloRelatorio/Criar', data)
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
