import { useState } from 'react'

import moment from 'moment'
import * as yup from 'yup'

import { Box, Chip, Grid, IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import {
  ActionDialog,
  Button,
  ButtonBox,
  Checkbox,
  ConfirmDeleteDialog,
  Fieldset,
  TextField,
  TimePicker,
} from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import useDialog from '~/hooks/useDialog'

import { VinculoFaltas } from '~/hooks/queries/VinculoFaltas/VinculoFaltas'
import { ConfiguracaoEmpregador } from '~/hooks/queries/VinculoFaltas/VinculoFaltasDTO'

import { useCreateVinculoFaltas } from '~/hooks/queries/VinculoFaltas/useCreateVinculoFaltas'
import { useCreateMultiplosVinculoFaltas } from '~/hooks/queries/VinculoFaltas/useCreateMultiplosVinculoFaltas'
import { useUpdateVinculoFaltas } from '~/hooks/queries/VinculoFaltas/useUpdateVinculoFaltas'
import { useRemoveVinculoFaltas } from '~/hooks/queries/VinculoFaltas/useRemoveVinculoFaltas'

import {
  IndLancamentoFaltaEnum,
  IndLancamentoFaltaValues,
} from '~/@types/enums/IndLancamentoFaltaEnum'
import { TipoFaltaEnum, TipoFaltaValues } from '~/@types/enums/TipoFaltaEnum'

const faltasQueDescontamDSR = [TipoFaltaEnum.Injustificavel_90, TipoFaltaEnum.Suspensao_91]

const schema = yup.object().shape({
  indLancamento: yup.string().required('Informe o Inicador de Falta'),
  qtdHoras: yup
    .string()
    .when(['indLancamento'], (indLancamento, schema) => {
      if (indLancamento === '2') {
        return schema.required('Informe o Tempo de Atraso').nullable()
      }
      return schema
    })
    .nullable(),
})

interface FormProps {
  isOpen: boolean
  disabled: boolean
  onClose: () => void
  data: Partial<VinculoFaltas>
  configuracaoEmpregador?: ConfiguracaoEmpregador
  datesSeraoInseridas?: string[]
}

export default function Form(props: FormProps) {
  const {
    data: _data,
    disabled,
    isOpen,
    onClose,
    configuracaoEmpregador,
    datesSeraoInseridas: _datesSeraoInseridas,
  } = props

  const [data, setData] = useState<Partial<VinculoFaltas>>(_data)
  const [datesSeraoInseridas, setDatesSeraoInseridas] = useState<string[] | undefined>(
    _datesSeraoInseridas,
  )

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  const {
    close: closeConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog(null)

  const { mutateAsync: createVinculoFaltas, isLoading: isLoadingCreateVinculoFaltas } =
    useCreateVinculoFaltas()
  const {
    mutateAsync: createMultiplosVinculoFaltas,
    isLoading: isLoadingCreateMultiplosVinculoFaltas,
  } = useCreateMultiplosVinculoFaltas()
  const { mutateAsync: updateVinculoFaltas, isLoading: isLoadingUpdateVinculoFaltas } =
    useUpdateVinculoFaltas()
  const { mutateAsync: removeVinculoFaltas, isLoading: isLoadingRemoveVinculoFaltas } =
    useRemoveVinculoFaltas()

  const isLoading =
    isLoadingCreateVinculoFaltas ||
    isLoadingUpdateVinculoFaltas ||
    isLoadingCreateMultiplosVinculoFaltas

  const isNewRegister = _data.id ? false : true

  async function handleSubmit() {
    if (data.id) {
      await updateVinculoFaltas(data as VinculoFaltas)
    } else {
      if (datesSeraoInseridas) {
        await createMultiplosVinculoFaltas({
          camposFaltaAtraso: data as VinculoFaltas,
          datas: datesSeraoInseridas,
        })
      } else {
        await createVinculoFaltas(data as VinculoFaltas)
      }
    }
    onClose()
  }

  async function handleRemove() {
    await removeVinculoFaltas(data.id as string)
    onClose()
  }

  return (
    <ActionDialog
      renderRight={
        !isNewRegister && !disabled ? (
          <IconButton color="primary" size="small" onClick={() => openConfirmDelete(null)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        ) : (
          <></>
        )
      }
      title="Cadastro de Falta"
      subtitle={_data?.dtFalta ? moment(_data.dtFalta).format('DD/MM/yyyy') : '-'}
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
      customActions={
        !disabled ? (
          <ButtonBox right={1} bottom={1}>
            <Button onClick={onClose}>Cancelar</Button>
            <Button variant="contained" onClick={handleValidate} isLoading={isLoading}>
              Salvar
            </Button>
          </ButtonBox>
        ) : (
          <ButtonBox right={1} bottom={1}>
            <Button onClick={onClose} variant="contained">
              Fechar
            </Button>
          </ButtonBox>
        )
      }
    >
      <Fieldset disabled={disabled}>
        <Grid container spacing={2}>
          {datesSeraoInseridas ? (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box display="grid" gridGap={4} gridTemplateColumns="1fr 1fr 1fr">
                {datesSeraoInseridas.map((date, index) => (
                  <Chip
                    key={index}
                    label={moment(date).format('DD/MM/yyyy')}
                    onDelete={() => {
                      setDatesSeraoInseridas((_prev) => {
                        const prev = _prev || []
                        return [...prev.filter((d) => d !== date)]
                      })
                    }}
                    color="secondary"
                  />
                ))}
              </Box>
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <MUIAutoComplete
              label="Indicador de Falta"
              options={IndLancamentoFaltaValues}
              renderOption={(option) => option.name}
              optionId="value"
              value={data?.indLancamento || ''}
              onChange={(e, obj) => {
                const indLancamento = obj?.value || ''
                if (isNewRegister) {
                  let isDSR = false
                  const isDSRFalta = configuracaoEmpregador?.isDSRFalta || false
                  const isDSRAtraso = configuracaoEmpregador?.isDSRAtraso || false

                  if (indLancamento === IndLancamentoFaltaEnum.Falta) {
                    isDSR = isDSRFalta
                  }
                  if (indLancamento === IndLancamentoFaltaEnum.Atraso) {
                    isDSR = isDSRAtraso
                  }

                  setData((prev) => ({ ...prev, isDSR }))
                }

                setData((oldState) => ({
                  ...oldState,
                  indLancamento,
                  tipoFalta: TipoFaltaEnum.Injustificavel_90,
                  qtdHoras: null,
                }))
              }}
              required
              validationErrors={validationErrors}
              name="indLancamento"
            />
          </Grid>

          {data?.indLancamento === IndLancamentoFaltaEnum.Falta && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <MUIAutoComplete
                label="Tipo de Falta"
                options={TipoFaltaValues}
                renderOption={(option) => option.name}
                optionId="value"
                value={data?.tipoFalta || ''}
                onChange={(e, obj) => {
                  const tipoFalta = obj?.value || ''
                  const isDSR = tipoFalta === TipoFaltaEnum.Injustificavel_90
                  setData((oldState) => ({
                    ...oldState,
                    tipoFalta,
                    isDSR,
                  }))
                }}
              />
            </Grid>
          )}
          {data?.indLancamento === IndLancamentoFaltaEnum.Atraso && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TimePicker
                label="Tempo de Atraso"
                value={data?.qtdHoras || null}
                onChange={(value) => {
                  const qtdHoras = value
                  setData((oldState) => ({
                    ...oldState,
                    qtdHoras,
                  }))
                }}
                name="qtdHoras"
                validationErrors={validationErrors}
                required
              />
            </Grid>
          )}

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Observação"
              value={data?.observacao || ''}
              onChange={(e) => {
                const observacao = e?.target?.value || ''
                setData((oldState) => ({
                  ...oldState,
                  observacao,
                }))
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Checkbox
              label="Descontar DSR?"
              value={data?.isDSR || false}
              disabled={data.tipoFalta ? !faltasQueDescontamDSR.includes(data.tipoFalta) : true}
              onChange={(e, value) => {
                const isDSR = value
                setData((oldState) => ({
                  ...oldState,
                  isDSR,
                }))
              }}
            />
          </Grid>
        </Grid>
      </Fieldset>

      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isLoadingRemoveVinculoFaltas}
        onCancel={closeConfirmDelete}
        onConfirm={handleRemove}
      />
    </ActionDialog>
  )
}
