import { useState, useEffect, useCallback } from 'react'

import { ContainerTable, Stack, PageHeader, ButtonBox, Button } from '~/components'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import Form from './components/Form'
import VinculoHorarioItem from './components/VinculoHorarioItem'

import api from '~/services/api-pessoal'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

const VinculoHorario = ({ vinculo, considerIsFinalizado, dtAlteracao }) => {
  const isFinalizado = vinculo?.isFinalizado && considerIsFinalizado

  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })

  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })

  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    const getCollection = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get(`/VinculoHorario/GetByVinculo?vinculoId=${vinculo?.id}`)
        if (response.data.data) {
          setCollection((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [vinculo])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = (id) => {
        const { itens } = collection
        const item = itens.find((i) => i.id === id)
        handleOpenForm(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    //eslint-disable-next-line
    [collection],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    const itens = collection.itens
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))
    try {
      await api.delete(`/VinculoHorario/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleOpenForm = useCallback(
    (
      data = {
        vinculo: vinculo,
        vinculoId: vinculo?.id,
        dtIncio: dtAlteracao
          ? dtAlteracao
          : collection.itens.length > 0
          ? null
          : vinculo?.dtAdmissao,
      },
    ) => {
      setForm((oldState) => ({
        ...oldState,
        isOpen: true,
        data,
      }))
    },
    [dtAlteracao, vinculo, collection.itens],
  )

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
  }, [handleOpenForm])

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const { itens } = collection
        const newItens = [data, ...itens]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [collection],
  )

  return (
    <Stack height="100%">
      <PageHeader>
        <ButtonBox top={1}>
          {isFinalizado ? (
            <></>
          ) : (
            <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
              Adicionar
            </Button>
          )}
        </ButtonBox>
      </PageHeader>

      <ContainerTable>
        <Table
          data={collection.itens}
          isLoading={collection.isLoading}
          onItemClick={handleClickItem}
          isFinalizado={isFinalizado}
          renderExpandableRow={(index) => (
            <VinculoHorarioItem
              vinculo={vinculo}
              isFinalizado={isFinalizado}
              vinculoHorarioId={collection.itens[index].id}
            />
          )}
        />
      </ContainerTable>

      {form.isOpen && (
        <Form
          isOpen={form.isOpen}
          data={form.data}
          vinculo={vinculo}
          onClose={handleCloseForm}
          onAfterSubmitForm={handleAfterSubmitForm}
          isFinalizado={isFinalizado}
        />
      )}

      {confirmDeleteDialog.isOpen && (
        <ConfirmDeleteDialog
          isOpen={confirmDeleteDialog.isOpen}
          isDeleting={confirmDeleteDialog.isDeleting}
          onCancel={handleCloseConfirmDeleteItem}
          onConfirm={handleDeleteItem}
        />
      )}
    </Stack>
  )
}

export default VinculoHorario

VinculoHorario.defaultProps = {
  considerIsFinalizado: true,
  dtAlteracao: null,
}
