export enum ConsultaVencimentosSortEnum {
  DataVencimento,
  EstabelecimentoNome,
  FuncionarioNome,
}

export const ConsultaVencimentosSortValues = [
  {
    name: 'Data de Vencimento',
    value: ConsultaVencimentosSortEnum.DataVencimento,
  },
  {
    name: 'Nome do Estabelecimento',
    value: ConsultaVencimentosSortEnum.EstabelecimentoNome,
  },
  {
    name: 'Nome do Funcionário',
    value: ConsultaVencimentosSortEnum.FuncionarioNome,
  },
]
