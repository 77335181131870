import { validate as isValidUUID } from 'uuid'

import { Box, IconButton, makeStyles } from '@material-ui/core'
import { Add as AddIcon, Close as CloseIcon } from '@material-ui/icons'

import DynamicContextMenu, { Item } from '~/components/DynamicContextMenu'
import BadgeInfo from './components/BadgeInfo'

import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'
import {
  DECIMO_TERCEIRO_SALARIO,
  IntermitenteNormal,
  IntermitenteRescisao,
  TODOS,
} from '~/pages/ReciboPagamento/components/Menu'

import { useContextRecibo } from '~/hooks/useRecibo'
import useAmbiente from '~/hooks/useAmbiente'

import { CategoriaEnum } from '~/@types/enums/CategoriaEnum'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const useStylesVinculoCardItem = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  heightIcon: {
    height: '12pt',
  },
}))

function SingleMenu(props) {
  const classes = useStylesVinculoCardItem()
  return (
    <IconButton {...props} size="small">
      <AddIcon color="primary" fontSize="small" className={classes.heightIcon} />
    </IconButton>
  )
}

export default function CardMenu(props) {
  const { vinculo, agrupamentoId, showActions, onContextMenuItemClick } = props
  const classes = useStylesVinculoCardItem()

  const { qtdRecibos, vinculoTipo, recibosFinalizados } = vinculo

  const { filterValue } = useContextRecibo()
  const { mes } = useAmbiente()

  function handleClickItem(idf) {
    const isRPLotacao = isValidUUID(agrupamentoId)

    let _lotacaoId = undefined
    let _vinculo = vinculo

    if (isRPLotacao) {
      _lotacaoId = vinculo?.vinculoId || ''
      _vinculo.vinculoId = agrupamentoId
    }

    onContextMenuItemClick(idf, {
      vinculo: _vinculo,
      lotacaoId: _lotacaoId,
      idf,
    })
  }

  const menusByFilter = {
    [TODOS]: {
      [VinculoTipoEnum.Funcionario_1]: [
        ReciboIDFEnum.Mensal_1,
        ReciboIDFEnum.Ferias_2,
        ReciboIDFEnum.Complementar_3,
        ReciboIDFEnum.Adiantamento_4,
        ReciboIDFEnum.Rescisao_5,
        ReciboIDFEnum.RescisaoComplementar_6,
        DECIMO_TERCEIRO_SALARIO,
        ReciboIDFEnum.RescisaoSimulada_11,
        ReciboIDFEnum.Resilicao_14,
      ],
      [VinculoTipoEnum.Empregador_2]: [ReciboIDFEnum.Socio_15, ReciboIDFEnum.Rescisao_5],
      [VinculoTipoEnum.Estagiario_3]: [
        ReciboIDFEnum.Mensal_1,
        ReciboIDFEnum.Ferias_2,
        ReciboIDFEnum.Rescisao_5,
      ],
      [VinculoTipoEnum.Autonomo_4]: [ReciboIDFEnum.Autonomo_16],
      [VinculoTipoEnum.Cooperado_5]: [ReciboIDFEnum.Cooperado_19, ReciboIDFEnum.Rescisao_5],
    },
    [DECIMO_TERCEIRO_SALARIO]: {
      [VinculoTipoEnum.Funcionario_1]: [DECIMO_TERCEIRO_SALARIO],
      [VinculoTipoEnum.Empregador_2]: [],
      [VinculoTipoEnum.Estagiario_3]: [DECIMO_TERCEIRO_SALARIO],
      [VinculoTipoEnum.Autonomo_4]: [],
      [VinculoTipoEnum.Cooperado_5]: [],
    },
    [ReciboIDFEnum.Mensal_1]: {
      [VinculoTipoEnum.Funcionario_1]: [ReciboIDFEnum.Mensal_1, ReciboIDFEnum.Complementar_3],
      [VinculoTipoEnum.Empregador_2]: [ReciboIDFEnum.Socio_15],
      [VinculoTipoEnum.Estagiario_3]: [ReciboIDFEnum.Mensal_1],
      [VinculoTipoEnum.Autonomo_4]: [ReciboIDFEnum.Autonomo_16],
      [VinculoTipoEnum.Cooperado_5]: [ReciboIDFEnum.Cooperado_19],
    },
    [ReciboIDFEnum.Ferias_2]: {
      [VinculoTipoEnum.Funcionario_1]: [ReciboIDFEnum.Ferias_2],
      [VinculoTipoEnum.Empregador_2]: [],
      [VinculoTipoEnum.Estagiario_3]: [ReciboIDFEnum.Ferias_2],
      [VinculoTipoEnum.Autonomo_4]: [],
      [VinculoTipoEnum.Cooperado_5]: [],
    },
    [ReciboIDFEnum.Complementar_3]: {
      [VinculoTipoEnum.Funcionario_1]: [ReciboIDFEnum.Complementar_3],
      [VinculoTipoEnum.Empregador_2]: [],
      [VinculoTipoEnum.Estagiario_3]: [],
      [VinculoTipoEnum.Autonomo_4]: [],
      [VinculoTipoEnum.Cooperado_5]: [],
    },
    [ReciboIDFEnum.Adiantamento_4]: {
      [VinculoTipoEnum.Funcionario_1]: [ReciboIDFEnum.Adiantamento_4],
      [VinculoTipoEnum.Empregador_2]: [],
      [VinculoTipoEnum.Estagiario_3]: [ReciboIDFEnum.Adiantamento_4],
      [VinculoTipoEnum.Autonomo_4]: [],
      [VinculoTipoEnum.Cooperado_5]: [],
    },
    [ReciboIDFEnum.Rescisao_5]: {
      [VinculoTipoEnum.Funcionario_1]: [ReciboIDFEnum.Rescisao_5],
      [VinculoTipoEnum.Empregador_2]: [ReciboIDFEnum.Rescisao_5],
      [VinculoTipoEnum.Estagiario_3]: [ReciboIDFEnum.Rescisao_5],
      [VinculoTipoEnum.Autonomo_4]: [],
      [VinculoTipoEnum.Cooperado_5]: [ReciboIDFEnum.Rescisao_5],
    },
    [ReciboIDFEnum.RescisaoTSV_20]: {
      [VinculoTipoEnum.Funcionario_1]: [],
      [VinculoTipoEnum.Empregador_2]: [ReciboIDFEnum.RescisaoTSV_20],
      [VinculoTipoEnum.Estagiario_3]: [ReciboIDFEnum.RescisaoTSV_20],
      [VinculoTipoEnum.Autonomo_4]: [ReciboIDFEnum.RescisaoTSV_20],
      [VinculoTipoEnum.Cooperado_5]: [ReciboIDFEnum.RescisaoTSV_20],
    },
    [ReciboIDFEnum.RescisaoComplementar_6]: {
      [VinculoTipoEnum.Funcionario_1]: [ReciboIDFEnum.RescisaoComplementar_6],
      [VinculoTipoEnum.Empregador_2]: [],
      [VinculoTipoEnum.Estagiario_3]: [],
      [VinculoTipoEnum.Autonomo_4]: [],
      [VinculoTipoEnum.Cooperado_5]: [],
    },
    [ReciboIDFEnum.RescisaoSimulada_11]: {
      [VinculoTipoEnum.Funcionario_1]: [],
      [VinculoTipoEnum.Empregador_2]: [],
      [VinculoTipoEnum.Estagiario_3]: [],
      [VinculoTipoEnum.Autonomo_4]: [],
      [VinculoTipoEnum.Cooperado_5]: [],
    },
    [ReciboIDFEnum.Resilicao_14]: {
      [VinculoTipoEnum.Funcionario_1]: [ReciboIDFEnum.Resilicao_14],
      [VinculoTipoEnum.Empregador_2]: [],
      [VinculoTipoEnum.Estagiario_3]: [],
      [VinculoTipoEnum.Autonomo_4]: [],
      [VinculoTipoEnum.Cooperado_5]: [],
    },
    [ReciboIDFEnum.Socio_15]: {
      [VinculoTipoEnum.Funcionario_1]: [],
      [VinculoTipoEnum.Empregador_2]: [ReciboIDFEnum.Rescisao_5, ReciboIDFEnum.Socio_15],
      [VinculoTipoEnum.Estagiario_3]: [],
      [VinculoTipoEnum.Autonomo_4]: [],
      [VinculoTipoEnum.Cooperado_5]: [],
    },
    [ReciboIDFEnum.Autonomo_16]: {
      [VinculoTipoEnum.Funcionario_1]: [],
      [VinculoTipoEnum.Empregador_2]: [],
      [VinculoTipoEnum.Estagiario_3]: [],
      [VinculoTipoEnum.Autonomo_4]: [ReciboIDFEnum.Autonomo_16],
      [VinculoTipoEnum.Cooperado_5]: [],
    },
    [ReciboIDFEnum.ConvencaoColetiva_18]: {
      [VinculoTipoEnum.Funcionario_1]: [],
      [VinculoTipoEnum.Empregador_2]: [],
      [VinculoTipoEnum.Estagiario_3]: [],
      [VinculoTipoEnum.Autonomo_4]: [],
      [VinculoTipoEnum.Cooperado_5]: [ReciboIDFEnum.Cooperado_19, ReciboIDFEnum.Rescisao_5],
    },
    [ReciboIDFEnum.Ferias_Coletivas_21]: {
      [VinculoTipoEnum.Funcionario_1]: [ReciboIDFEnum.Ferias_Coletivas_21],
      [VinculoTipoEnum.Empregador_2]: [],
      [VinculoTipoEnum.Estagiario_3]: [],
      [VinculoTipoEnum.Autonomo_4]: [],
      [VinculoTipoEnum.Cooperado_5]: [],
    },
    [IntermitenteNormal]: {
      [VinculoTipoEnum.Funcionario_1]: [CategoriaEnum.Empregado_Intermitente_111],
      [VinculoTipoEnum.Empregador_2]: [],
      [VinculoTipoEnum.Estagiario_3]: [],
      [VinculoTipoEnum.Autonomo_4]: [],
      [VinculoTipoEnum.Cooperado_5]: [],
    },
    [IntermitenteRescisao]: {
      [VinculoTipoEnum.Funcionario_1]: [ReciboIDFEnum.Rescisao_5],
      [VinculoTipoEnum.Empregador_2]: [],
      [VinculoTipoEnum.Estagiario_3]: [],
      [VinculoTipoEnum.Autonomo_4]: [],
      [VinculoTipoEnum.Cooperado_5]: [],
    },
  }

  const displayMenu = () => {
    if (menusByFilter[filterValue][vinculoTipo].length === 0) {
      return <CloseIcon fontSize="small" color="inherit" />
    }

    if (menusByFilter[filterValue][vinculoTipo].length === 1) {
      let idf = menusByFilter[filterValue][vinculoTipo][0]
      return (
        <SingleMenu
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(idf)
          }}
        />
      )
    }

    return (
      <DynamicContextMenu forceContextMenu={true} iconSize="12pt">
        <Item
          label="Recibo Mensal"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(ReciboIDFEnum.Mensal_1)
          }}
          isVisible={menusByFilter[filterValue][vinculoTipo].includes(ReciboIDFEnum.Mensal_1)}
        />
        <Item
          label="Recibo de Férias"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(ReciboIDFEnum.Ferias_2)
          }}
          isVisible={menusByFilter[filterValue][vinculoTipo].includes(ReciboIDFEnum.Ferias_2)}
        />
        <Item
          label="Recibo Complementar"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(ReciboIDFEnum.Complementar_3)
          }}
          isVisible={menusByFilter[filterValue][vinculoTipo].includes(ReciboIDFEnum.Complementar_3)}
        />
        <Item
          label="Adiantamento"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(ReciboIDFEnum.Adiantamento_4)
          }}
          isVisible={menusByFilter[filterValue][vinculoTipo].includes(ReciboIDFEnum.Adiantamento_4)}
        />

        <Item
          label="Recibo Intermitente"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(CategoriaEnum.Empregado_Intermitente_111)
          }}
          isVisible={menusByFilter[filterValue][vinculoTipo].includes(
            CategoriaEnum.Empregado_Intermitente_111,
          )}
        />

        <Item
          label="Recibo Rescisão"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(ReciboIDFEnum.Rescisao_5)
          }}
          isVisible={menusByFilter[filterValue][vinculoTipo].includes(ReciboIDFEnum.Rescisao_5)}
        />
        <Item
          label="Recibo Rescisão Complementar"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(ReciboIDFEnum.RescisaoComplementar_6)
          }}
          isVisible={menusByFilter[filterValue][vinculoTipo].includes(
            ReciboIDFEnum.RescisaoComplementar_6,
          )}
        />
        <Item
          label={mes !== '12' ? 'Recibo Primeira Parcela 13°' : 'Recibo Segunda Parcela 13°'}
          icon="add"
          onClick={(e) => {
            let decimoTerceiro = ReciboIDFEnum.DecimoTerceiro1aParcela_7
            if (mes === '12') decimoTerceiro = ReciboIDFEnum.DecimoTerceiro2aParcela_8
            e.stopPropagation()
            handleClickItem(decimoTerceiro)
          }}
          isVisible={menusByFilter[filterValue][vinculoTipo].includes(DECIMO_TERCEIRO_SALARIO)}
        />
        <Item
          label="Recibo Primeira Quinzena"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(ReciboIDFEnum.PrimeiraQuinzena_9)
          }}
          // isVisible={menusByFilter[filterValue][vinculoTipo].includes(
          //   ReciboIDFEnum.PrimeiraQuinzena_9,
          // )}
          isVisible={false}
        />
        <Item
          label="Recibo Segunda Quinzena"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(ReciboIDFEnum.SegundaQuinzena_10)
          }}
          // isVisible={menusByFilter[filterValue][vinculoTipo].includes(
          //   ReciboIDFEnum.SegundaQuinzena_10,
          // )}
          isVisible={false}
        />
        <Item
          label="Recibo Rescisão Simulada"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(ReciboIDFEnum.RescisaoSimulada_11)
          }}
          isVisible={menusByFilter[filterValue][vinculoTipo].includes(
            ReciboIDFEnum.RescisaoSimulada_11,
          )}
        />
        <Item
          label="Recibo Resilição"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(ReciboIDFEnum.Resilicao_14)
          }}
          isVisible={menusByFilter[filterValue][vinculoTipo].includes(ReciboIDFEnum.Resilicao_14)}
        />
        <Item
          label="Recibo Pró-Labore"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(ReciboIDFEnum.Socio_15)
          }}
          isVisible={menusByFilter[filterValue][vinculoTipo].includes(ReciboIDFEnum.Socio_15)}
        />
        <Item
          label="Recibo Autônomo"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(ReciboIDFEnum.Autonomo_16)
          }}
          isVisible={menusByFilter[filterValue][vinculoTipo].includes(ReciboIDFEnum.Autonomo_16)}
        />
        <Item
          label="Recibo Cooperado"
          icon="add"
          onClick={(e) => {
            e.stopPropagation()
            handleClickItem(ReciboIDFEnum.Cooperado_19)
          }}
          isVisible={menusByFilter[filterValue][vinculoTipo].includes(ReciboIDFEnum.Cooperado_19)}
        />
      </DynamicContextMenu>
    )
  }

  return (
    <Box className={classes.root}>
      {qtdRecibos > 0 && !showActions && (
        <BadgeInfo value={qtdRecibos} success={recibosFinalizados} />
      )}
      {showActions && displayMenu()}
    </Box>
  )
}
