import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { RelConsultaLancamentoVinculoRequestDTO } from './dtos/RelConsultaLancamentoVinculoRequestDTO'
import { RelConsultaLancamentoVinculoDataClientDTO } from './dtos/RelConsultaLancamentoVinculoDataClientDTO'

const query = '/RelConsultaLancamentoVinculo/ObterData'

interface RequestProps {
  data: RelConsultaLancamentoVinculoRequestDTO
}

export function useObterData({ data }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: RelConsultaLancamentoVinculoDataClientDTO[] }>(
      query,
      data,
    )
    return response.data.data
  }

  return useQuery([query, data], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled: false,
  })
}
