import React from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { HeightDataTable } from '~/components'

import { DiaHorarioEnum, DiaHorarioValues } from '~/@types/enums/DiaHorarioEnum'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { VinculoHorarioItemPartial } from '~/hooks/queries/VinculoHorarioItem/VinculoHorarioItem'

const formatDiaHorario = (diaHorario: DiaHorarioEnum) => {
  const objectFinded = DiaHorarioValues.find((obj) => obj.value === diaHorario)
  return objectFinded?.name || ''
}

const formatNumberInHour = (hour: string) => {
  if (!hour) return ''
  return `${hour.substring(0, 2)}:${hour.substring(2)}`
}

interface TableProps {
  data: VinculoHorarioItemPartial[]
  onItemClick?: (index: number) => void
}

export default function Table(props: TableProps) {
  const { data: _data, onItemClick } = props

  _data.sort((a, b) => (a?.diaHorario || 0) - (b?.diaHorario || 0))
  const data = _data.map((item) => {
    return {
      ...item,
      diaHorario: item?.diaHorario ? formatDiaHorario(item?.diaHorario) : '',
      horarioTrabalhoNome:
        (item?.horarioTrabalho?.codigo || '') + ' - ' + (item?.horarioTrabalho?.nome || ''),
      horaEntrada: formatNumberInHour(item?.horarioTrabalho?.horaEntrada || ''),
      horaSaida: formatNumberInHour(item?.horarioTrabalho?.horaSaida || ''),
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'diaHorario',
      label: 'Dia da Semana Trabalhado',
    },
    {
      name: 'horarioTrabalhoNome',
      label: 'Horário de Trabalho',
    },
    {
      name: 'horaEntrada',
      label: 'Entrada',
    },
    {
      name: 'horaSaida',
      label: 'Saída',
    },
  ]

  if (onItemClick) {
    columns.push({
      name: 'horaSaida',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, { rowIndex }) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick(rowIndex)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    })
  }

  return <HeightDataTable data={data} columns={columns} pagination={false} />
}
