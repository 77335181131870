import { useState, memo, useCallback } from 'react'

import * as yup from 'yup'

import { Box, Grid, Paper, Theme, useMediaQuery } from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons'
import {
  Button,
  ContainerTable,
  CurrencyTextField,
  DatePickerNew,
  Finder,
  PageHeader,
  Stack,
  StackContainer,
} from '~/components'

import { AutoCompleteCargo, AutoCompleteEventoEmpregador } from '~/components/AutoComplete'

import Table from './Table'
import DialogImportacao from './DialogImportacao'

import { useObterFuncionarios } from '~/hooks/queries/PisoEnfermagem/useObterFuncionarios'
import { useCalcularFuncionario } from '~/hooks/queries/PisoEnfermagem/useCalcularFuncionario'
import { useProcessar } from '~/hooks/queries/PisoEnfermagem/useProcessar'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import useLocalStorage from '~/hooks/useLocalStorage'
import useValidationErrors from '~/hooks/useValidationErrors'

import constants from '~/utils/constants'

import { PisoEnfermagemFuncionarioDTO } from '~/hooks/queries/PisoEnfermagem/dtos/PisoEnfermagemFuncionarioDTO'

const schema = yup.object().shape({
  dtPagamento: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Pagamento')
    .nullable(),
  eventoMensalCodigo: yup.string().required('Informe o Evento Mensal'),
  eventoFeriasCodigo: yup.string().required('Informe o Evento Férias'),
})

const MemoTable = memo(Table)

export default function PisoEnfermagem() {
  const [dataForm, setDataForm] = useState({
    nrMeses: 1,
    vrFator: 111,
  })
  const [dataFormProcess, setDataFormProcess] = useState({
    dtPagamento: '',
    eventoMensalId: '',
    eventoMensalCodigo: '',
    eventoFeriasId: '',
    eventoFeriasCodigo: '',
  })
  const [query, setQuery] = useState('')
  const [cargoId, setCargoId] = useLocalStorage(constants.cargoId, '')
  const { anoMes, estabelecimento, empregador } = useAmbiente()
  const isSmallWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const {
    close: closeDialogImportacao,
    isOpen: isOpenDialogImportacao,
    open: openDialogImportacao,
  } = useDialog(null)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleProcess,
    data: dataFormProcess,
  })

  const {
    data: _d,
    isLoading,
    isFetching: _isFetching,
    remountQuery,
    remountQueryList,
    resetQuery,
  } = useObterFuncionarios({
    params: {
      anoMes,
      estabelecimentoId: estabelecimento.id,
      cargoId,
    },
  })
  const data = _d || []

  const { mutateAsync: mutateAsyncCalularFuncionario, isLoading: isCalculing } =
    useCalcularFuncionario()

  const isFetching = _isFetching || isCalculing

  const { mutateAsync: mutateAsyncProcessar, isLoading: isProcessing } = useProcessar()

  const handleCalcFuncionario = useCallback(
    async (row: PisoEnfermagemFuncionarioDTO, vrPiso: number) => {
      const rowNova = await mutateAsyncCalularFuncionario({
        data: row,
        params: {
          vrPiso,
          vrMultiplicador: dataForm.nrMeses,
          vrFator: dataForm.vrFator,
        },
      })
      remountQuery(rowNova)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataForm.nrMeses, dataForm.vrFator],
  )

  async function handleProcess() {
    await mutateAsyncProcessar({
      data,
      params: {
        dtPagamento: dataFormProcess.dtPagamento,
        eventoMensalCodigo: dataFormProcess.eventoMensalCodigo,
        eventoFeriasCodigo: dataFormProcess.eventoFeriasCodigo,
      },
    })
    resetQuery()
  }

  function handleAfterImport(dt: PisoEnfermagemFuncionarioDTO[]) {
    remountQueryList(dt)
  }

  return (
    <StackContainer>
      <PageHeader title="Piso Enfermagem">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Stack
        component={Paper}
        p={1}
        orientation={isSmallWidth ? 'vertical' : 'horizontal'}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flex={1} maxWidth={500}>
          <AutoCompleteCargo
            onChange={(e, cargo) => {
              const cargoId = cargo?.id || ''
              setCargoId(cargoId)
            }}
            value={cargoId}
            empregadorId={empregador.id}
          />
        </Box>
        <Stack orientation="horizontal">
          <CurrencyTextField
            label="Número de Meses"
            value={dataForm.nrMeses}
            onChange={(_, value) => {
              const nrMeses = value
              setDataForm((prev) => ({ ...prev, nrMeses }))
            }}
          />

          <CurrencyTextField
            label="Fator"
            value={dataForm.vrFator}
            onChange={(_, value) => {
              const vrFator = value
              setDataForm((prev) => ({ ...prev, vrFator }))
            }}
          />
        </Stack>
        <Button
          startIcon={<CloudUpload />}
          variant="contained"
          onClick={() => openDialogImportacao(null)}
        >
          Importação
        </Button>
      </Stack>

      <ContainerTable>
        <MemoTable
          data={data}
          query={query}
          isFetching={isFetching}
          isLoading={isLoading}
          onCalcFuncionario={handleCalcFuncionario}
        />
      </ContainerTable>

      <Stack
        component={Paper}
        p={1}
        orientation="horizontal"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container spacing={1} style={{ maxWidth: 1000 }}>
          <Grid item xs={12} sm={12} md={4}>
            <DatePickerNew
              label="Data de Pagamento"
              name="dtPagamento"
              validationErrors={validationErrors}
              value={dataFormProcess.dtPagamento || null}
              onChange={(dt) => {
                const dtPagamento = dt || ''
                setDataFormProcess((prev) => ({ ...prev, dtPagamento }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AutoCompleteEventoEmpregador
              label="Evento Mensal"
              name="eventoMensalId"
              validationErrors={validationErrors}
              optionId="id"
              value={dataFormProcess.eventoMensalId}
              onChange={(obj) => {
                const eventoMensalId = obj?.id || ''
                const eventoMensalCodigo = obj?.codigo.toString() || ''
                setDataFormProcess((prev) => ({ ...prev, eventoMensalId, eventoMensalCodigo }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AutoCompleteEventoEmpregador
              label="Evento Férias"
              name="eventoFeriasId"
              validationErrors={validationErrors}
              optionId="id"
              value={dataFormProcess.eventoFeriasId}
              onChange={(obj) => {
                const eventoFeriasId = obj?.id || ''
                const eventoFeriasCodigo = obj?.codigo.toString() || ''
                setDataFormProcess((prev) => ({ ...prev, eventoFeriasId, eventoFeriasCodigo }))
              }}
            />
          </Grid>
        </Grid>

        <Button
          variant="contained"
          onClick={handleValidate}
          disabled={data.length > 0 ? false : true}
          isLoading={isProcessing}
        >
          Processar
        </Button>
      </Stack>

      {isOpenDialogImportacao && (
        <DialogImportacao
          isOpen={isOpenDialogImportacao}
          onClose={closeDialogImportacao}
          params={{
            anoMes,
            estabelecimentoId: estabelecimento.id,
            cargoId,
            vrMultiplicador: dataForm.nrMeses,
            vrFator: dataForm.vrFator,
          }}
          onAfterImport={handleAfterImport}
        />
      )}
    </StackContainer>
  )
}
