import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'
import { openDownloadData } from '~/hooks/useUtils'

interface RequestProps {
  params: {
    anoMes: string
    estabelecimentoId: string
    cargoId: string | null
  }
}

export function useGerarPlanilha() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ params }: RequestProps) {
    const response = await api.post<Blob>('/PisoEnfermagem/GerarPlanilha', null, {
      responseType: 'arraybuffer',
      params,
    })

    const contentDisposition = response.headers['content-disposition']
    let filename = 'PISO-ENFERMAGEM.pdf'

    if (contentDisposition) {
      const utf8FilenameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/)
      const asciiFilenameMatch = contentDisposition.match(/filename="(.+?)"/)

      if (utf8FilenameMatch) {
        filename = decodeURIComponent(utf8FilenameMatch[1])
      } else if (asciiFilenameMatch) {
        filename = asciiFilenameMatch[1]
      }
    }

    openDownloadData(filename, response.data)

    notifySuccess('Planilha gerada com sucesso')
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
