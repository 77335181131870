import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { EstabelecimentoConsulta } from '../EstabelecimentoConsulta/EstabelecimentoConsulta'

import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'

export const query = '/RP/ObterEstabelecimentosComRecibosFinalizados'

interface RequestProps {
  data: ReciboIDFEnum[]
  params: {
    anoMes: string
    isLiberarRecibosESocial: boolean
    grupoId?: string
  }
}

export function useObterEstabelecimentosComRecibosFinalizados({ data, params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: EstabelecimentoConsulta[] }>(query, data, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, data, params], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled: false,
  })
}
