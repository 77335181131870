import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { diaHorarioValues } from '~/values/diaHorarioValues'
import { HeightDataTable } from '~/components'

const formatDiaHorario = (diaHorario) => {
  const diaFinded = diaHorarioValues.find((dia) => dia.value === diaHorario)
  return diaFinded?.name || ''
}

const formatNumberInHour = (number) => {
  if (!number) return ''
  return `${number.substr(0, 2)}:${number.substr(2)}`
}

const Table = (props) => {
  const { data: _data, onItemClick, isLoading } = props

  const data = _data?.map((item) => {
    return {
      ...item,
      diaHorario: formatDiaHorario(item?.diaHorario),
      horarioTrabalhoNome:
        (item?.horarioTrabalho?.codigo || '') + ' - ' + (item?.horarioTrabalho?.nome || ''),
      horaEntrada: formatNumberInHour(item?.horarioTrabalho?.horaEntrada),
      horaSaida: formatNumberInHour(item?.horarioTrabalho?.horaSaida),
    }
  })

  const columns = useMemo(
    () => [
      {
        name: 'diaHorario',
        label: 'Dia da Semana Trabalhado',
      },
      {
        name: 'horarioTrabalhoNome',
        label: 'Horário de Trabalho',
      },
      {
        name: 'horaEntrada',
        label: 'Entrada',
      },
      {
        name: 'horaSaida',
        label: 'Saída',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick(tableMeta.rowIndex)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} pagination={false} />
}

export default Table
