import { useState } from 'react'

import api from '~/services/api-pessoal'
import * as yup from 'yup'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import { MultipleSelect, PageHeader, Button, Finder } from '~/components'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import { useObterEstabelecimentosComRecibosNaoFinalizados } from '~/hooks/queries/RP/useObterEstabelecimentosComRecibosNaoFinalizados'

import { ReciboIDFEnum, ReciboIDFValues } from '~/@types/enums/ReciboIDFEnum'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

const recibosIdfsFinalizarRecibo = [
  ReciboIDFEnum.Mensal_1,
  ReciboIDFEnum.Ferias_2,
  ReciboIDFEnum.Complementar_3,
  ReciboIDFEnum.Adiantamento_4,
  ReciboIDFEnum.DecimoTerceiro1aParcela_7,
  ReciboIDFEnum.DecimoTerceiro2aParcela_8,
  ReciboIDFEnum.RescisaoSimulada_11,
  ReciboIDFEnum.Socio_15,
  ReciboIDFEnum.Autonomo_16,
  ReciboIDFEnum.Cooperado_19,
  ReciboIDFEnum.Ferias_Coletivas_21,
]

const recibosIdfsInicializar = [ReciboIDFEnum.Mensal_1, ReciboIDFEnum.Socio_15]

const recibosIdfsInicializarForm = ReciboIDFValues.filter((idr) =>
  recibosIdfsInicializar.includes(idr.value),
)

const recibosIdfs = ReciboIDFValues.filter((idr) => recibosIdfsFinalizarRecibo.includes(idr.value))

const schema = yup.object().shape({
  idfsRecibos: yup.array().min(1, 'Informe pelo menos uma Identificação dos Recibos'),
})

export default function FinalizarRecibos() {
  const [rowsSelecteds, setRowsSelecteds] = useState([])
  const [data, setData] = useState({ idfsRecibos: recibosIdfsInicializarForm, grupoId: undefined })
  const [query, setQuery] = useState('')
  const [isSubmiting, setSubmiting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { anoMes } = useAmbiente()
  const theme = useTheme()
  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit: handleSubmit,
  })
  const {
    data: _c,
    isLoading,
    isFetching,
    refetch,
  } = useObterEstabelecimentosComRecibosNaoFinalizados({
    grupoId: data.grupoId,
    anoMes,
    recibosIdfs: data.idfsRecibos.map((d) => d.value),
  })
  const collection = _c || []

  async function handleSearch() {
    await refetch()
  }

  async function handleSubmit() {
    setSubmiting(true)
    try {
      const newIdfsRecibos = []

      data.idfsRecibos.forEach((d) => {
        newIdfsRecibos.push(d.value)
        if (d.value === ReciboIDFEnum.mensal) {
          newIdfsRecibos.push(ReciboIDFEnum.socio)
        }
      })

      const estabelecimentosIds = rowsSelecteds.map((index) => collection[index].id)

      const _data = {
        idfsRecibos: newIdfsRecibos,
        estabelecimentosIds,
      }

      await api.put('/RP/FinalizarPorEstabelecimento', _data, {
        params: {
          anoMes,
        },
      })
      refetch()
      setRowsSelecteds([])
      notification.success('Os recibos foram finalizados com sucesso')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmiting(false)
    }
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <Box height="50px">
        <PageHeader title="Finalizar Recibos">
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </PageHeader>
      </Box>
      <Box
        component={Paper}
        padding={2}
        display="flex"
        alignItems="center"
        gridGap={theme.spacing(1)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <MultipleSelect
              inputProps={{
                label: 'Identificação dos Recibos',
              }}
              value={data.idfsRecibos}
              options={recibosIdfs}
              getOptionLabel={(option) => option.name}
              onChange={(e, options) => setData((prev) => ({ ...prev, idfsRecibos: options }))}
              limitTags={5}
              required
              validationErrors={validationErrors}
              name="idfsRecibos"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AutoCompleteGrupo
              value={data.grupoId}
              onChange={(e, grupo) => {
                const grupoId = grupo?.id || null
                setData((prev) => ({ ...prev, grupoId }))
              }}
            />
          </Grid>
        </Grid>

        <Button
          variant="contained"
          isLoading={isLoading || isFetching}
          onClick={() => handleSearch()}
        >
          Buscar
        </Button>
      </Box>
      <Table
        data={collection}
        query={query}
        isLoading={isLoading}
        isFetching={isFetching}
        rowsSelected={rowsSelecteds}
        handleRowSelected={setRowsSelecteds}
      />
      <Box display="flex" justifyContent="flex-end" pt={1}>
        <Button
          isLoading={isSubmiting}
          variant="contained"
          size="small"
          color="primary"
          onClick={handleValidate}
          disabled={!(rowsSelecteds.length > 0)}
        >
          Finalizar Recibos
        </Button>
      </Box>
    </Box>
  )
}
