import { formatCurrency } from '~/utils/utils'

import { IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import {
  Button,
  ButtonBox,
  ContainerTable,
  ContentDivider,
  Stack,
  ToolsDataTable,
} from '~/components'

import Form from './Form'

import useDialog from '~/hooks/useDialog'

import { useObterTodos } from '~/hooks/queries/ProcessoTrabalhistaTributosIRRF/useObterTodos'
import { useRemover } from '~/hooks/queries/ProcessoTrabalhistaTributosIRRF/useRemover'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface TributosIRRFProps {
  processoTrabalhistaTributosId: string
  processoTrabalhistaVinculoId?: string
}

export default function TributosIRRF({
  processoTrabalhistaTributosId,
  processoTrabalhistaVinculoId,
}: TributosIRRFProps) {
  const { close: closeForm, isOpen: isOpenForm, open: openForm } = useDialog()
  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterTodos({
    params: {
      processoTrabalhistaTributosId,
      processoTrabalhistaVinculoId,
    },
  })
  const data = _data || []

  const isCadastroPreparado =
    processoTrabalhistaTributosId && processoTrabalhistaVinculoId ? true : false

  const { mutateAsync, isLoading: isRemoving } = useRemover()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigoReceita',
      label: 'Código de Recolhimento',
    },
    {
      name: 'vrCodigoReceita',
      label: 'Valor da Receita',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrCodigoReceita13',
      label: 'Valor da Receita 13°',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={async () => await mutateAsync(value)}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Stack height="100%">
      <ContentDivider
        title="I.R.R.F"
        renderRight={
          <Button
            variant="contained"
            onClick={openForm}
            disabled={isCadastroPreparado ? false : true}
          >
            Adicionar
          </Button>
        }
      />
      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          disableAutoHeight
          pagination={false}
          heightHeader="30px"
          isLoading={isLoading}
          isFetching={isFetching || isRemoving}
        />
      </ContainerTable>

      {isOpenForm && isCadastroPreparado && (
        <Form
          isOpen={isOpenForm}
          onClose={closeForm}
          processoTrabalhistaTributosId={processoTrabalhistaTributosId}
          processoTrabalhistaVinculoId={processoTrabalhistaVinculoId as string}
        />
      )}
    </Stack>
  )
}
