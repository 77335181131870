import { formatToCPF } from 'brazilian-values'
import { formatCurrency } from '~/utils/utils'

import { CopyLabel, CurrencyTextField, ToolsDataTable } from '~/components'

import { PisoEnfermagemFuncionarioDTO } from '~/hooks/queries/PisoEnfermagem/dtos/PisoEnfermagemFuncionarioDTO'
import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableProps {
  query: string
  isLoading: boolean
  isFetching: boolean
  data: PisoEnfermagemFuncionarioDTO[]
  onCalcFuncionario: (rowCurrent: PisoEnfermagemFuncionarioDTO, newValue: number) => void
}

export default function Table({
  query,
  isLoading,
  isFetching,
  data,
  onCalcFuncionario,
}: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'nrInscricao',
      label: 'CPF',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel>,
      },
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'vrComplemento',
      label: 'Complemento',
      options: {
        customBodyRender: (value, { rowData }) => (
          <CurrencyTextField
            value={value}
            onBlur={(_, newValue) => {
              const id = rowData[0]
              const rowCurrent = data.find((d) => d.id === id)
              if (rowCurrent) {
                onCalcFuncionario(rowCurrent, newValue)
              }
            }}
          />
        ),
        setCellProps: () => ({
          style: { textAlign: 'end', width: '150px' },
        }),
      },
    },
    {
      name: 'vrTotal',
      label: 'Total',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end', width: '150px' },
        }),
      },
    },
    {
      name: 'vrBaseReduzida',
      label: 'Base Reduzida',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end', width: '150px' },
        }),
      },
    },
    {
      name: 'vrFerias',
      label: 'Férias',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end', width: '150px' },
        }),
      },
    },
    {
      name: 'vrFGTS',
      label: 'FGTS',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end', width: '150px' },
        }),
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      disableAutoHeight
      isFetching={isFetching}
      sherlock={{
        columns: ['nrInscricao', 'nome'],
        query,
      }}
    />
  )
}
