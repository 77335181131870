import React, { useState, useEffect } from 'react'

import PropType from 'prop-types'

import { TextField, CircularProgress, Box } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ErrorMessage } from 'mio-library-ui'

const obterErro = (name, validationErrors) => {
  if (!validationErrors) return false

  const { inner } = validationErrors
  const erroEncontrado = inner.find((item) => {
    const { path } = item
    return name === path
  })

  if (!erroEncontrado) return false

  return erroEncontrado.message
}

function TextFieldAutoComplete(props) {
  const {
    isLoading,
    label,
    placeholder,
    required,
    inputRef,
    classesInput,
    autoFocus,
    variant,
    InputLabelProps,
    ...params
  } = props
  return (
    <TextField
      {...params}
      label={label}
      placeholder={placeholder}
      required={required}
      inputRef={inputRef}
      variant={variant}
      size="small"
      fullWidth
      autoFocus={autoFocus}
      InputLabelProps={InputLabelProps}
      InputProps={{
        ...params.InputProps,
        classes: { ...classesInput },
        endAdornment: (
          <React.Fragment>
            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }}
    />
  )
}

export default function MUIAutoComplete(props) {
  const {
    label,
    placeholder,
    value: _value,
    onChange,
    getOptions,
    options,
    optionId,
    renderOption,
    isLoading,
    disabled,
    waitSearch,
    inputRef,
    name,
    required,
    validationErrors,
    classesInput,
    filterOptions,
    autoFocus,
    variant,
    InputLabelProps,
    renderOptionComponent,
    ...rest
  } = props

  const [value, setValue] = useState(null)
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')

  const mensagemDeErro = obterErro(name, validationErrors)

  useEffect(() => {
    function getValueCurrent() {
      if (!_value && typeof _value !== 'number') return setValue(null)
      if (typeof _value === 'object') return setValue(_value)
      if (typeof _value === 'string' || typeof _value === 'number') {
        if (!optionId)
          throw new Error(
            "The value type passed is not object, need the prop 'optionId' for find the object in MUIAutoComplete",
          )
        if (!options.length > 0) return getOptions()
        const objectFinded = options.find((obj) => obj[optionId] === _value)
        return setValue(objectFinded)
      }
    }
    getValueCurrent()
    // eslint-disable-next-line
  }, [_value, options])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!(query.length > 0)) return
      getOptions(query)
    }, 500)
    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line
  }, [query])

  function openAutoComplete() {
    setOpen(true)
    if (waitSearch) return
    if (options.length === 0) return getOptions()
  }

  function onInputChange(event, value, reason) {
    if (reason === 'input' && waitSearch) setQuery(value)
  }

  return (
    <Box width="100%">
      <Autocomplete
        id="mui-autocomplete"
        disabled={disabled}
        fullWidth
        open={open}
        onOpen={openAutoComplete}
        onClose={() => setOpen(false)}
        value={value}
        onChange={onChange}
        getOptionLabel={renderOption}
        getOptionSelected={(option, value) => {
          if (!option) return false
          if (!value) return false
          return JSON.stringify(option) === JSON.stringify(value)
        }}
        options={options}
        loading={isLoading}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextFieldAutoComplete
            {...params}
            label={label}
            placeholder={placeholder}
            isLoading={isLoading}
            required={required}
            inputRef={inputRef}
            classesInput={classesInput}
            name={name}
            autoFocus={autoFocus}
            variant={variant}
            InputLabelProps={InputLabelProps}
          />
        )}
        loadingText="Carregando..."
        closeText="Fechar"
        openText="Abrir"
        noOptionsText="Sem resultados"
        clearText="Limpar"
        onInputChange={onInputChange}
        renderOption={renderOptionComponent}
        {...rest}
      />
      {mensagemDeErro && <ErrorMessage error={mensagemDeErro} />}
    </Box>
  )
}

MUIAutoComplete.defaultProps = {
  label: '',
  placeholder: '',
  value: null,
  onChange: () => undefined,
  getOptions: () => undefined,
  options: [],
  optionId: 'id',
  renderOption: () => '',
  isLoading: false,
  disabled: false,
  waitSearch: false,
  inputRef: null,
  name: '',
  required: false,
  validationErrors: false,
  classesInput: null,
  variant: 'outlined',
}

MUIAutoComplete.propTypes = {
  getOptionDisabled: PropType.func,
  renderOption: PropType.func,
  renderOptionComponent: PropType.func,
  getOptions: PropType.func,
  onChange: PropType.func,
  options: PropType.array,
  value: PropType.any,
  optionId: PropType.string,
  required: PropType.bool,
  validationErrors: PropType.any,
  name: PropType.string,
  label: PropType.string,
  title: PropType.string,
  variant: PropType.string,
  disabled: PropType.bool,
  inputRef: PropType.any,
  InputLabelProps: PropType.any,
  ListboxComponent: PropType.any,
  ListboxProps: PropType.any,
  onInputChange: PropType.func,
}
