import { useEffect, useRef } from 'react'

import { Box, Typography, makeStyles } from '@material-ui/core'
import { TreeView as MuiTreeView, TreeItem } from '@material-ui/lab'
import { ExpandMore as ExpandMoreIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons'

import { List } from 'react-virtualized'

import CardItem from './components/CardItem'
import CardMenu from './components/CardMenu'

import { useContextRecibo } from '~/hooks/useRecibo'
import { formatToDigits } from '~/utils/utils'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoMenuDTO } from '~/hooks/queries/RP/VinculoMenuDTO'
import { RP } from '~/hooks/queries/RP/RP'

const BASE_HEIGHT = 32

const useStyles = makeStyles((theme: FixLater) => ({
  highLightItem: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: '2px',
  },
  labelAjusts: {
    width: 'calc(100% - 18px)',
  },
  removeIcon: {
    display: 'none',
  },
}))

interface TreeViewProps {
  data: VinculoMenuDTO[]
  onSelectItem: (event: 'reciboSelecionado', reciboId: string) => void
  onContextMenuItemClick: (event: any, recibo: RP) => void
}

export default function TreeView({ data, onSelectItem, onContextMenuItemClick }: TreeViewProps) {
  const { reciboPagamento, expanded, setExpanded } = useContextRecibo()
  const classes = useStyles()

  const listRef = useRef<List[]>([])
  const rootRef = useRef<any | null>(null)

  const { estabelecimento, anoMes } = useAmbiente()

  useEffect(() => {
    const listsRef = listRef.current

    listsRef.forEach((ref) => {
      ref.recomputeRowHeights()
    })
  }, [expanded, anoMes, estabelecimento, reciboPagamento])

  useEffect(() => {
    setExpanded([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estabelecimento])

  const handleToggle = (nodeIds: string[]) => {
    const novosNodesIds: string[] = []

    nodeIds.forEach((d) => {
      const level = d.split('|')[0]
      if (!novosNodesIds.some((x) => x.split('|')[0] === level)) {
        novosNodesIds.push(d)
      }
    })

    setExpanded(novosNodesIds)
  }

  const heightRoot = rootRef?.current?.offsetHeight
  const widthRoot = rootRef?.current?.offsetWidth

  return (
    <MuiTreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      onNodeToggle={(_, nodes) => handleToggle(nodes)}
      disableSelection={true}
      selected={['2|' + reciboPagamento?.id || '']}
      style={{
        height: 'calc(100% - 4px)',
        minHeight: '300px',
      }}
      ref={rootRef}
    >
      {data?.length === 0 ? (
        <Typography align="center">Registros não encontrados</Typography>
      ) : (
        data.map((group, groupIndex) => {
          const nodeGroupId = `0|${group.agrupamentoId}`
          return (
            <TreeItem
              key={nodeGroupId}
              nodeId={nodeGroupId}
              label={
                <CardItem
                  labelText={group.agrupamentoNome}
                  labelInfo={() => (
                    <Box fontWeight="bold">{formatToDigits(group?.vinculos?.length || 0, 2)}</Box>
                  )}
                  bolder={true}
                />
              }
            >
              <List
                id="boxTreeVinculo"
                ref={(el) => {
                  if (el) {
                    listRef.current[groupIndex] = el
                  }
                }}
                width={widthRoot - 16}
                height={
                  heightRoot - (data?.length || 1) * BASE_HEIGHT <= 200
                    ? 200
                    : heightRoot - (data?.length || 1) * BASE_HEIGHT
                }
                autoHeight={estabelecimento?.isRPLotacao}
                rowHeight={({ index }) => {
                  const vinculoCurrent = group.vinculos[index]

                  let heightCurrent = 0

                  if (expanded.some((d) => d.includes(vinculoCurrent.vinculoId))) {
                    heightCurrent = BASE_HEIGHT * vinculoCurrent.recibos.length
                  }

                  return BASE_HEIGHT + heightCurrent
                }}
                rowCount={group.vinculos.length}
                overscanRowCount={2}
                rowRenderer={({ index: indexSecond, style }) => {
                  const vinculoCurrent = group.vinculos[indexSecond]
                  const recibos = vinculoCurrent?.recibos || []
                  const nodeId = `1|${vinculoCurrent.vinculoId}`
                  return (
                    <TreeItem
                      style={style}
                      key={nodeId}
                      nodeId={nodeId}
                      onLabelClick={() => {
                        const isOpen = expanded.includes(nodeId)
                        if (recibos.length > 0 && !isOpen) {
                          onSelectItem('reciboSelecionado', recibos[0].reciboId)
                        }
                      }}
                      classes={{
                        expanded: classes.highLightItem,
                        label: classes.labelAjusts,
                      }}
                      label={
                        <CardItem
                          labelText={vinculoCurrent.vinculoNome}
                          bolder={false}
                          labelInfo={(isHover) => (
                            <CardMenu
                              vinculo={vinculoCurrent}
                              agrupamentoId={group.agrupamentoId}
                              showActions={isHover}
                              onContextMenuItemClick={onContextMenuItemClick}
                            />
                          )}
                        />
                      }
                    >
                      {recibos.map((reciboCurrent) => (
                        <TreeItem
                          key={`2|${reciboCurrent.reciboId}`}
                          nodeId={`2|${reciboCurrent.reciboId}`}
                          label={<CardItem labelText={`${reciboCurrent.idfDesc}`} />}
                          onLabelClick={(e) => {
                            e.preventDefault()
                            onSelectItem('reciboSelecionado', reciboCurrent.reciboId)
                          }}
                          onKeyDown={(e) => {
                            const { key } = e
                            if (key === 'Enter') {
                              onSelectItem('reciboSelecionado', reciboCurrent.reciboId)
                            }
                          }}
                          classes={{
                            iconContainer: classes.removeIcon,
                          }}
                        />
                      ))}
                    </TreeItem>
                  )
                }}
              />
            </TreeItem>
          )
        })
      )}
    </MuiTreeView>
  )
}
