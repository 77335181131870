import { formatCurrency } from '~/utils/utils'

import { Box, useTheme, makeStyles, Paper } from '@material-ui/core'

import {
  ActionDialog,
  Button,
  ButtonBox,
  ContainerTable,
  LabelValueColor,
  Stack,
  ToolsDataTable,
} from '~/components'

import { useObterDetalhamentoGPSPorCodigoReceita } from '~/hooks/queries/ApurarEncargos/useObterDetalhamentoGPSPorCodigoReceita'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { DetalhamentoGPSCodigoReceitaEnum } from '~/@types/enums/DetalhamentoGPSCodigoReceitaEnum'
import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}))

interface DetalhamentoGPSPorCodigoReceitaProps {
  isOpen: boolean
  onClose: () => void
  params: {
    empregadorId: string
    periodoApuracao: string
    tipoRequisicao: DetalhamentoGPSCodigoReceitaEnum
    indApuracao: IndApuracaoEnum
  }
}

export default function DetalhamentoGPSPorCodigoReceita({
  isOpen,
  onClose,
  params,
}: DetalhamentoGPSPorCodigoReceitaProps) {
  const classes = useStyles()
  const theme = useTheme()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterDetalhamentoGPSPorCodigoReceita({
    params,
  })
  const data = _d?.receitas || []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'descricaoTipoCodigoReceita',
      label: 'Código Receita',
      options: {
        customBodyRender: (value, { rowIndex }) => (
          <Box display="flex" gridGap={10} alignItems="center">
            <Box
              bgcolor={data[rowIndex].isDiferenca ? theme.palette.error.light : undefined}
              height="26px"
              width="12px"
              borderRadius="0px 8px 8px 0px"
              ml={-1.5}
            />
            {value}
          </Box>
        ),
      },
    },
    {
      name: 'vrCodigoReceitaFolha',
      label: 'Valor Folha',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: (_, rowIndex) => ({
          style: {
            color: data[rowIndex]?.isDiferenca ? theme.palette.error.main : undefined,
          },
        }),
      },
    },
    {
      name: 'vrCodigoReceitaESocial',
      label: 'Valor eSocial',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: (_, rowIndex) => ({
          style: {
            color: data[rowIndex]?.isDiferenca ? theme.palette.error.main : undefined,
          },
        }),
      },
    },
    {
      name: 'vrDiferenca',
      label: 'Diferença',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: (_, rowIndex) => ({
          style: {
            color: data[rowIndex]?.isDiferenca ? theme.palette.error.main : undefined,
          },
        }),
      },
    },
  ]

  const colorFooterValues = _d?.isDiferenca ? theme.palette.error.light : '#2E8BC0'

  return (
    <ActionDialog
      title="Apuração Encargos - Detalhamento por Código de Receita"
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
        classes: {
          paper: classes.fullHeight,
        },
      }}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <Stack height="100%">
        <ContainerTable>
          <ToolsDataTable
            data={data}
            columns={columns}
            isLoading={isLoading}
            isFetching={isFetching}
            disableAutoHeight
          />
        </ContainerTable>

        <Box component={Paper} padding={1.5} display="flex" justifyContent="flex-end">
          <LabelValueColor
            value={formatCurrency(_d?.vrTotalReceitaFolha || 0, true)}
            label="Total da Folha"
            color={colorFooterValues}
          />
          <LabelValueColor
            value={formatCurrency(_d?.vrTotalReceitaESocial || 0, true)}
            label="Total do eSocial"
            color={colorFooterValues}
          />
          <LabelValueColor
            value={formatCurrency(_d?.vrDiferenca || 0, true)}
            label="Diferença"
            color={colorFooterValues}
          />
        </Box>
      </Stack>
    </ActionDialog>
  )
}
