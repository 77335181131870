import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { IndConsultaVencimentosEnum } from '~/@types/enums/IndConsultaVencimentosEnum'
import { ConsultaVencimentosSortEnum } from '~/@types/enums/ConsultaVencimentosSortEnum'
import { ConsultaVencimentosFuncionarioDTO } from './dtos/ConsultaVencimentosFuncionarioDTO'

const query = '/ConsultaVencimentos/ObterConsultaVencimentos'

interface RequestProps {
  params: {
    dtInicio: string
    dtVencimento: string
    sortField: ConsultaVencimentosSortEnum
  }
  data: IndConsultaVencimentosEnum[]
}

export function useObterConsultaVencimentos({ data, params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: ConsultaVencimentosFuncionarioDTO[] }>(query, data, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, data, params], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled: false,
  })
}
