import { useState } from 'react'

import moment from 'moment'

import { Box, useTheme } from '@material-ui/core'

import { ActionMenu, Button, ToolsDataTable } from '~/components'

import TableCellContent from './TableCellContent'

import { useAlterarStatusEvento } from '~/hooks/queries/ESocial/useAlterarStatusEvento'
import useDialogNotification from '~/hooks/useDialogNotification'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ESocialAuditoriaTransmitirDTO } from '~/hooks/queries/ESocial/useObterEventosTransmissaoEnvio'

import { ESocialGrupoEventoConsultaEnum } from '~/@types/enums/ESocialGrupoEventoConsultaEnum'
import { ESocialStatusEnum } from '~/@types/enums/ESocialStatusEnum'

interface TableEventosAnaliticoProps {
  data: ESocialAuditoriaTransmitirDTO[]
  grupo: ESocialGrupoEventoConsultaEnum
}

export default function TableEventosAnalitico(props: TableEventosAnaliticoProps) {
  const { data: _data, grupo } = props

  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const { mutateAsync: mutateAsyncAlterarStatusEvento, isLoading: isLoadingAlterarStatusEvento } =
    useAlterarStatusEvento()
  const theme = useTheme()
  const dialogNotification = useDialogNotification()

  const isGrupoErro = grupo === ESocialGrupoEventoConsultaEnum.Erro

  const data = _data.map((d) => ({
    ...d,
    dtMovimento: d?.dtMovimento ? moment(d.dtMovimento).format('DD/MM/yyyy - HH:mm:ss') : '',
    dtESocialRecibo: d?.dtESocialRecibo
      ? moment(d.dtESocialRecibo).format('DD/MM/yyyy - HH:mm:ss')
      : '',
    retorno_DataHora: d?.auditoriaXML?.retorno_DataHora
      ? moment(d.auditoriaXML.retorno_DataHora).format('DD/MM/yyyy - HH:mm:ss')
      : '',
    ocorrencias: d?.auditoriaXML?.ocorrencias ? d.auditoriaXML.ocorrencias : '',
    errosSchema: d?.auditoriaXML?.errosSchema ? d.auditoriaXML.errosSchema : '',
    xmlEnvio: d?.auditoriaXML?.xmlEnvio ? d.auditoriaXML.xmlEnvio : '',
    retorno_Xml: d?.auditoriaXML?.retorno_Xml ? d.auditoriaXML.retorno_Xml : '',
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'auditoriaDescricao',
      label: 'Descrição',
    },
    {
      name: 'tabelaOrigem',
      label: 'Origem',
    },
    {
      name: 'dtMovimento',
      label: 'Data do Movimento',
    },
    {
      name: 'tipoMovimento',
      label: 'Tipo Movimento',
    },
    {
      name: 'indRetificacao',
      label: 'Ind. Retificação',
    },
    {
      name: 'eSocialRecibo',
      label: 'Número do Recibo',
      options: {
        display:
          grupo === ESocialGrupoEventoConsultaEnum.Finalizado ||
          grupo === ESocialGrupoEventoConsultaEnum.Finalizado_Advertencia
            ? 'true'
            : 'excluded',
      },
    },
    {
      name: 'dtESocialRecibo',
      label: 'Data do Recibo',
      options: {
        display:
          grupo === ESocialGrupoEventoConsultaEnum.Finalizado ||
          grupo === ESocialGrupoEventoConsultaEnum.Finalizado_Advertencia
            ? 'true'
            : 'excluded',
      },
    },
    {
      name: 'retorno_DataHora',
      label: 'Data/Hora Retorno',
      options: {
        display:
          grupo === ESocialGrupoEventoConsultaEnum.Finalizado_Advertencia ||
          grupo === ESocialGrupoEventoConsultaEnum.Erro
            ? 'true'
            : 'excluded',
      },
    },
    {
      name: 'auditoriaId',
      label: 'Ocorrências',
      options: {
        display:
          grupo === ESocialGrupoEventoConsultaEnum.Finalizado_Advertencia ||
          grupo === ESocialGrupoEventoConsultaEnum.Erro
            ? 'true'
            : 'excluded',
        customBodyRender: (value, { rowData }) => {
          const nome = rowData[1]
          return (
            <TableCellContent auditoriaId={value} type="ocorrencias" subtitle={nome}>
              Ocorrências
            </TableCellContent>
          )
        },
      },
    },
    {
      name: 'auditoriaId',
      label: 'Erros Schema',
      options: {
        display:
          grupo === ESocialGrupoEventoConsultaEnum.Finalizado_Advertencia ||
          grupo === ESocialGrupoEventoConsultaEnum.Erro
            ? 'true'
            : 'excluded',
        customBodyRender: (value, { rowData }) => {
          const nome = rowData[1]
          return (
            <TableCellContent auditoriaId={value} type="errosSchema" subtitle={nome}>
              Erros Schema
            </TableCellContent>
          )
        },
      },
    },
    {
      name: 'auditoriaId',
      label: 'XML Envio',
      options: {
        customBodyRender: (value, { rowData }) => {
          const nome = rowData[1]
          return (
            <TableCellContent auditoriaId={value} type="xmlEnvio" subtitle={nome}>
              XML Envio
            </TableCellContent>
          )
        },
      },
    },
    {
      name: 'auditoriaId',
      label: 'XML Retorno',
      options: {
        customBodyRender: (value, { rowData }) => {
          const nome = rowData[1]
          return (
            <TableCellContent auditoriaId={value} type="xmlRetorno" subtitle={nome}>
              XML Retorno
            </TableCellContent>
          )
        },
      },
    },
  ]

  function handleAlterarStatus(
    status: ESocialStatusEnum.RegistroInativo_99 | ESocialStatusEnum.Aberto_10,
  ) {
    if (status === ESocialStatusEnum.RegistroInativo_99) {
      dialogNotification.info({
        descriptions: [
          'Deseja realmente inativar os eventos selecionados?',
          'O evento só deve ser inativado se já estiver registrado no eSocial ou se não houver necessidade de envio.',
        ],
        onConfirm: () => handleChangeStatus(status),
        labelOnConfirm: 'Confirmar',
      } as FixLater)
    } else {
      handleChangeStatus(status)
    }
  }

  async function handleChangeStatus(status: ESocialStatusEnum) {
    const eventosIds = rowsSelected.map((indexSelected) => data[indexSelected].auditoriaId)
    await mutateAsyncAlterarStatusEvento({
      alterarParaStatus: status,
      eventosIds,
    })
    setRowsSelected([])
  }

  return (
    <>
      {isGrupoErro && (
        <Box
          position="fixed"
          right={theme.spacing(1)}
          bottom={theme.spacing(1)}
          display="flex"
          zIndex={theme.zIndex.appBar}
          gridGap={theme.spacing(1)}
        >
          <ActionMenu
            options={[
              {
                label: 'Inativar Eventos',
                onClick: () => handleAlterarStatus(ESocialStatusEnum.RegistroInativo_99),
                icon: 'remove_circle_outline',
              },
            ]}
            isLoading={isLoadingAlterarStatusEvento}
            disabled={rowsSelected.length > 0 ? false : true}
          />

          <Button
            variant="contained"
            onClick={() => handleAlterarStatus(ESocialStatusEnum.Aberto_10)}
            disabled={rowsSelected.length > 0 ? false : true}
            isLoading={isLoadingAlterarStatusEvento}
          >
            Alterar Status para Aguardando
          </Button>
        </Box>
      )}

      <ToolsDataTable
        data={data}
        columns={columns}
        disableAutoHeight
        optionsSelectable={{
          type: 'multiple',
          rowsSelected: rowsSelected,
          onRowSelected: setRowsSelected,
        }}
      />
    </>
  )
}
