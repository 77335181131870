import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { DetalhamentoGPSCodigoReceitaEnum } from '~/@types/enums/DetalhamentoGPSCodigoReceitaEnum'
import { DetalhamentoGPSPorCodigoReceitaRespostaDTO } from './dtos/DetalhamentoGPSPorCodigoReceita/DetalhamentoGPSPorCodigoReceitaRespostaDTO'
import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'

const query = '/ApurarEncargos/ObterDetalhamentoGPSPorCodigoReceita'

interface RequestProps {
  params: {
    empregadorId: string
    periodoApuracao: string
    tipoRequisicao: DetalhamentoGPSCodigoReceitaEnum
    indApuracao: IndApuracaoEnum
  }
}

export function useObterDetalhamentoGPSPorCodigoReceita({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: DetalhamentoGPSPorCodigoReceitaRespostaDTO }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
