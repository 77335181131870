import React from 'react'

import { IconButton } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'
import { Visibility as VisibilityIcon } from '@material-ui/icons'

import { ToolsDataTable } from '~/components'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import moment from 'moment'

import { contratoExperienciaConsts } from '~/values/contratoExperienciaValues'

export default function Table(props) {
  const {
    data: _data,
    isLoading,
    query,
    onClickItem,
    rowsSelected,
    onRowSelected,
    tipoContrato,
  } = props

  const data = _data.map((d) => ({
    ...d,
    nrInscricao: d?.nrInscricao ? formatToCPFOrCNPJ(d.nrInscricao) : '',
    cargo: d?.contrato?.cargo || '',
    nrDias: d?.contrato?.nrDias || 0,
    dtInicio: d?.contrato?.dtInicio ? moment(d.contrato.dtInicio).format('DD/MM/yyyy') : 0,
    dtFim: d?.contrato?.dtFim ? moment(d.contrato.dtFim).format('DD/MM/yyyy') : 0,
    vrRemuneracao: d?.contrato?.vrRemuneracao,
    local: d?.contrato?.local,

    nrDiasProrrogacao: d?.contrato?.nrDiasProrrogacao || 0,
    dtInicioProrrogacao: d?.contrato?.dtInicioProrrogacao
      ? moment(d.contrato.dtInicioProrrogacao).format('DD/MM/yyyy')
      : 0,
    dtFimProrrogacao: d?.contrato?.dtFimProrrogacao,
  }))

  const isContratoExperiencia = tipoContrato === contratoExperienciaConsts.contratoExperiencia

  const columns = [
    {
      name: 'nrInscricao',
      label: 'CPF',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'cargo',
      label: 'Cargo',
    },
    {
      name: isContratoExperiencia ? 'nrDias' : 'nrDiasProrrogacao',
      label: 'Número de Dias',
    },
    {
      name: isContratoExperiencia ? 'dtInicio' : 'dtInicioProrrogacao',
      label: 'Data de Início',
    },
    {
      name: isContratoExperiencia ? 'dtFim' : 'dtFimProrrogacao',
      label: 'Data de Fim',
    },
    {
      name: 'vrRemuneracao',
      label: 'Remuneração',
    },
    {
      name: 'local',
      label: 'Local e Data',
    },
    {
      name: 'vinculoId',
      label: 'Horários de trabalho',
      options: {
        customBodyRender: (vinculoIdCurrent) => {
          const dataFinded = data.find((d) => d.vinculoId === vinculoIdCurrent)
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Visualizar horários de trabalho"
                color="primary"
                aria-label="Visualizar"
                onClick={() => onClickItem(dataFinded)}
              >
                <VisibilityIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      sherlock={{
        columns: ['nrInscricao', 'nome'],
        query,
      }}
      optionsSelectable={{
        type: 'multiple',
        rowsSelected,
        onRowSelected,
      }}
      disableAutoHeight
    />
  )
}
