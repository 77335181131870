import { useEffect, useRef, useState, memo } from 'react'

import { Grid, Paper } from '@material-ui/core'

import { ContainerTable, Finder, Stack } from '~/components'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

import Table from './Table'

import useAmbiente from '~/hooks/useAmbiente'
import useSocket from '~/hooks/useSocket'
import useLocalStorage from '~/hooks/useLocalStorage'

import constants from '~/utils/constants'

import { useObterProcessamentoFolha } from '~/hooks/queries/FolhaAutomatica/useObterProcessamentoFolha'

const MemoTable = memo(Table)

export default function Processamento() {
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([])
  const [query, setQuery] = useLocalStorage(constants.query, '')
  const [grupoId, setGrupoId] = useLocalStorage(constants.grupoId, '')

  const tableRef = useRef<FixLater>(null)

  const { empresaId, anoMes } = useAmbiente()

  const [anoMesAntigo, setAnoMesAntigo] = useState<string | null>(null)

  const prevAnoMesRef = useRef<string | null>(null)
  useEffect(() => {
    if (anoMes !== prevAnoMesRef.current) {
      setAnoMesAntigo(prevAnoMesRef.current)
      prevAnoMesRef.current = anoMes
    }
  }, [anoMes])

  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useObterProcessamentoFolha({
    params: {
      anoMes,
      grupoId,
    },
  })
  const data = _d || []

  const socket = useSocket({
    urlHub: '/TaskQueueHub',
    onStartConnection: (socket) => {
      socket.send('AddToGroup', empresaId + anoMes)
    },
  })

  useEffect(() => {
    if (!socket) return
    socket.on('UpdateTask', () => refetch())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  useEffect(() => {
    if (!socket) return
    if (anoMesAntigo) {
      socket.send('RemoveFromGroup', empresaId + anoMesAntigo)
    }
    socket.send('AddToGroup', empresaId + anoMes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, empresaId, anoMesAntigo])

  return (
    <Stack height="100%">
      <Stack
        component={Paper}
        p={1}
        orientation="horizontal"
        alignItems="center"
        justifyContent="space-between"
        height={55}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <AutoCompleteGrupo
              value={grupoId}
              onChange={(e, grupo) => {
                setGrupoId(grupo?.id || '')
              }}
            />
          </Grid>
        </Grid>
        <Finder
          isOpen={true}
          defaultValue={query}
          onSearch={setQuery}
          onClose={() => setQuery('')}
        />
      </Stack>

      <ContainerTable>
        <MemoTable
          data={data}
          rowsExpanded={rowsExpanded}
          setRowsExpanded={setRowsExpanded}
          query={query}
          isLoading={isLoading}
          isFetching={isFetching}
          tableRef={tableRef}
          socket={socket}
        />
      </ContainerTable>
    </Stack>
  )
}
