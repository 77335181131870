import { ModeloRelatorioSimplesDTO } from '~/hooks/queries/ModeloRelatorio/dtos/ModeloRelatorioSimplesDTO'
import MUIAutoComplete from '../MUIAutoComplete'

import { useObterModelos } from '~/hooks/queries/ModeloRelatorio/useObterModelos'

interface AutoCompleteModeloRelatorioProps {
  value: string
  onChange: (id: string) => void
}

export default function AutoCompleteModeloRelatorio({
  value,
  onChange,
}: AutoCompleteModeloRelatorioProps) {
  const { data: _d, isLoading: _isLoading, isFetching, refetch } = useObterModelos()

  const options = _d || []
  const isLoading = _isLoading || isFetching
  const optionId = 'id'

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={refetch}
      renderOption={(d: ModeloRelatorioSimplesDTO) => d.nome}
      label="Modelo de Relatório"
      optionId={optionId}
      value={value}
      onChange={(_, d: ModeloRelatorioSimplesDTO) => onChange(d ? d[optionId] : '')}
    />
  )
}
