import React, { useState, useEffect, useCallback } from 'react'

import { Grid, MenuItem } from '@material-ui/core'
import { TextField, ContentDivider } from 'mio-library-ui'

import {
  NumeroInscricaoTextField,
  DatePicker,
  SimpleHoraTextField,
  ContainerForm,
  Checkbox,
} from '~/components'
import { MUIAutoComplete, AutoCompleteSindicato } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import api from '~/services/api-pessoal'
import moment from 'moment'
import * as yup from 'yup'
import { tipoContratoTrabalhoValues } from '~/values/tipoContratoTrabalhoValues'
import { JornadaTipoValues } from '~/@types/enums/JornadaTipoEnum'
import { jornadaTempoParcialValues } from '~/values/jornadaTempoParcialValues'
import { indUnidadeSalarioValues } from '~/values/indUnidadeSalarioValues'
import { naturezaAtividadeValues } from '~/values/naturezaAtividadeValues'

const schema = yup.object().shape({
  tipoContrato: yup.string().required('Informe o Tipo de Contrato'),
  tipoJornada: yup.string().required('Informe o Tipo de Jornada'),
  jornadaParcial: yup.string().required('Informe a Jornada Parcial'),
  unidadeSalarioFixo: yup.string().required('Informe a Unidade Salário Fixo'),
  naturezaAtividade: yup.string().required('Informe a Natureza Atividade'),
})

const Form = (props) => {
  const { vinculo: _data, onClickClose, setWasModified } = props

  const [data, setData] = useState({})
  const [oldData, setOldData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    const formatData = () => {
      const newData = {
        ..._data,
        horasMes: _data?.horasMes ? _data.horasMes.toFixed(2) : null,
        horasSemana: _data?.horasSemana ? _data.horasSemana.toFixed(2) : null,
        numeroDiasContrato: _data?.contratoExperienciaDias || '',
        dtVencimentoContratoExperiencia: _data?.contratoExperienciaDtVencimento || '',
      }
      setData(newData)
      setOldData(newData)
    }
    formatData()
  }, [_data])

  useEffect(() => {
    const checkModification = () => {
      if (JSON.stringify(oldData) !== JSON.stringify(data)) {
        setWasModified(true)
      } else {
        setWasModified(false)
      }
    }
    checkModification()
    //eslint-disable-next-line
  }, [data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        await api.put(`/vinculo/${data.id}`, data)
        notification.put()
        onClickClose()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    update()
    // eslint-disable-next-line
  }, [data])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ContainerForm handleSubmit={handleValidate} onCancel={onClickClose} isLoading={isSubmitting}>
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <ContentDivider top={2} />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
          <TextField
            label="Pessoa Física"
            fullWidth
            value={data?.pessoaFisica?.nome || ''}
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <NumeroInscricaoTextField
            label="CPF"
            value={data?.pessoaFisica?.nrInscricao || ''}
            disabled
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <TextField
            label="Regime Previdência"
            fullWidth
            value={data.tipoRegimePrevidencia || ''}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const tipoRegimePrevidencia = e.target.value
              setData({ ...data, tipoRegimePrevidencia })
            }}
            select
          >
            <MenuItem value="1">Regime Geral da Previdência Social - RGPS</MenuItem>
            <MenuItem value="2">Regime Próprio de Previdência Social - RPPS </MenuItem>
            <MenuItem value="3">Regime Próprio de Previdência Social no Exterior - RPPE</MenuItem>
          </TextField>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <MUIAutoComplete
            label="Unidade Salário Fixo"
            options={indUnidadeSalarioValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="unidadeSalarioFixo"
            value={data.unidadeSalarioFixo}
            onChange={(e, obj) => {
              const unidadeSalarioFixo = obj ? obj.value : ''
              setData({ ...data, unidadeSalarioFixo })
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteSindicato
            label="Sindicato"
            value={data?.sindicatoId || null}
            onChange={(e, sindicatoObj) => {
              const sindicato = sindicatoObj || null
              const sindicatoId = sindicatoObj.id || ''
              setData({
                ...data,
                sindicatoId,
                sindicato,
              })
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <TextField
            label="Regime Jornada"
            fullWidth
            value={data?.tipoRegimeJornada || ''}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const tipoRegimeJornada = e.target.value
              setData({ ...data, tipoRegimeJornada })
            }}
            select
          >
            <MenuItem value="1">Submetido a Horário de Trabalho</MenuItem>
            <MenuItem value="2">Atividade Externa</MenuItem>
            <MenuItem value="3">Funções Específicas</MenuItem>
            <MenuItem value="4">Teletrabalho</MenuItem>
          </TextField>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <MUIAutoComplete
            label="Natureza Atividade"
            options={naturezaAtividadeValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="naturezaAtividade"
            value={data.naturezaAtividade}
            onChange={(e, obj) => {
              const naturezaAtividade = obj ? obj.value : ''
              setData({ ...data, naturezaAtividade })
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <MUIAutoComplete
            label="Tipo de Contrato"
            options={tipoContratoTrabalhoValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="tipoContrato"
            value={data.tipoContrato}
            onChange={(e, obj) => {
              const tipoContrato = obj ? obj.value : ''
              setData({ ...data, tipoContrato })
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Objeto Determinante Para Fim do Contrato"
            fullWidth
            value={data.objetoDeterminante || ''}
            variant="outlined"
            size="small"
            multiline
            inputProps={{
              maxLength: 200,
            }}
            onChange={(e) => {
              const objetoDeterminante = e.target.value
              setData({ ...data, objetoDeterminante })
            }}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={4} xs={12}>
          <TextField
            label="Número de Dias"
            variant="outlined"
            size="small"
            fullWidth
            onlyNumber
            disabled
            inputProps={{
              maxLength: 6,
            }}
            value={data?.numeroDiasContrato || ''}
            onChange={(e) => {
              const numeroDiasContrato = e.target.value
              setData((oldState) => ({
                ...oldState,
                numeroDiasContrato,
              }))
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={8} xs={12}>
          <DatePicker
            label="Vencimento Experiência"
            size="small"
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            value={data?.dtVencimentoContratoExperiencia || null}
            onChange={(date) => {
              const dtVencimentoContratoExperiencia = date ? date.format('yyyy-MM-DD') : null
              setData({
                ...data,
                dtVencimentoContratoExperiencia,
              })
            }}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={4} xs={12}>
          <TextField
            label="Dias Prorrogação"
            fullWidth
            value={data?.contratoExperienciaDiasProrrogacao || ''}
            variant="outlined"
            size="small"
            onlyNumber
            onBlur={() => {
              if (
                data?.contratoExperienciaDiasProrrogacao &&
                data?.dtVencimentoContratoExperiencia
              ) {
                const contratoExperienciaDtProrrogacao = moment(
                  data?.dtVencimentoContratoExperiencia,
                ).add(data?.contratoExperienciaDiasProrrogacao, 'day')
                setData((oldState) => ({
                  ...oldState,
                  contratoExperienciaDtProrrogacao,
                }))
              }
            }}
            onChange={(e) => {
              const contratoExperienciaDiasProrrogacao = e.target.value
              setData({
                ...data,
                contratoExperienciaDiasProrrogacao,
              })
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={8} xs={12}>
          <DatePicker
            label="Vencimento Prorrogação do Contrato"
            size="small"
            value={data.contratoExperienciaDtProrrogacao || null}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(date) => {
              const contratoExperienciaDtProrrogacao = date ? date.format('yyyy-MM-DD') : null
              setData({
                ...data,
                contratoExperienciaDtProrrogacao,
              })
            }}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <DatePicker
            label="Término Contrato"
            size="small"
            value={data.dtTerminoContrato || null}
            onChange={(date) => {
              const dtTerminoContrato = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtTerminoContrato })
            }}
          />
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          <TextField
            label="Descrição Justificativa Prorrogação de Contrato"
            fullWidth
            value={data?.justificativaProrrogacaoContrato || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) => {
              const justificativaProrrogacaoContrato = e?.target?.value || null
              setData({ ...data, justificativaProrrogacaoContrato })
            }}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
          <SimpleHoraTextField
            label="Horas Semana"
            value={data?.horasSemana || ''}
            onChange={(e) => {
              const horasSemana = e.target.value.replace(':', '.')
              setData((oldState) => ({ ...oldState, horasSemana }))
            }}
          />
        </Grid>
        <Grid item xl={10} lg={10} md={10} sm={8} xs={12}>
          <MUIAutoComplete
            label="Tipo de Jornada"
            options={JornadaTipoValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="tipoJornada"
            value={data.tipoJornada}
            onChange={(e, obj) => {
              const tipoJornada = obj ? obj.value : ''
              setData((oldState) => ({ ...oldState, tipoJornada }))
            }}
          />
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <MUIAutoComplete
            label="Jornada Parcial"
            options={jornadaTempoParcialValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="jornadaParcial"
            value={data.jornadaParcial}
            onChange={(e, obj) => {
              const jornadaParcial = obj ? obj.value : ''
              setData({ ...data, jornadaParcial })
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Checkbox
            label="Horário Nortuno"
            value={data?.isHorarioNoturno || false}
            onChange={(e, value) => {
              const isHorarioNoturno = value
              setData({ ...data, isHorarioNoturno })
            }}
          />
        </Grid>
      </Grid>
    </ContainerForm>
  )
}

export default Form
