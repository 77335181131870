import React, { useState } from 'react'

import * as yup from 'yup'

import { ButtonBox } from 'mio-library-ui'
import { Box, Grid, MenuItem } from '@material-ui/core'

import {
  ContentDivider,
  DatePickerNew,
  NumeroInscricaoTextField,
  TextField,
  Checkbox as CheckboxComponent,
  Button,
  PageHeader,
} from '~/components'
import { AutoCompleteSindicato, MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'

import { VinculoPartial } from '~/hooks/queries/Vinculo/Vinculo'
import { CategoriaValues } from '~/@types/enums/CategoriaEnum'
import { TipoAdmissaoValues } from '~/@types/enums/TipoAdmissaoEnum'
import { IndGrauExposicaoValues } from '~/@types/enums/IndGrauExposicaoEnum'
import { IndMultiploVinculoValues } from '~/@types/enums/IndMultiploVinculoEnum'
import { NaturezaAtividadeValues } from '~/@types/enums/NaturezaAtividadeEnum'
import { IndUnidadeSalarioValues } from '~/@types/enums/IndUnidadeSalarioEnum'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'
import { useStepperContext } from '~/components/StepperForm'
import { MainDataForm } from '..'

const schema = yup.object().shape({
  indGrauExposicao: yup.string().required('Informe o Indicador Grau Exposição'),
  indMultiplosVinculos: yup.string().required('Informe o Indicador Múltiplos Vínculos'),
  tipoAdmissao: yup.string().required('Informe o Tipo de Admissão'),
  unidadeSalarioFixo: yup.string().required('Informe a Unidade Salário Fixo'),
  sindicatoId: yup.string().required('Informe o Sindicato').nullable(),
  naturezaAtividade: yup.string().required('Informe a Natureza Atividade'),
})

export default function FormAdmissao() {
  const { mainData, onCancel, onNext } = useStepperContext<MainDataForm>()

  const [data, setData] = useState<VinculoPartial>(mainData.dataInitialForm)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const hasAdmissaoPreliminar = mainData?.dataInitialForm.reciboAdmissaoPreliminar ? true : false

  function handleSubmit() {
    onNext({
      ...mainData,
      dataInitialForm: data,
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <PageHeader title="Admissão" />
      </Grid>

      <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
        <TextField
          label="Pessoa Física"
          fullWidth
          value={data?.pessoaFisica?.nome || ''}
          size="small"
          disabled
        />
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
        <NumeroInscricaoTextField
          label="CPF"
          value={data?.pessoaFisica?.nrInscricao || ''}
          disabled
        />
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <ContentDivider title="Informações da Admissão" />
      </Grid>

      <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
        <DatePickerNew
          label="Data Admissão"
          size="small"
          value={data?.dtAdmissao || null}
          disabled
        />
      </Grid>
      <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
        <DatePickerNew label="Opção FGTS" size="small" value={data?.dtOpcaoFGTS || null} disabled />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
        <TextField
          label="Matrícula"
          fullWidth
          value={data?.matricula || ''}
          size="small"
          disabled
        />
      </Grid>

      <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
        <TextField
          label="Registro Livro"
          fullWidth
          value={data.registroLivro || ''}
          size="small"
          onlyNumber
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            maxLength: 4,
          }}
          onChange={(e) => {
            const registroLivro = e?.target?.value || ''
            setData({ ...data, registroLivro })
          }}
        />
      </Grid>

      <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
        <TextField
          label="Registro Folha"
          fullWidth
          value={data.registroFolha || ''}
          size="small"
          onlyNumber
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            maxLength: 4,
          }}
          onChange={(e) => {
            const registroFolha = e?.target?.value || ''
            setData({ ...data, registroFolha })
          }}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={8} sm={12} xs={12}>
        <MUIAutoComplete
          label="Categoria"
          options={CategoriaValues}
          renderOption={(option: FixLater) => option.name}
          optionId="value"
          disabled
          value={data.categoria}
        />
      </Grid>

      <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
        <MUIAutoComplete
          label="Tipo de Admissão"
          options={TipoAdmissaoValues}
          required
          optionId="value"
          renderOption={(option: FixLater) => option.name}
          validationErrors={validationErrors}
          name="tipoAdmissao"
          value={data.tipoAdmissao}
          onChange={(e: FixLater, obj: FixLater) => {
            const tipoAdmissao = obj ? obj.value : ''
            setData({ ...data, tipoAdmissao })
          }}
        />
      </Grid>

      <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
        <TextField
          label="Indicador de Admissão"
          fullWidth
          value={data?.indAdmissao || ''}
          size="small"
          onChange={(e) => {
            const indAdmissao = e?.target?.value || ''
            setData({ ...data, indAdmissao })
          }}
          select
        >
          <MenuItem value="1">Normal</MenuItem>
          <MenuItem value="2">Decorrente de Ação Fiscal</MenuItem>
          <MenuItem value="3">Decorrente de Decisão Judicial</MenuItem>
        </TextField>
      </Grid>

      <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
        <CheckboxComponent
          label="Primeiro Emprego"
          value={data?.indPrimeiroEmprego === 'S'}
          onChange={(e, value) => {
            const indPrimeiroEmprego = value ? 'S' : 'N'
            setData({ ...data, indPrimeiroEmprego })
          }}
        />
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <TextField
          label="Regime Trabalhista"
          fullWidth
          value={data?.tipoRegimeTrabalhista || ''}
          size="small"
          onChange={(e) => {
            const tipoRegimeTrabalhista = e?.target?.value || ''
            setData({ ...data, tipoRegimeTrabalhista })
          }}
          select
        >
          <MenuItem value="1">CLT</MenuItem>
          <MenuItem value="2">Estatutário</MenuItem>
        </TextField>
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <MUIAutoComplete
          label="Indicador Grau Exposição"
          options={IndGrauExposicaoValues}
          optionId="value"
          renderOption={(option: FixLater) => option.name}
          required
          validationErrors={validationErrors}
          name="indGrauExposicao"
          value={data.indGrauExposicao}
          onChange={(e: FixLater, obj: FixLater) => {
            const indGrauExposicao = obj ? obj.value : ''
            setData({ ...data, indGrauExposicao })
          }}
        />
      </Grid>

      <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
        <MUIAutoComplete
          label="Indicador Múltiplos Vínculos"
          options={IndMultiploVinculoValues}
          renderOption={(option: FixLater) => option.name}
          optionId="value"
          required
          validationErrors={validationErrors}
          name="indMultiplosVinculos"
          value={data.indMultiplosVinculos}
          onChange={(e: FixLater, obj: FixLater) => {
            const indMultiplosVinculos = obj ? obj.value : ''
            setData({ ...data, indMultiplosVinculos })
          }}
        />
      </Grid>
      <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
        <TextField
          label="Regime Previdência"
          fullWidth
          value={data?.tipoRegimePrevidencia || ''}
          size="small"
          onChange={(e) => {
            const tipoRegimePrevidencia = e?.target?.value || ''
            setData({ ...data, tipoRegimePrevidencia })
          }}
          select
        >
          <MenuItem value="1">Regime Geral da Previdência Social - RGPS</MenuItem>
          <MenuItem value="2">Regime Próprio de Previdência Social - RPPS </MenuItem>
          <MenuItem value="3">Regime Próprio de Previdência Social no Exterior - RPPE</MenuItem>
        </TextField>
      </Grid>

      <Grid item xl={5} lg={5} md={5} sm={7} xs={12}>
        <MUIAutoComplete
          label="Natureza Atividade"
          options={NaturezaAtividadeValues}
          optionId="value"
          renderOption={(option: FixLater) => option.name}
          required
          validationErrors={validationErrors}
          name="naturezaAtividade"
          value={data.naturezaAtividade}
          onChange={(e: FixLater, obj: FixLater) => {
            const naturezaAtividade = obj ? obj.value : ''
            setData({ ...data, naturezaAtividade })
          }}
          disabled={hasAdmissaoPreliminar}
        />
      </Grid>

      <Grid item xl={2} lg={2} md={2} sm={5} xs={12}>
        <MUIAutoComplete
          label="Unidade Salário Fixo"
          options={IndUnidadeSalarioValues}
          optionId="value"
          renderOption={(option: FixLater) => option.name}
          required
          validationErrors={validationErrors}
          name="unidadeSalarioFixo"
          value={data.unidadeSalarioFixo}
          onChange={(e: FixLater, obj: FixLater) => {
            const unidadeSalarioFixo = obj ? obj.value : ''
            setData({ ...data, unidadeSalarioFixo })
          }}
          disabled={hasAdmissaoPreliminar}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          label="Descrição Salário Variável"
          fullWidth
          value={data?.descricaoSalarioVariavel || ''}
          size="small"
          inputProps={{
            maxLength: 100,
          }}
          onChange={(e) => {
            const descricaoSalarioVariavel = e?.target?.value || ''
            setData({ ...data, descricaoSalarioVariavel })
          }}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
        <AutoCompleteSindicato
          name="sindicatoId"
          label="Sindicato"
          required
          value={data?.sindicatoId || null}
          onChange={(e: FixLater, sindicatoObj: FixLater) => {
            const sindicato = sindicatoObj || null
            const sindicatoId = sindicato?.id || ''
            setData({
              ...data,
              sindicatoId,
              sindicato,
            })
          }}
          validationErrors={validationErrors}
          optionId="id"
        />
      </Grid>

      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
        <TextField
          label="Número Processo Trabalhista Admissão Judicial"
          fullWidth
          value={data?.nrProcessoTrabalhista || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 20,
          }}
          onChange={(e) => {
            const nrProcessoTrabalhista = e?.target?.value || ''
            setData((oldState) => ({
              ...oldState,
              nrProcessoTrabalhista,
            }))
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <ContentDivider title="Informações de Transferência" />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <DatePickerNew
          label="Data Transferência"
          size="small"
          value={data.dtTransferencia || null}
          onChange={(date) => {
            const dtTransferencia = date
            setData({ ...data, dtTransferencia })
          }}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <NumeroInscricaoTextField
          label="CNPJ do Empregador Anterior"
          typeMask={tipoInscricaoConsts.CNPJ_1}
          value={data?.cnpjEmpregoAnterior || ''}
          onChange={(e, value) => {
            const cnpjEmpregoAnterior = value || ''
            setData({ ...data, cnpjEmpregoAnterior })
          }}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <NumeroInscricaoTextField
          label="CNPJ Sucessora"
          typeMask={tipoInscricaoConsts.CNPJ_1}
          value={data?.cnpjSucessora || ''}
          onChange={(e, value) => {
            const cnpjSucessora = value
            setData({ ...data, cnpjSucessora })
          }}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <TextField
          label="Matrícula Emprego Anterior"
          fullWidth
          value={data.matriculaEmpregoAnterior || ''}
          size="small"
          inputProps={{
            maxLength: 30,
          }}
          onChange={(e) => {
            const matriculaEmpregoAnterior = e?.target?.value || ''
            setData({ ...data, matriculaEmpregoAnterior })
          }}
        />
      </Grid>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Grid>
  )
}
