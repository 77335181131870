import { formatToCPF } from 'brazilian-values'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { ContainerTable, CopyLabel, ToolsDataTable } from '~/components'
import { AlteracaoSalarialColetivaVinculoDTO } from '~/hooks/queries/AlteracaoSalarialColetiva/dtos/AlteracaoSalarialColetivaVinculoDTO'
import { formatCurrency, formatSimpleDate } from '~/utils/utils'

interface TableProps {
  data: AlteracaoSalarialColetivaVinculoDTO[]
  isLoading: boolean
  isFetching: boolean
  query: string
  rowsSelected: number[]
  setRowsSelected: (rows: number[]) => void
}

export default function Table({
  data,
  isLoading,
  isFetching,
  query,
  rowsSelected,
  setRowsSelected,
}: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nrInscricao',
      label: 'CPF',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel>,
      },
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'cargo',
      label: 'Cargo',
    },
    {
      name: 'dtAdmissao',
      label: 'Admissão',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'salarioAnterior',
      label: 'Salário Anterior',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'salarioAtual',
      label: 'Salário Atual',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
  ]

  return (
    <ContainerTable>
      <ToolsDataTable
        columns={columns}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        sherlock={{
          query,
          columns: ['nrInscricao', 'nome', 'cargo'],
        }}
        disableAutoHeight
        optionsSelectable={{
          onRowSelected: setRowsSelected,
          rowsSelected,
          type: 'multiple',
        }}
      />
    </ContainerTable>
  )
}
