import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useClientObterModelos } from './useObterModelos'

import { notifyPut } from '~/utils/notification'

import { ModeloRelatorioRequestDTO } from './dtos/ModeloRelatorioRequestDTO'

interface RequestProps {
  data: ModeloRelatorioRequestDTO
  params: {
    id: string
  }
}

export function useAtualizar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useClientObterModelos()

  async function handleRequest({ data, params }: RequestProps) {
    await api.put('/ModeloRelatorio/Atualizar', data, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
