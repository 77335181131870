import { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, CurrencyTextField } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useCriar } from '~/hooks/queries/ProcessoTrabalhistaTributosIRRF/useCriar'

import { ProcessoTrabalhistaTributosIRRF } from '~/hooks/queries/ProcessoTrabalhistaTributosIRRF/dtos/ProcessoTrabalhistaTributosIRRF'
import { CodigoReceitaIRRFProcessoTrabalhistaValues } from '~/@types/enums/CodigoReceitaIRRFProcessoTrabalhistaEnum'

const schema = yup.object().shape({
  codigoReceita: yup.string().required('Informe o Código de Recolhimento'),
  vrCodigoReceita: yup
    .number()
    .required('Informe o Valor da Receita')
    .min(0.01, 'Informe o Valor da Receita'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  processoTrabalhistaTributosId: string
  processoTrabalhistaVinculoId: string
}

export default function Form({
  isOpen,
  onClose,
  processoTrabalhistaTributosId,
  processoTrabalhistaVinculoId,
}: FormProps) {
  const [data, setData] = useState<ProcessoTrabalhistaTributosIRRF>({
    processoTrabalhistaTributosId,
    processoTrabalhistaVinculoId,
  } as ProcessoTrabalhistaTributosIRRF)

  const { mutateAsync: mutateAsyncCriar, isLoading: isLoading } = useCriar()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    await mutateAsyncCriar(data)
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Processo Trabalhista Tributos IRRF"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onCancelClick={onClose}
      onOkClick={handleValidate}
      isOkProcessing={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUIAutoComplete
            label="Código de Recolhimento"
            required
            validationErrors={validationErrors}
            name="codigoReceita"
            options={CodigoReceitaIRRFProcessoTrabalhistaValues}
            renderOption={(option) => option.name}
            optionId="value"
            value={data.codigoReceita}
            onChange={(e, obj) => {
              const codigoReceita = obj?.value || ''
              setData((prev) => ({ ...prev, codigoReceita }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Valor da Receita"
            value={data.vrCodigoReceita || ''}
            required
            validationErrors={validationErrors}
            name="vrCodigoReceita"
            onChange={(_, value) => {
              const vrCodigoReceita = value
              setData((oldState) => ({
                ...oldState,
                vrCodigoReceita,
              }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrencyTextField
            label="Valor da Receita"
            value={data.vrCodigoReceita13 || ''}
            onChange={(_, value) => {
              const vrCodigoReceita13 = value
              setData((oldState) => ({
                ...oldState,
                vrCodigoReceita13,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
