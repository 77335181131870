import { makeStyles } from '@material-ui/core'
import { Search } from '@material-ui/icons'

import { formatCurrency } from '~/hooks/useUtils'
import { Stack } from '~/components'

const useStyles = makeStyles((theme) => ({
  highLight: {
    padding: theme.spacing(0.5),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

interface AlignCellProps {
  value: string
  onClick?: () => void
}

export default function AlignCellValue(props: AlignCellProps) {
  const { value, onClick } = props

  const isHighLight = Boolean(value) && Boolean(onClick)

  const classes = useStyles()

  return (
    <Stack
      width="100%"
      orientation="horizontal"
      justifyContent="flex-end"
      alignItems="center"
      className={isHighLight ? classes.highLight : ''}
      onClick={onClick}
      gap={0.5}
    >
      {formatCurrency(value)}
      {isHighLight && <Search fontSize="small" />}
    </Stack>
  )
}
