import { formatToCPFOrCNPJ } from 'brazilian-values'
import { formatSimpleDate } from '~/utils/utils'

import { CopyLabel, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'

interface TableProps {
  query: string
  data: VinculoConsulta[]
  isLoading: boolean
  isFetching: boolean
  onRowSelected: (rowsSelected: number[]) => void
  rowsSelected: number[]
}

export default function Table({
  query,
  data,
  isLoading,
  isFetching,
  onRowSelected,
  rowsSelected,
}: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Código',
      name: 'pessoaCodigo',
    },
    {
      label: 'CPF',
      name: 'pessoaCPF',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      label: 'Nome',
      name: 'pessoaNome',
    },
    {
      label: 'Admissão',
      name: 'dtAdmissao',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      sherlock={{ columns: ['pessoaCodigo', 'pessoaCPF', 'pessoaNome'], query }}
      disableAutoHeight
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        type: 'multiple',
      }}
    />
  )
}
