import './configs/ReactotronConfig'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ToastContainer } from 'react-toastify'
import { Provider as AlertProvider } from 'react-alert'
import SyncFusionProvider from './providers/SyncFusionProvider'

import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { AlertDialog } from '~/components'

import Routes from './routes'
import theme from './styles/theme'
import { store, persistor } from './store'
import './index.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <AlertProvider template={AlertDialog}>
            <QueryClientProvider client={queryClient}>
              <SyncFusionProvider>
                <Routes />
                <ReactQueryDevtools initialIsOpen={false} />
              </SyncFusionProvider>
            </QueryClientProvider>
          </AlertProvider>
        </ThemeProvider>
      </PersistGate>
      <ToastContainer position="bottom-right" autoClose={4000} />
    </Provider>
  )
}

export default App
