import { useState } from 'react'

import { Button, ConfirmDeleteDialog, ContainerTable, Finder, Stack } from '~/components'

import DialogRelatorio from './DialogRelatorio'
import Form from './Form'
import Table, { DataActions } from './Table'

import useDialog from '~/hooks/useDialog'

import { ModeloRelatorioSimplesDTO } from '~/hooks/queries/ModeloRelatorio/dtos/ModeloRelatorioSimplesDTO'
import { useRemover } from '~/hooks/queries/ModeloRelatorio/useRemover'

export default function TabRelatorios() {
  const [query, setQuery] = useState('')

  const { mutateAsync, isLoading: isRemoving } = useRemover()

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<ModeloRelatorioSimplesDTO | null>(null)

  const {
    close: closeDialogRelatorio,
    data: dataDialogRelatorio,
    isOpen: isOpenDialogRelatorio,
    open: openDialogRelatorio,
  } = useDialog('')

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  function handleClickItem(event: DataActions, data: ModeloRelatorioSimplesDTO) {
    switch (event) {
      case 'open':
        openDialogRelatorio(data.id)
        break
      case 'delete':
        openConfirmDelete(data.id)
        break
      case 'edit':
        openForm(data)
        break
    }
  }

  async function handleConfirmDelete() {
    await mutateAsync({
      params: {
        id: dataConfirmDelete,
      },
    })
    closeConfirmDelete()
  }

  return (
    <Stack height="100%">
      <Stack orientation="horizontal" alignItems="center" justifyContent="flex-end">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />

        <Button variant="contained" onClick={() => openForm(null)}>
          Adicionar
        </Button>
      </Stack>

      <ContainerTable>
        <Table query={query} onItemClick={handleClickItem} />
      </ContainerTable>

      {isOpenForm && (
        <Form
          isOpen={isOpenForm}
          onClose={closeForm}
          onAfterSubmit={openDialogRelatorio}
          data={dataForm}
        />
      )}

      {isOpenDialogRelatorio && (
        <DialogRelatorio
          isOpen={isOpenDialogRelatorio}
          idRelatorio={dataDialogRelatorio}
          onClose={closeDialogRelatorio}
        />
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isRemoving}
          onCancel={closeConfirmDelete}
          onConfirm={handleConfirmDelete}
        />
      )}
    </Stack>
  )
}
