import { convertToArrayDescription } from '~/utils/utils'

export enum IndRepercussaoProcessoEnum {
  DecisaoComRepercussaoTributaria_01 = 1,
  DecisaoSemRepercussaoTributaria_02 = 2,
  DecisaoComRepercussaoExclusivaDeclaracaoRendimentosIRRF_03 = 3,
  DecisaoRepercussaoExclusivaDeclaracaoRendimentos_4 = 4,
  DecisaoRepercussaoTributariaFGTSPagamento_5 = 5,
}

export const IndRepercussaoProcessoLabels = {
  [IndRepercussaoProcessoEnum.DecisaoComRepercussaoTributaria_01]:
    '1 - Decisão com repercussão tributária e/ou FGTS com rendimentos informados em S-2501',
  [IndRepercussaoProcessoEnum.DecisaoSemRepercussaoTributaria_02]:
    '2 - Decisão sem repercussão tributária ou FGTS',
  [IndRepercussaoProcessoEnum.DecisaoComRepercussaoExclusivaDeclaracaoRendimentosIRRF_03]:
    '3 - Decisão com repercussão exclusiva para declaração de rendimentos para fins de Imposto de Renda',
  [IndRepercussaoProcessoEnum.DecisaoRepercussaoExclusivaDeclaracaoRendimentos_4]:
    '4 - Decisão com repercussão exclusiva para declaração de rendimentos para fins de Imposto de Renda com pagamento através de depósito judicial',
  [IndRepercussaoProcessoEnum.DecisaoRepercussaoTributariaFGTSPagamento_5]:
    '5 - Decisão com repercussão tributária e/ou FGTS com pagamento através de depósito judicial',
}

export const IndRepercussaoProcessoValues = convertToArrayDescription(
  IndRepercussaoProcessoEnum,
  IndRepercussaoProcessoLabels,
)
