import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { DetalhamentoContribuicaoSocialParametrosDTO } from './dtos/DetalhamentoContribuicaoSocial/DetalhamentoContribuicaoSocialParametrosDTO'
import { DetalhamentoContribuicaoSocialRespostaDTO } from './dtos/DetalhamentoContribuicaoSocial/DetalhamentoContribuicaoSocialRespostaDTO'

const query = '/ApurarEncargos/ObterDetalhamentoContribuicaoSocial'

interface RequestProps {
  data: DetalhamentoContribuicaoSocialParametrosDTO
}

export default function useObterDetalhamentoContribuicaoSocial({ data }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: DetalhamentoContribuicaoSocialRespostaDTO }>(
      query,
      data,
    )
    return response.data.data
  }

  return useQuery([query, data], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
