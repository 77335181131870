import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { PisoEnfermagemFuncionarioDTO } from './dtos/PisoEnfermagemFuncionarioDTO'

interface RequestProps {
  data: PisoEnfermagemFuncionarioDTO
  params: {
    vrPiso: number
    vrMultiplicador: number
    vrFator: number
  }
}

export function useCalcularFuncionario() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.post<{ data: PisoEnfermagemFuncionarioDTO }>(
      '/PisoEnfermagem/CalcularFuncionario',
      data,
      { params },
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
