import { useEffect, useState, memo } from 'react'

import { Box, Paper } from '@material-ui/core'
import { Button, ContainerTable, Finder, Stack, TextField } from '~/components'
import { AutoCompleteModeloRelatorio } from '~/components/AutoComplete'

import Table from './Table'

import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'

import { useVinculoConsultaGetByEstabelecimento } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetByEstabelecimento'
import { useProcessar } from '~/hooks/queries/ModeloRelatorio/useProcessar'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const MemoTable = memo(Table)

export default function TabProcessar() {
  const [rowsSelected, setRowsSelected] = useState<number[]>([])
  const [dataForm, setDataForm] = useState({
    modeloId: '',
    tagVariavel: '',
  })
  const [query, setQuery] = useState('')

  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useVinculoConsultaGetByEstabelecimento({
    vinculoTipo: [VinculoTipoEnum.Funcionario_1],
    estabelecimentoId: estabelecimento.id,
    anoMes,
  })
  const data = _d || []

  useEffect(() => {
    setRowsSelected([])
  }, [_d])

  const { mutateAsync, isLoading: isProcessing } = useProcessar()

  async function handleProcess() {
    if (!dataForm.modeloId) {
      dialogNotification.warning({
        descriptions: ['Informe o Modelo de Relatório para prosseguir.'],
      })
      return
    }
    const funcionariosIds = rowsSelected.map((index) => data[index].id)
    await mutateAsync({
      data: funcionariosIds,
      params: {
        id: dataForm.modeloId,
        tagVariavel: dataForm.tagVariavel,
      },
    })
    setRowsSelected([])
  }

  return (
    <Stack height="100%">
      <Stack
        orientation="horizontal"
        alignItems="center"
        justifyContent="space-between"
        component={Paper}
        p={1}
      >
        <Stack flex={1} orientation="horizontal">
          <Box width="100%" maxWidth={500}>
            <AutoCompleteModeloRelatorio
              value={dataForm.modeloId}
              onChange={(id) => {
                const modeloId = id
                setDataForm((prev) => ({ ...prev, modeloId }))
              }}
            />
          </Box>
          <Box width="100%" maxWidth={500}>
            <TextField
              label="Tag [VARIAVEL]"
              value={dataForm.tagVariavel}
              onChange={(e) => {
                const tagVariavel = e.target.value
                setDataForm((prev) => ({ ...prev, tagVariavel }))
              }}
            />
          </Box>
        </Stack>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </Stack>

      <ContainerTable>
        <MemoTable
          query={query}
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          rowsSelected={rowsSelected}
          onRowSelected={setRowsSelected}
        />
      </ContainerTable>

      <Stack
        component={Paper}
        p={1}
        orientation="horizontal"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          variant="contained"
          onClick={handleProcess}
          disabled={rowsSelected.length > 0 ? false : true}
          isLoading={isProcessing}
        >
          Processar
        </Button>
      </Stack>
    </Stack>
  )
}
