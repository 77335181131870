import { useState } from 'react'

import { Grid } from '@material-ui/core'

import { ActionDialog, TextField } from '~/components'

import { useCriar } from '~/hooks/queries/ModeloRelatorio/useCriar'
import { useAtualizar } from '~/hooks/queries/ModeloRelatorio/useAtualizar'

import { ModeloRelatorioSimplesDTO } from '~/hooks/queries/ModeloRelatorio/dtos/ModeloRelatorioSimplesDTO'

interface FormProps {
  isOpen: boolean
  onClose: () => void
  onAfterSubmit: (id: string) => void
  data: ModeloRelatorioSimplesDTO | null
}

export default function Form({ isOpen, onClose, onAfterSubmit, data: _data }: FormProps) {
  const [data, setData] = useState(_data || ({} as ModeloRelatorioSimplesDTO))

  const { mutateAsync: mutateAsyncCriar, isLoading: isLoadingCriar } = useCriar()
  const { mutateAsync: mutateAsyncAtualizar, isLoading: isLoadingAtualizar } = useAtualizar()

  const isLoading = isLoadingCriar || isLoadingAtualizar

  async function handleSubmit() {
    if (_data) {
      await mutateAsyncAtualizar({
        data,
        params: {
          id: _data.id,
        },
      })
    } else {
      const id = await mutateAsyncCriar({
        data,
      })
      onAfterSubmit(id)
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Criar Relatório"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onCancelClick={onClose}
      isOkProcessing={isLoading}
      onOkClick={handleSubmit}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data.nome || ''}
            size="small"
            inputProps={{
              maxLength: 255,
            }}
            onChange={(e) => {
              const nome = e.target.value
              setData((prev) => ({ ...prev, nome }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Descrição"
            fullWidth
            value={data.descricao || ''}
            size="small"
            inputProps={{
              maxLength: 500,
            }}
            rows={4}
            multiline
            onChange={(e) => {
              const descricao = e.target.value
              setData((prev) => ({ ...prev, descricao }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
