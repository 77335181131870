import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { ButtonBox, ToolsDataTable } from '~/components'

import {
  IndHorarioTrabalhoIntervaloEnum,
  IndHorarioTrabalhoIntervaloLabels,
} from '~/@types/enums/IndHorarioTrabalhoIntervaloEnum'
import {
  TipoHorarioIntervaloEnum,
  TipoHorarioIntervaloLabels,
} from '~/@types/enums/TipoHorarioIntervaloEnum'
import { HorarioTrabalhoIntervaloForm } from '~/hooks/queries/HorarioTrabalhoIntervalo/HorarioTrabalhoIntervalo'
import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableProps {
  data: HorarioTrabalhoIntervaloForm[]
  onItemClick: (event: 'edit' | 'delete', value: string) => void
  isLoading: boolean
}

const Table = ({ data, onItemClick, isLoading }: TableProps) => {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'tipoIntervalo',
      label: 'Tipo Intervalo',
      options: {
        customBodyRender: (value: TipoHorarioIntervaloEnum) =>
          TipoHorarioIntervaloLabels[value] || '',
      },
    },
    {
      name: 'inicioIntervalo',
      label: 'Início Intervalo',
      options: {
        customBodyRender: (value) => (value ? `${value.substr(0, 2)}:${value.substr(2, 2)}` : 0),
      },
    },
    {
      name: 'fimIntervalo',
      label: 'Fim Intervalo',
      options: {
        customBodyRender: (value) => (value ? `${value.substr(0, 2)}:${value.substr(2, 2)}` : 0),
      },
    },
    {
      name: 'duracaoIntervalo',
      label: 'Duração Intervalo min(s)',
    },
    {
      name: 'indIntervalo',
      label: 'Indicador',
      options: {
        customBodyRender: (value: IndHorarioTrabalhoIntervaloEnum) =>
          IndHorarioTrabalhoIntervaloLabels[value] || '',
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      pagination={false}
      data={data}
      columns={columns}
      isLoading={isLoading}
    />
  )
}

export default Table
