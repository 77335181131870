import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Paper, Grid, Box } from '@material-ui/core'

import {
  PageHeader,
  PDFViewer,
  PrintButton,
  Finder,
  ContainerTable,
  AnoMesTextField,
  StackContainer,
  Stack,
  Checkbox,
} from '~/components'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

import Table from './components/Table'

import { useEstabelecimentoObterFiltrado } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoObterFiltrado'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useLocalStorage from '~/hooks/useLocalStorage'

import constants from '~/utils/constants'

import { IndAtividadeEstabelecimentoEnum } from '~/@types/enums/IndAtividadeEstabelecimentoEnum'
import { RelCentroCustoRequestDTO } from '~/hooks/queries/RelCentroCusto/dtos/RelCentroCustoRequestDTO'

const schema = yup.object().shape({
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início'),
  anoMesFim: yup.string().required('Informe o Ano/Mês Fim'),
})

export default function RelatorioCentroCusto() {
  const [grupoId, setGrupoId] = useLocalStorage(constants.grupoId, '')

  const [rowsSelecteds, setRowsSelecteds] = useState<number[]>([])
  const [query, setQuery] = useState('')
  const [dataForm, setDataForm] = useState({
    anoMesInicio: '',
    anoMesFim: '',
    isRelatorioSintetico: false,
    isAgruparAnoMes: true,
    isAgruparDepartamento: true,
    isAgruparSetor: true,
  })

  const { anoMes } = useAmbiente()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useEstabelecimentoObterFiltrado({
    indAtividadeEstabelecimentoEnum: IndAtividadeEstabelecimentoEnum.ApenasAtivos,
    anoMes,
    grupoId,
  })
  const data = _d || []

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<RelCentroCustoRequestDTO>({} as RelCentroCustoRequestDTO)

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data: dataForm,
    handleSubmit: handlePrint,
  })

  useEffect(() => {
    setDataForm((prev) => ({
      ...prev,
      anoMesInicio: anoMes,
      anoMesFim: anoMes,
    }))
  }, [anoMes])

  useEffect(() => {
    setRowsSelecteds([])
  }, [_d])

  function handlePrint() {
    const estabelecimentosIds = rowsSelecteds.map((rowIndex) => data[rowIndex].id)
    openPDFViewer({
      estabelecimentosIds,
      anoMesInicio: dataForm.anoMesInicio,
      anoMesFim: dataForm.anoMesFim,
      isRelatorioSintetico: dataForm.isRelatorioSintetico,
      isAgruparAnoMes: dataForm.isAgruparAnoMes,
      isAgruparDepartamento: dataForm.isAgruparDepartamento,
      isAgruparSetor: dataForm.isAgruparSetor,
    })
    setRowsSelecteds([])
  }

  return (
    <StackContainer>
      <PageHeader title="Relatório de Centro de Custo">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Stack p={2} component={Paper}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <AnoMesTextField
                  label="Ano/Mês Início"
                  value={dataForm.anoMesInicio}
                  onChange={(e) =>
                    setDataForm((prev) => ({
                      ...prev,
                      anoMesInicio: e.target.value.replace('/', ''),
                    }))
                  }
                  required
                  validationErrors={validationErrors}
                  name="anoMesInicio"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <AnoMesTextField
                  label="Ano/Mês Fim"
                  value={dataForm.anoMesFim}
                  onChange={(e) =>
                    setDataForm((prev) => ({
                      ...prev,
                      anoMesFim: e.target.value.replace('/', ''),
                    }))
                  }
                  required
                  validationErrors={validationErrors}
                  name="anoMesFim"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Checkbox
                  label="Agrupar por Competência"
                  value={dataForm.isAgruparAnoMes}
                  onChange={(e, value) => {
                    const isAgruparAnoMes = value
                    setDataForm((prev) => ({ ...prev, isAgruparAnoMes }))
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Checkbox
                  label="Agrupar por Departamento"
                  value={dataForm.isAgruparDepartamento}
                  onChange={(e, value) => {
                    const isAgruparDepartamento = value
                    setDataForm((prev) => ({ ...prev, isAgruparDepartamento }))
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Checkbox
                  label="Agrupar por Setor"
                  value={dataForm.isAgruparSetor}
                  onChange={(e, value) => {
                    const isAgruparSetor = value
                    setDataForm((prev) => ({ ...prev, isAgruparSetor }))
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Stack orientation="horizontal">
              <Box minWidth={210}>
                <Checkbox
                  label="Imprimir Relatório Sintético"
                  value={dataForm.isRelatorioSintetico}
                  onChange={(e, value) => {
                    const isRelatorioSintetico = value
                    setDataForm((prev) => ({ ...prev, isRelatorioSintetico }))
                  }}
                />
              </Box>
              <AutoCompleteGrupo
                value={grupoId}
                onChange={(e, grupo) => {
                  const grupoId = grupo?.id || null
                  setGrupoId(grupoId)
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      <ContainerTable>
        <Table
          data={data}
          query={query}
          isLoading={isLoading}
          isFetching={isFetching}
          rowsSelected={rowsSelecteds}
          onRowsSelected={setRowsSelecteds}
        />
      </ContainerTable>

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          reportKey="CentroCusto"
          title="Relatório de Centro de Custo"
          axiosConfig={{
            method: 'post',
            url: '/RelCentroCusto/ObterRelatorio',
            data: dataPDFViewer,
          }}
        />
      )}

      <Stack alignItems="flex-end">
        <PrintButton disabled={rowsSelecteds.length > 0 ? false : true} onClick={handleValidate} />
      </Stack>
    </StackContainer>
  )
}
