import { useEffect, useState } from 'react'

import { Button, ConfirmDeleteDialog, Stack, StackContainer } from '~/components'

import Form from './components/Form'
import Table from './components/Table'
import DialogIntervalos from './components/DialogIntervalos'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import api from '~/services/api-pessoal'
import useDialog from '~/hooks/useDialog'

import { VinculoHorarioItem } from '~/hooks/queries/VinculoHorarioItem/VinculoHorarioItem'
import { Divider } from '@material-ui/core'
import { Vinculo } from '~/hooks/queries/Vinculo/Vinculo'

interface VinculoHorarioItemProps {
  vinculo: Vinculo
  vinculoHorarioId: string
  isFinalizado: boolean
}

export default function VinculoHorarioItemPage({
  vinculo,
  vinculoHorarioId,
  isFinalizado,
}: VinculoHorarioItemProps) {
  const [collectionItem, setCollectionItem] = useState<{
    isLoading: boolean
    itens: VinculoHorarioItem[]
  }>({
    isLoading: false,
    itens: [],
  })

  const {
    isOpen: isOpenForm,
    close: closeForm,
    data: dataForm,
    open: openForm,
  } = useDialog<VinculoHorarioItem | null>(null)

  const {
    isOpen: isOpenDialogIntervalos,
    close: closeDialogIntervalos,
    data: dataDialogIntervalos,
    open: openDialogIntervalos,
  } = useDialog('')

  const {
    isOpen: isOpenConfirmDelete,
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')
  const [isDeleting, setDeleting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    const getCollection = async () => {
      setCollectionItem((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get(
          `/VinculoHorarioItem/GetByVinculoHorario?vinculoHorarioId=${vinculoHorarioId}`,
        )
        if (response.data.data) {
          setCollectionItem((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollectionItem((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vinculoHorarioId])

  const handleClickItem = (event: 'edit' | 'delete' | 'intervalos', id: string) => {
    const handleClickEditItem = () => {
      const { itens } = collectionItem
      const item = itens.find((i) => i.id === id)
      if (item) openForm(item)
    }

    const handleClickDeleteItem = () => {
      openConfirmDelete(id)
    }

    function handleClickIntervalos() {
      openDialogIntervalos(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
      intervalos: handleClickIntervalos,
    }
    functions[event]()
  }

  const handleDeleteItem = async () => {
    const itens = collectionItem.itens
    setDeleting(true)
    try {
      await api.delete(`/VinculoHorarioItem/${dataConfirmDelete}`)
      const newItens = itens.filter((i) => i.id !== dataConfirmDelete)
      closeConfirmDelete()
      setCollectionItem((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setDeleting(false)
    }
  }

  const handleAfterSubmitFormItem = (
    event: 'update' | 'insert',
    data: VinculoHorarioItem | VinculoHorarioItem[],
  ) => {
    const handleAfterInsert = () => {
      if (!Array.isArray(data)) return
      const { itens } = collectionItem
      const newItens = [...data, ...itens]
      setCollectionItem((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      closeForm()
    }

    const handleAfterUpdate = () => {
      if (Array.isArray(data)) return
      const { itens } = collectionItem
      const newItens = itens.map((i) => (i.id === data.id ? data : i))
      setCollectionItem((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      closeForm()
    }

    const functions = {
      insert: handleAfterInsert,
      update: handleAfterUpdate,
    }

    functions[event]()
  }

  return (
    <Stack>
      {isFinalizado ? (
        <> </>
      ) : (
        <>
          <Divider />
          <Stack justifyContent="flex-end" alignItems="flex-end">
            <Button
              variant="contained"
              onClick={() =>
                openForm({
                  vinculoHorarioId,
                } as VinculoHorarioItem)
              }
            >
              Adicionar
            </Button>
          </Stack>
        </>
      )}

      <Divider />

      <StackContainer p={0}>
        <Table
          data={collectionItem.itens}
          isLoading={collectionItem.isLoading}
          onItemClick={handleClickItem}
          isFinalizado={isFinalizado}
        />
      </StackContainer>

      {isOpenForm && (
        <Form
          vinculo={vinculo}
          isOpen={isOpenForm}
          data={dataForm}
          diasOld={collectionItem?.itens}
          onClose={closeForm}
          onAfterSubmitFormItem={handleAfterSubmitFormItem}
        />
      )}

      {isOpenDialogIntervalos && (
        <DialogIntervalos
          isOpen={isOpenDialogIntervalos}
          onClose={closeDialogIntervalos}
          horarioId={dataDialogIntervalos}
        />
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isDeleting}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
        />
      )}
    </Stack>
  )
}
