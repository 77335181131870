import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ESocialGrupoEventoConsultaEnum } from '~/@types/enums/ESocialGrupoEventoConsultaEnum'
import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'
import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'

import { ESocialEmpregadorTransmitirRespostaDTO } from './useObterEventosTransmissaoEnvio'

export const queryUseObterEventosTransmissaoAcompanhamento =
  '/ESocial/ObterEventosTransmissaoAcompanhamento'

interface useObterEventosTransmissaoAcompanhamentoProps {
  params: {
    grupoEvento: ESocialGrupoEventoConsultaEnum
    anoMes: string
    grupoId: string | null
    indApuracao: IndApuracaoEnum
    page: number
    pageSize: number
    empregadorNome: string
  }
  data: ESocialEventoEnum[]
}

export function useObterEventosTransmissaoAcompanhamento({
  params,
  data,
}: useObterEventosTransmissaoAcompanhamentoProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: ESocialEmpregadorTransmitirRespostaDTO }>(
      queryUseObterEventosTransmissaoAcompanhamento,
      data,
      {
        params,
      },
    )
    return response?.data?.data
  }

  return useQuery([queryUseObterEventosTransmissaoAcompanhamento, data, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
