import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { FinalizarReciboEstabelecimentoBodytDTO } from './dtos/FinalizarReciboEstabelecimentoBodytDTO'
import { notifySuccess } from '~/utils/notification'

interface RequestParams {
  data: FinalizarReciboEstabelecimentoBodytDTO
  params: { anoMes: string; isLiberarRecibosESocial: boolean }
}

export function useLiberarPorEstabelecimento() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestParams) {
    await api.put('/RP/LiberarPorEstabelecimento', data, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => notifySuccess('Recibo liberados com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
