import { ReactNode } from 'react'

import { registerLicense } from '@syncfusion/ej2-base'

import { setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { L10n } from '@syncfusion/ej2-base'
import * as pt from '@syncfusion/ej2-locale/src/pt.json'

registerLicense(import.meta.env.VITE_LICENSE_KEY_SYNCFUSION)

setCulture('pt')
setCurrencyCode('BRL')
L10n.load(pt)

interface SyncFusionProviderProps {
  children: ReactNode
}

export default function SyncFusionProvider({ children }: SyncFusionProviderProps) {
  return <>{children}</>
}
