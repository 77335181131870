import React, { useState } from 'react'

import { Box, Grid, Paper } from '@material-ui/core'
import { Button } from 'mio-library-ui'

import { MultipleSelect } from '~/components'

import {
  AutoCompleteBanco,
  AutoCompleteBancoAgencia,
  AutoCompleteLotacao,
  MUIAutoComplete,
} from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'

import {
  tipoProcessamentoRelacaoBancariaValues,
  tipoProcessamentoRelacaoBancariaConsts,
} from '~/values/tipoProcessamentoRelacaoBancariaValues'
import { ReciboIDFEnum, ReciboIDFValues } from '~/@types/enums/ReciboIDFEnum'

const recibosIdfsRelacaoBancaria = [
  ReciboIDFEnum.Mensal_1,
  ReciboIDFEnum.Ferias_2,
  ReciboIDFEnum.Complementar_3,
  ReciboIDFEnum.Adiantamento_4,
  ReciboIDFEnum.Rescisao_5,
  ReciboIDFEnum.DecimoTerceiro1aParcela_7,
  ReciboIDFEnum.DecimoTerceiro2aParcela_8,
  ReciboIDFEnum.Ferias_Coletivas_21,
]

const recibosIdfs = ReciboIDFValues.filter((idr) => recibosIdfsRelacaoBancaria.includes(idr.value))

const identificaoReciboInitial = ReciboIDFValues.find(
  (item) => item.value === ReciboIDFEnum.Mensal_1,
)

const initialDataForm = {
  identificacaoRecibo: [identificaoReciboInitial],
  indProcessamento: tipoProcessamentoRelacaoBancariaConsts.TodosVinculos_3,
  lotacaoId: null,
  bancoId: null,
  agenciaId: null,
}

export default function Form(props) {
  const { onProcess, isLoading } = props

  const [data, setData] = useState(initialDataForm)

  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()

  function handleProcess() {
    if (!(data?.identificacaoRecibo.length > 0))
      return dialogNotification.error('Informe pelo menos uma identificação do recibo.')

    const identificacaoRecibo = data.identificacaoRecibo.map((idf) => idf.value)

    onProcess({
      estabelecimentoId: estabelecimento?.id,
      anoMes,
      identificacaoRecibo,
      indProcessamento: data.indProcessamento,
      lotacaoId: data?.lotacaoId,
      bancoId: data?.bancoId,
      agenciaId: data?.agenciaId,
    })
  }

  return (
    <Box component={Paper} padding={2}>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12} xs={12}>
          <MultipleSelect
            inputProps={{
              label: 'Identificação dos Recibos',
            }}
            value={data.identificacaoRecibo}
            options={recibosIdfs}
            getOptionLabel={(option) => option.name}
            onChange={(e, options) =>
              setData((prev) => ({
                ...prev,
                identificacaoRecibo: options,
              }))
            }
            limitTags={3}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <MUIAutoComplete
            label="Tipo de Processamento"
            options={tipoProcessamentoRelacaoBancariaValues}
            renderOption={(option) => option.name}
            value={data.indProcessamento}
            onChange={(event, object) =>
              setData((prev) => ({
                ...prev,
                indProcessamento:
                  object?.value || tipoProcessamentoRelacaoBancariaConsts.TodosVinculos_3,
              }))
            }
            optionId="value"
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <AutoCompleteLotacao
            value={data?.lotacaoId || null}
            onChange={(_, object) =>
              setData((prev) => ({ ...prev, lotacaoId: object?.id || null }))
            }
            empregadorId={estabelecimento?.empregadorId}
            optionId="id"
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <AutoCompleteBanco
            value={data?.bancoId || null}
            onChange={(_, object) => setData((prev) => ({ ...prev, bancoId: object?.id || null }))}
            optionId="id"
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <AutoCompleteBancoAgencia
            value={data?.agenciaId || null}
            onChange={(_, object) =>
              setData((prev) => ({ ...prev, agenciaId: object?.id || null }))
            }
            optionId="id"
          />
        </Grid>
        <Grid item xs={12}>
          <Box width="100%" display="flex" justifyContent="flex-end" gridGap={8}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => setData(initialDataForm)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleProcess}
              isLoading={isLoading}
            >
              Buscar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
