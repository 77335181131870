export enum CodigoIncidenciaIREnum {
  NaoInformado = 0,
  /**
   * Verba transitada pela folha de pagamento de natureza diversa de rendimento
   * ou retenção/isenção/dedução de IR (exemplo: desconto de convênio farmácia,
   * desconto de consignações, etc.)
   */
  IRRF_isento_9 = 9,

  /**11 Remuneração mensal */
  BaseCalculoIR_RemuneracaoMensal_11 = 11,
  /**12 13º salário */
  BaseCalculoIR_13Salario_12 = 12,
  /**13 Férias */
  BaseCalculoIR_Ferias_13 = 13,
  /**14 PLR */
  BaseCalculoIR_PLR_14 = 14,

  /**31 Remuneração mensal */
  RetencaoIRRFSobre_RemuneracaoMensal_31 = 31,
  /**32 13º salário */
  RetencaoIRRFSobre_13Salario_32 = 32,
  /**33 Férias */
  RetencaoIRRFSobre_Ferias_33 = 33,
  /**34 PLR */
  RetencaoIRRFSobre_PLR_34 = 34,

  /**41 Previdência Social Oficial - PSO - Remuneração mensal */
  DeducaoRendimentoTributavelIRRF_PrevidenciaSocialOficialPSO_41 = 41,
  /**42 PSO - 13º salário */
  DeducaoRendimentoTributavelIRRF_PSO13Salario_42 = 42,
  /**43 PSO - Férias */
  DeducaoRendimentoTributavelIRRF_PSOFerias_43 = 43,
  /**46 Previdência privada - Salário mensal */
  DeducaoRendimentoTributavelIRRF_PrevidenciaPrivadaSalarioMensal_46 = 46,
  /**47 Previdência privada - 13º salário */
  DeducaoRendimentoTributavelIRRF_PrevidenciaPrivada13Salario_47 = 47,
  /**48 Previdência privada - Férias */
  DeducaoRendimentoTributavelIRRF_PrevidenciaPrivadaFerias_48 = 48,
  /**51 Pensão alimentícia - Remuneração mensal */
  DeducaoRendimentoTributavelIRRF_PensaoAlimenticiaRemuneracaoMensal_51 = 51,
  /**52 Pensão alimentícia - 13º salário */
  DeducaoRendimentoTributavelIRRF_PensaoAlimenticia13Salario_52 = 52,
  /**53 Pensão alimentícia - Férias */
  DeducaoRendimentoTributavelIRRF_PensaoAlimenticiaFerias_53 = 53,
  /**54 Pensão alimentícia - PLR */
  DeducaoRendimentoTributavelIRRF_PensaoAlimenticiaPLR_54 = 54,
  /**61 Fundo de Aposentadoria Programada Individual - FAPI - Remuneração mensal */
  DeducaoRendimentoTributavelIRRF_FAPIRemuneracaoMensal_61 = 61,
  /**62 Fundo de Aposentadoria Programada Individual - FAPI - 13º salário */
  DeducaoRendimentoTributavelIRRF_FAPI13Salario_62 = 62,
  /**63 Fundação de previdência complementar do servidor público - Remuneração mensal */
  DeducaoRendimentoTributavelIRRF_FundacaoPrevidenciaMensal_63 = 63,
  /**64 Fundação de previdência complementar do servidor público - 13º salário */
  DeducaoRendimentoTributavelIRRF_FundacaoPrevidencia13Salario_64 = 64,
  /**65 Fundação de previdência complementar do servidor público - Férias */
  DeducaoRendimentoTributavelIRRF_FundacaoPrevidenciaFerias_65 = 65,
  /**66 Fundo de Aposentadoria Programada Individual - FAPI - Férias */
  DeducaoRendimentoTributavelIRRF_FAPIFerias_66 = 66,
  /**67 Plano privado coletivo de assistência à saúde */
  DeducaoRendimentoTributavelIRRF_PlanoPrivadoColetivoAssistenciaSaude_67 = 67,
  /**68 Desconto simplificado mensal*/
  DescontoSimplificadoMensal_68 = 68,

  /**70 Parcela isenta 65 anos - Remuneração mensal */
  RendimentoNaoTributavelIRRF_ParcelaIsenta65Mensal_70 = 70,
  /**71 Parcela isenta 65 anos - 13º salário */
  RendimentoNaoTributavelIRRF_ParcelaIsenta6513Salario_71 = 71,
  /**72 Diárias */
  RendimentoNaoTributavelIRRF_Diarias_72 = 72,
  /**73 Ajuda de custo */
  RendimentoNaoTributavelIRRF_AjudaCusto_73 = 73,
  /**74 Indenização e rescisão de contrato, inclusive a título de PDV e acidentes de trabalho */
  RendimentoNaoTributavelIRRF_IndenizacaoRescisaoContrato_74 = 74,
  /**75 Abono pecuniário */
  RendimentoNaoTributavelIRRF_AbonoPecuniario_75 = 75,
  /**76 Rendimento de beneficiário com moléstia grave ou acidente em serviço - Remuneração mensal */
  RendimentoNaoTributavelIRRF_RendimentoMolestiaGraveMensal_76 = 76,
  /**77 Rendimento de beneficiário com moléstia grave ou acidente em serviço - 13º salário */
  RendimentoNaoTributavelIRRF_RensimentoMolestiaGrave13Salario_77 = 77,
  /**700 Auxílio moradia */
  RendimentoNaoTributavelIRRF_AuxilioMoradia_700 = 700,
  /**701 Parte não tributável do valor de serviço de transporte de passageiros ou cargas */
  RendimentoNaoTributavelIRRF_NaoTributavelTransportePassageiros_701 = 701,
  /**702 Bolsa médico residente - remuneração mensal */
  BolsaMedicoResidenteRemuneracaoMensal_702 = 702,
  /**703 Bolsa médico residente - 13º salário */
  BolsaMedicoResidente13Salario_703 = 703,
  /**704 Juros de mora recebidos, devidos pelo atraso no pagamento de remuneração por exercício de emprego, cargo ou função */
  JurosMoraRecebidosDevidosPeloAtrasoPagamentoRemuneracao_704 = 704,
  /**79 Outras isenções (o nome da rubrica deve ser claro para identificação da natureza dos valores) */
  RendimentoNaoTributavelIRRF_OutrasIsencoes_79 = 79,

  /**9011 Remuneração mensal */
  ExigibilidadeSuspensaBaseCalculoIR_RemuneracaoMensal_9011 = 9011,
  /**9012 13º salário */
  ExigibilidadeSuspensaBaseCalculoIR_13Salario_9012 = 9012,
  /**9013 Férias */
  ExigibilidadeSuspensaBaseCalculoIR_Ferias_9013 = 9013,
  /**9014 PLR */
  ExigibilidadeSuspensaBaseCalculoIR_PLR_9014 = 9014,

  /**9031 Remuneração mensal */
  ExigibilidadeSuspensaRetencaoIRRFSobre_RemuneracaoMensal_9031 = 9031,
  /**9032 13º salário */
  ExigibilidadeSuspensaRetencaoIRRFSobre_13Salario_9032 = 9032,
  /**9033 Férias */
  ExigibilidadeSuspensaRetencaoIRRFSobre_Ferias_9033 = 9033,
  /**9034 PLR */
  ExigibilidadeSuspensaRetencaoIRRFSobre_PLR_9034 = 9034,
  /**9831 Depósito judicial - Mensal */
  ExigibilidadeSuspensaRetencaoIRRFSobre_DepositoJudicialMensal_9831 = 9831,
  /**9832 Depósito judicial - 13º salário */
  ExigibilidadeSuspensaRetencaoIRRFSobre_DepositoJudicial13Salario_9832 = 9832,
  /**9833 Depósito judicial - Férias */
  ExigibilidadeSuspensaRetencaoIRRFSobre_DepositoJudicialFerias_9833 = 9833,
  /**9834 Depósito judicial - PLR */
  ExigibilidadeSuspensaRetencaoIRRFSobre_DepositoJudicialPLR_9834 = 9834,

  /**9041 Previdência Social Oficial - PSO - Remuneração mensal */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PSOMensal_9041 = 9041,
  /**9042 PSO - 13º salário */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PSO13Salario_9042 = 9042,
  /**9043 PSO - Férias */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PSOFerias_9043 = 9043,
  /**9046 Previdência privada - Salário mensal */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PrevidenciaPrivadaMensal_9046 = 9046,
  /**9047 Previdência privada - 13º salário */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PrevidenciaPrivada13Salario_9047 = 9047,
  /**9048 Previdência privada - Férias */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PrevidenciaPrivadaFerias_9048 = 9048,
  /**9051 Pensão alimentícia - Remuneração mensal */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PensaoAlimenticiaMensal_9051 = 9051,
  /**9052 Pensão alimentícia - 13º salário */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PensaoAlimenticia13Salario_9052 = 9052,
  /**9053 Pensão alimentícia - Férias */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PensaoAlimenticiaFerias_9053 = 9053,
  /**9054 Pensão alimentícia - PLR */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PensaoAlimenticiaPLR_9054 = 9054,
  /**9061 Fundo de Aposentadoria Programada Individual - FAPI - Remuneração mensal */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_FAPIMensal_9061 = 9061,
  /**9062 Fundo de Aposentadoria Programada Individual - FAPI - 13º salário */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_FAPI13Salario_9062 = 9062,
  /**9063 Fundação de previdência complementar do servidor público - Remuneração mensal */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_FundacaoPrevidenciaComplementarMensal_9063 = 9063,
  /**9064 Fundação de previdência complementar do servidor público - 13º salário */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_FundacaoPrevidenciaComplementar13Salario_9064 = 9064,
  /**9065 Fundação de previdência complementar do servidor público - Férias */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_FundacaoPrevidenciaComplementarFerias_9065 = 9065,
  /**9066 Fundo de Aposentadoria Programada Individual - FAPI - Férias */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_FAPIFerias_9066 = 9066,
  /**9067 Plano privado coletivo de assistência à saúde */
  ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PlanoPrivadoColetivoSaude_9067 = 9067,

  /**9082 Compensação judicial do ano-calendário */
  CompensacaoJudicial_CompensacaoJudicialAnoCalendario_9082 = 9082,
  /**9083 Compensação judicial de anos anteriores */
  CompensacaoJudicial_CompensacaoJudicialAnosanteriores_9083 = 9083,

  /**Codigo padrão para codigo de receita não documentado */
  CodigoReceitaNaoDocumentado_9999 = 9999,
}

export const CodigoIncidenciaIRValues = [
  { value: CodigoIncidenciaIREnum.NaoInformado, name: '0 - Não informar', categoria: 0 },
  {
    value: CodigoIncidenciaIREnum.IRRF_isento_9,
    name: '9 - Verba transitada pela folha de pagamento de natureza diversa de rendimento ou retenção/isenção/dedução de IR (exemplo: desconto de convênio farmácia, desconto de consignações, etc.)',
    categoria: 0,
  },
  {
    value: CodigoIncidenciaIREnum.BaseCalculoIR_RemuneracaoMensal_11,
    name: '11 - Remuneração mensal',
    categoria: 1,
  },
  {
    value: CodigoIncidenciaIREnum.BaseCalculoIR_13Salario_12,
    name: '12 - 13º salário',
    categoria: 1,
  },
  { value: CodigoIncidenciaIREnum.BaseCalculoIR_Ferias_13, name: '13 - Férias', categoria: 1 },
  { value: CodigoIncidenciaIREnum.BaseCalculoIR_PLR_14, name: '14 - PLR', categoria: 1 },
  {
    value: CodigoIncidenciaIREnum.RetencaoIRRFSobre_RemuneracaoMensal_31,
    name: '31 - Remuneração mensal',
    categoria: 2,
  },
  {
    value: CodigoIncidenciaIREnum.RetencaoIRRFSobre_13Salario_32,
    name: '32 - 13º salário',
    categoria: 2,
  },
  { value: CodigoIncidenciaIREnum.RetencaoIRRFSobre_Ferias_33, name: '33 - Férias', categoria: 2 },
  { value: CodigoIncidenciaIREnum.RetencaoIRRFSobre_PLR_34, name: '34 - PLR', categoria: 2 },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_PrevidenciaSocialOficialPSO_41,
    name: '41 - Previdência Social Oficial - PSO - Remuneração mensal',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_PSO13Salario_42,
    name: '42 - PSO - 13º salário',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_PSOFerias_43,
    name: '43 - PSO - Férias',
    categoria: 3,
  },
  {
    value:
      CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_PrevidenciaPrivadaSalarioMensal_46,
    name: '46 - Previdência privada - Salário mensal',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_PrevidenciaPrivada13Salario_47,
    name: '47 - Previdência privada - 13º salário',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_PrevidenciaPrivadaFerias_48,
    name: '48 - Previdência privada - Férias',
    categoria: 3,
  },
  {
    value:
      CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_PensaoAlimenticiaRemuneracaoMensal_51,
    name: '51 - Pensão alimentícia - Remuneração mensal',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_PensaoAlimenticia13Salario_52,
    name: '52 - Pensão alimentícia - 13º salário',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_PensaoAlimenticiaFerias_53,
    name: '53 - Pensão alimentícia - Férias',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_PensaoAlimenticiaPLR_54,
    name: '54 - Pensão alimentícia - PLR',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_FAPIRemuneracaoMensal_61,
    name: '61 - Fundo de Aposentadoria Programada Individual - FAPI - Remuneração mensal',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_FAPI13Salario_62,
    name: '62 - Fundo de Aposentadoria Programada Individual - FAPI - 13º salário',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_FundacaoPrevidenciaMensal_63,
    name: '63 - Fundação de previdência complementar do servidor público - Remuneração mensal',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_FundacaoPrevidencia13Salario_64,
    name: '64 - Fundação de previdência complementar do servidor público - 13º salário',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_FundacaoPrevidenciaFerias_65,
    name: '65 - Fundação de previdência complementar do servidor público - Férias',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_FAPIFerias_66,
    name: '66 - Fundo de Aposentadoria Programada Individual - FAPI - Férias',
    categoria: 3,
  },
  {
    value:
      CodigoIncidenciaIREnum.DeducaoRendimentoTributavelIRRF_PlanoPrivadoColetivoAssistenciaSaude_67,
    name: '67 - Plano privado coletivo de assistência à saúde',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.DescontoSimplificadoMensal_68,
    name: '68 - Desconto simplificado mensal',
    categoria: 3,
  },
  {
    value: CodigoIncidenciaIREnum.RendimentoNaoTributavelIRRF_ParcelaIsenta65Mensal_70,
    name: '70 - Parcela isenta 65 anos - Remuneração mensal',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaIREnum.RendimentoNaoTributavelIRRF_ParcelaIsenta6513Salario_71,
    name: '71 - Parcela isenta 65 anos - 13º salário',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaIREnum.RendimentoNaoTributavelIRRF_Diarias_72,
    name: '72 - Diárias',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaIREnum.RendimentoNaoTributavelIRRF_AjudaCusto_73,
    name: '73 - Ajuda de custo',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaIREnum.RendimentoNaoTributavelIRRF_IndenizacaoRescisaoContrato_74,
    name: '74 - Indenização e rescisão de contrato, inclusive a título de PDV e acidentes de trabalho',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaIREnum.RendimentoNaoTributavelIRRF_AbonoPecuniario_75,
    name: '75 - Abono pecuniário',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaIREnum.RendimentoNaoTributavelIRRF_RendimentoMolestiaGraveMensal_76,
    name: '76 - Rendimento de beneficiário com moléstia grave ou acidente em serviço - Remuneração mensal',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaIREnum.RendimentoNaoTributavelIRRF_RensimentoMolestiaGrave13Salario_77,
    name: '77 - Rendimento de beneficiário com moléstia grave ou acidente em serviço - 13º salário',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaIREnum.RendimentoNaoTributavelIRRF_AuxilioMoradia_700,
    name: '700 - Auxílio moradia',
    categoria: 4,
  },
  {
    value:
      CodigoIncidenciaIREnum.RendimentoNaoTributavelIRRF_NaoTributavelTransportePassageiros_701,
    name: '701 - Parte não tributável do valor de serviço de transporte de passageiros ou cargas',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaIREnum.BolsaMedicoResidenteRemuneracaoMensal_702,
    name: '702 - Bolsa médico residente - remuneração mensal',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaIREnum.BolsaMedicoResidente13Salario_703,
    name: '703 - Bolsa médico residente - 13º salário',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaIREnum.JurosMoraRecebidosDevidosPeloAtrasoPagamentoRemuneracao_704,
    name: '704 - Juros de mora recebidos, devidos pelo atraso no pagamento de remuneração por exercício de emprego, cargo ou função',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaIREnum.RendimentoNaoTributavelIRRF_OutrasIsencoes_79,
    name: '79 - Outras isenções (o nome da rubrica deve ser claro para identificação da natureza dos valores)',
    categoria: 4,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaBaseCalculoIR_RemuneracaoMensal_9011,
    name: '9011 - Remuneração mensal',
    categoria: 5,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaBaseCalculoIR_13Salario_9012,
    name: '9012 - 13º salário',
    categoria: 5,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaBaseCalculoIR_Ferias_9013,
    name: '9013 - Férias',
    categoria: 5,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaBaseCalculoIR_PLR_9014,
    name: '9014 - PLR',
    categoria: 5,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaRetencaoIRRFSobre_RemuneracaoMensal_9031,
    name: '9031 - Remuneração mensal',
    categoria: 6,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaRetencaoIRRFSobre_13Salario_9032,
    name: '9032 - 13º salário',
    categoria: 6,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaRetencaoIRRFSobre_Ferias_9033,
    name: '9033 - Férias',
    categoria: 6,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaRetencaoIRRFSobre_PLR_9034,
    name: '9034 - PLR',
    categoria: 6,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaRetencaoIRRFSobre_DepositoJudicialMensal_9831,
    name: '9831 - Depósito judicial - Mensal',
    categoria: 6,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaRetencaoIRRFSobre_DepositoJudicial13Salario_9832,
    name: '9832 - Depósito judicial - 13º salário',
    categoria: 6,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaRetencaoIRRFSobre_DepositoJudicialFerias_9833,
    name: '9833 - Depósito judicial - Férias',
    categoria: 6,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaRetencaoIRRFSobre_DepositoJudicialPLR_9834,
    name: '9834 - Depósito judicial - PLR',
    categoria: 6,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PSOMensal_9041,
    name: '9041 - Previdência Social Oficial - PSO - Remuneração mensal',
    categoria: 7,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PSO13Salario_9042,
    name: '9042 - PSO - 13º salário',
    categoria: 7,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PSOFerias_9043,
    name: '9043 - PSO - Férias',
    categoria: 7,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PrevidenciaPrivadaMensal_9046,
    name: '9046 - Previdência privada - Salário mensal',
    categoria: 7,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PrevidenciaPrivada13Salario_9047,
    name: '9047 - Previdência privada - 13º salário',
    categoria: 7,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PrevidenciaPrivadaFerias_9048,
    name: '9048 - Previdência privada - Férias',
    categoria: 7,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PensaoAlimenticiaMensal_9051,
    name: '9051 - Pensão alimentícia - Remuneração mensal',
    categoria: 7,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PensaoAlimenticia13Salario_9052,
    name: '9052 - Pensão alimentícia - 13º salário',
    categoria: 7,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PensaoAlimenticiaFerias_9053,
    name: '9053 - Pensão alimentícia - Férias',
    categoria: 7,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PensaoAlimenticiaPLR_9054,
    name: '9054 - Pensão alimentícia - PLR',
    categoria: 7,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_FAPIMensal_9061,
    name: '9061 - Fundo de Aposentadoria Programada Individual - FAPI - Remuneração mensal',
    categoria: 7,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_FAPI13Salario_9062,
    name: '9062 - Fundo de Aposentadoria Programada Individual - FAPI - 13º salário',
    categoria: 7,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_FundacaoPrevidenciaComplementarMensal_9063,
    name: '9063 - Fundação de previdência complementar do servidor público - Remuneração mensal',
    categoria: 7,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_FundacaoPrevidenciaComplementar13Salario_9064,
    name: '9064 - Fundação de previdência complementar do servidor público - 13º salário',
    categoria: 7,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_FundacaoPrevidenciaComplementarFerias_9065,
    name: '9065 - Fundação de previdência complementar do servidor público - Férias',
    categoria: 7,
  },
  {
    value: CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_FAPIFerias_9066,
    name: '9066 - Fundo de Aposentadoria Programada Individual - FAPI - Férias',
    categoria: 7,
  },
  {
    value:
      CodigoIncidenciaIREnum.ExigibilidadeSuspensaDeducaoBaseCalculoIRRF_PlanoPrivadoColetivoSaude_9067,
    name: '9067 - Plano privado coletivo de assistência à saúde',
    categoria: 7,
  },
  {
    value: CodigoIncidenciaIREnum.CompensacaoJudicial_CompensacaoJudicialAnoCalendario_9082,
    name: '9082 - Compensação judicial do ano-calendário',
    categoria: 8,
  },
  {
    value: CodigoIncidenciaIREnum.CompensacaoJudicial_CompensacaoJudicialAnosanteriores_9083,
    name: '9083 - Compensação judicial de anos anteriores',
    categoria: 8,
  },
]

export const CodigoIncidenciaIRCategorias = {
  0: '',
  1: 'Rendimento tributável (base de cálculo do IR)',
  2: 'Retenção do IRRF efetuada sobre',
  3: 'Dedução do rendimento tributável do IRRF',
  4: 'Rendimento não tributável ou isento do IRRF',
  5: 'Exigibilidade suspensa - Rendimento tributável (base de cálculo do IR)',
  6: 'Exigibilidade suspensa - Retenção do IRRF efetuada sobre',
  7: 'Exigibilidade suspensa - Dedução da base de cálculo do IRRF',
  8: 'Compensação judicial:',
}
