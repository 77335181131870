import { useState, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { ActionDialog } from '~/components'

import { AutoCompleteHorarioTrabalho, MUIAutoComplete } from '~/components/AutoComplete'
import MultipleSelect from '~/components/MultipleSelect'

import useDialogNotification from '~/hooks/useDialogNotification'
import useHandleError from '~/hooks/useHandleError'

import * as yup from 'yup'
import _ from 'lodash'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

import { diaHorarioValues } from '~/values/diaHorarioValues'

import { Vinculo } from '~/hooks/queries/Vinculo/Vinculo'
import { VinculoHorarioItem } from '~/hooks/queries/VinculoHorarioItem/VinculoHorarioItem'

const schema = yup.object().shape({
  dias: yup
    .string()
    .when(['id'], (id, schema) =>
      !id ? schema.required('Informe os Dias da semana trabalhado') : schema,
    ),
  horarioTrabalhoId: yup.string().required('Informe o Horário de trabalho').nullable(),
})

interface DataForm extends VinculoHorarioItem {
  dias: { value: number; name: string }[]
}

interface FormProps {
  vinculo: Vinculo
  isOpen: boolean
  onClose: () => void
  data: VinculoHorarioItem | null
  onAfterSubmitFormItem: (
    event: 'update' | 'insert',
    data: VinculoHorarioItem | VinculoHorarioItem,
  ) => void
  diasOld: VinculoHorarioItem[]
}

const Form = ({
  vinculo,
  isOpen,
  onClose,
  data: _data,
  onAfterSubmitFormItem,
  diasOld,
}: FormProps) => {
  const [data, setData] = useState<DataForm>((_data || {}) as DataForm)
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const { extractErrorMessage } = useHandleError()
  const notification = useNotification()
  const { empregador } = useAmbiente()

  function handleVerifyVinculoHasSabadoCompensado() {
    const duracaoJornadaSabado = data?.horarioTrabalho?.duracaoJornadaSabado || 0
    const isSabadoCompensado = vinculo?.isSabadoCompensado

    if (isSabadoCompensado && !(duracaoJornadaSabado > 0)) {
      dialogNotification.info({
        descriptions: [
          `O vínculo ${vinculo?.pessoaFisica?.nome} possui em suas configurações a opção de 
          "Horas de sábado compensadas durante a semana", mas nenhum horário de trabalho com horas compensadas de sábado foi definido!`,
        ],
      })
    }
  }

  const handleSubmit = useCallback(() => {
    if (!data?.id) {
      handleVerifyVinculoHasSabadoCompensado()
    }
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/VinculoHorarioItem/${data.id}`, data)
        onAfterSubmitFormItem('update', response.data.data)
        notification.put()
      } catch (err) {
        const msg = extractErrorMessage(err)
        notification.error(msg)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const { dias: diasObject } = data

        const dias = diasObject.map((d) => d.value)

        const newOldDias = diasOld.map((dia) => {
          return dia.diaHorario
        })

        const diasNotRepeat = _.difference(dias, newOldDias)

        const response = await api.post(
          `/VinculoHorarioItem/InserirMultiplosHorarios?vinculoHorarioId=${data.vinculoHorarioId}&horarioTrabalhoId=${data.horarioTrabalhoId}`,
          diasNotRepeat,
        )
        if (diasNotRepeat.length !== dias.length) {
          notification.error(`Dia da Semana se repetiu, não foi possível inserir alguns Dias`)
        } else {
          notification.post()
        }
        onAfterSubmitFormItem('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()

    //eslint-disable-next-line
  }, [data, onAfterSubmitFormItem])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Dia da semana trabalhado"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {data?.id ? (
            <MUIAutoComplete
              label="Dia da semana trabalhado"
              disabled
              options={diaHorarioValues}
              renderOption={(option) => option.name}
              optionId="value"
              value={data.diaHorario}
            />
          ) : (
            <MultipleSelect
              inputProps={{
                label: 'Dias da semana trabalhado',
                required: true,
              }}
              validationErrors={validationErrors}
              name="dias"
              getOptionLabel={(option) => option.name}
              disableCloseOnSelect
              value={data?.dias || []}
              options={diaHorarioValues}
              onChange={(e, selecteds) => {
                const dias = selecteds
                setData({ ...data, dias })
              }}
            />
          )}
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteHorarioTrabalho
            value={data?.horarioTrabalho || null}
            empregadorId={empregador?.id}
            required
            validationErrors={validationErrors}
            name="horarioTrabalhoId"
            label="Horário de trabalho"
            onChange={(e, horarioTrabalho) => {
              const horarioTrabalhoId = horarioTrabalho?.id || null
              setData({ ...data, horarioTrabalhoId, horarioTrabalho })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
