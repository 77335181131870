import { Box, useTheme, IconButton, Paper } from '@material-ui/core'
import {
  FiFolderPlus as CollapseOpenIcon,
  FiFolderMinus as CollapseCloseIcon,
} from 'react-icons/fi'

import {
  Button,
  Checkbox,
  ContainerTable,
  Finder,
  Stack,
  ButtonBox,
  PDFViewer,
  ActionMenu,
} from '~/components'

import Form from '../Form'
import Table from '../Table'
import DialogAlerts from '../DialogAlerts'
import DialogConcedidaProgramada from '../DialogConcedidaProgramada'
import DialogLicencaRemunerada from '../DialogLicencaRemunerada'

import { useFeriasColetiva } from '../Context'
import useDialog from '~/hooks/useDialog'
import {
  FeriasColetivaDTO,
  FeriasColetivaRequestDTO,
} from '~/hooks/queries/FeriasColetiva/obterFuncionariosAnalitico'
import { Print } from '@material-ui/icons'
import { useRemoverRecibosDisponiveisExclusao } from '~/hooks/queries/FeriasColetiva/useRemoverRecibosDisponiveisExclusao'

export default function FeriasColetivaContent() {
  const { formProps, tableProps, requestProps } = useFeriasColetiva()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<{
    data: FeriasColetivaDTO[]
    params: {
      isImprimirAlertas: boolean
    }
  }>()

  const {
    paramsPage,
    onChangeAbono,
    onChangeFilterSetor,
    isOpenCollapseForm,
    setOpenCollapseForm,
    dataForm,
    setDataForm,
  } = formProps
  const { collection, rowsSelected, setQuery } = tableProps
  const { onProcess, onSearch, isSubmitting } = requestProps

  const theme = useTheme()

  const { mutateAsync: mutateAsyncRemoveRecibos, isLoading: isLoadingRemoveRecibos } =
    useRemoverRecibosDisponiveisExclusao()

  function handlePrint() {
    openPDFViewer({
      data: collection,
      params: {
        isImprimirAlertas: dataForm.isImprimirAlertas,
      },
    })
  }

  async function handleRemoveRecibosDisponiveis() {
    const ids = rowsSelected.map((row) => collection[row].funcionarioId)
    await mutateAsyncRemoveRecibos({
      data: ids,
      params: {
        dtInicioFerias: dataForm.dtInicioFerias || '',
      },
    })
    await onSearch(dataForm as FeriasColetivaRequestDTO)
  }

  return (
    <Stack height="100%">
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        component={Paper}
        p={1}
      >
        <Box display="flex" gridGap={theme.spacing(1)} width={350}>
          <Checkbox
            value={paramsPage.isAbono}
            label="Férias com Abono"
            onChange={(e, checked) => onChangeAbono(checked)}
          />
          <Checkbox
            value={paramsPage.isFilterBySetor}
            label="Filtrar por Setor"
            onChange={(e, checked) => onChangeFilterSetor(checked)}
          />
        </Box>

        <Box display="flex" gridGap={theme.spacing(1)}>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />

          <IconButton size="small" onClick={() => setOpenCollapseForm(!isOpenCollapseForm)}>
            {isOpenCollapseForm ? (
              <CollapseCloseIcon color="primary" />
            ) : (
              <CollapseOpenIcon color="primary" />
            )}
          </IconButton>
        </Box>
      </Box>

      <Form />

      <ContainerTable>
        <Table />
      </ContainerTable>

      <DialogAlerts />
      <DialogConcedidaProgramada />
      <DialogLicencaRemunerada />

      {collection.length > 0 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          component={Paper}
          p={1}
        >
          <Checkbox
            value={dataForm.isImprimirAlertas}
            label="Imprimir Alertas"
            onChange={(e, checked) =>
              setDataForm((prev) => ({ ...prev, isImprimirAlertas: checked }))
            }
          />

          <ButtonBox>
            <ActionMenu
              isLoading={isLoadingRemoveRecibos}
              options={[
                {
                  icon: 'delete',
                  disabled: rowsSelected.length > 0 ? false : true,
                  label: 'Remover recibos mensais disponíveis',
                  title:
                    rowsSelected.length > 0
                      ? 'Remover recibos mensais geralmente gerados na abertura da competência'
                      : 'Selecione os funcionários para remover os recibos disponíveis',
                  onClick: handleRemoveRecibosDisponiveis,
                  badgeContent: rowsSelected.length,
                },
              ]}
            />
            <Button disabled={!(collection.length > 0)} onClick={handlePrint} startIcon={<Print />}>
              Imprimir
            </Button>
            <Button
              isLoading={isSubmitting}
              disabled={!(rowsSelected.length > 0)}
              onClick={onProcess}
              variant="contained"
            >
              Processar
            </Button>
          </ButtonBox>
        </Box>
      )}

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          title="Férias Coletiva Analítica"
          axiosConfig={{
            method: 'post',
            url: '/FeriasColetiva/ObterFuncionariosAnaliticoRelatorio',
            ...dataPDFViewer,
          }}
        />
      )}
    </Stack>
  )
}
