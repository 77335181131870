import { useState } from 'react'

import IconButton from '@material-ui/core/IconButton'
import { ToolsDataTable, ButtonBox } from '~/components'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatSimpleDate } from '~/utils/utils'

import { VinculoHorario } from '~/hooks/queries/VinculoHorario/VinculoHorario'

interface TableProps {
  data: VinculoHorario[]
  onItemClick: (event: 'edit' | 'delete', id: string) => void
  isLoading: boolean
  isFinalizado: boolean
  renderExpandableRow: (index: number) => React.ReactNode
}

export default function Table({
  data,
  onItemClick,
  isLoading,
  isFinalizado,
  renderExpandableRow,
}: TableProps) {
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([0])

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtIncio',
      label: 'Data de Início',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          if (isFinalizado) return <></>
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      columns={columns}
      isLoading={isLoading}
      optionsExpandable={{
        type: 'single',
        expandedChildren: renderExpandableRow,
        onRowExpanded: setRowsExpanded,
        rowsExpanded,
      }}
    />
  )
}
