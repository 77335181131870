import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import {
  MultipleSelect,
  PageHeader,
  Button,
  Finder,
  StackContainer,
  ContainerTable,
  Checkbox,
} from '~/components'

import Table from './components/Table'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import { useObterEstabelecimentosComRecibosFinalizados } from '~/hooks/queries/RP/useObterEstabelecimentosComRecibosFinalizados'
import { useLiberarPorEstabelecimento } from '~/hooks/queries/RP/useLiberarPorEstabelecimento'

import { ReciboIDFEnum, ReciboIDFValues } from '~/@types/enums/ReciboIDFEnum'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

const recibosIdfsFinalizarRecibo = [
  ReciboIDFEnum.Mensal_1,
  ReciboIDFEnum.Ferias_2,
  ReciboIDFEnum.Complementar_3,
  ReciboIDFEnum.Adiantamento_4,
  ReciboIDFEnum.DecimoTerceiro1aParcela_7,
  ReciboIDFEnum.DecimoTerceiro2aParcela_8,
  ReciboIDFEnum.RescisaoSimulada_11,
  ReciboIDFEnum.Socio_15,
  ReciboIDFEnum.Autonomo_16,
  ReciboIDFEnum.Cooperado_19,
  ReciboIDFEnum.Ferias_Coletivas_21,
]

const recibosIdfsInicializar = [ReciboIDFEnum.Mensal_1, ReciboIDFEnum.Socio_15]

const recibosIdfsInicializarForm = ReciboIDFValues.filter((idr) =>
  recibosIdfsInicializar.includes(idr.value),
)

const recibosIdfs = ReciboIDFValues.filter((idr) => recibosIdfsFinalizarRecibo.includes(idr.value))

const schema = yup.object().shape({
  idfsRecibos: yup.array().min(1, 'Informe pelo menos uma Identificação dos Recibos'),
})

export default function LiberarRecibos() {
  const [rowsSelecteds, setRowsSelecteds] = useState([])
  const [data, setData] = useState({
    idfsRecibos: recibosIdfsInicializarForm,
    grupoId: undefined,
    isLiberarRecibosESocial: false,
  })
  const [query, setQuery] = useState('')

  const { anoMes } = useAmbiente()
  const theme = useTheme()
  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit: handleSubmit,
  })
  const {
    data: _c,
    isLoading,
    isFetching,
    refetch,
  } = useObterEstabelecimentosComRecibosFinalizados({
    params: {
      grupoId: data.grupoId,
      anoMes,
      isLiberarRecibosESocial: data.isLiberarRecibosESocial,
    },
    data: data.idfsRecibos.map((d) => d.value),
  })
  const collection = _c || []

  const { mutateAsync, isLoading: isProcessing } = useLiberarPorEstabelecimento()

  useEffect(() => {
    setRowsSelecteds([])
  }, [_c])

  async function handleSearch() {
    await refetch()
  }

  async function handleSubmit() {
    const estabelecimentosIds = rowsSelecteds.map((index) => collection[index].id)
    await mutateAsync({
      data: {
        estabelecimentosIds,
        idfsRecibos: data.idfsRecibos.map((d) => d.value),
      },
      params: {
        anoMes,
        isLiberarRecibosESocial: data.isLiberarRecibosESocial,
      },
    })
    refetch()
  }

  return (
    <StackContainer>
      <PageHeader title="Liberar Recibos">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Box
        component={Paper}
        padding={2}
        display="flex"
        alignItems="center"
        gridGap={theme.spacing(1)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <MultipleSelect
              inputProps={{
                label: 'Identificação dos Recibos',
              }}
              value={data.idfsRecibos}
              options={recibosIdfs}
              getOptionLabel={(option) => option.name}
              onChange={(e, options) => setData((prev) => ({ ...prev, idfsRecibos: options }))}
              limitTags={5}
              required
              validationErrors={validationErrors}
              name="idfsRecibos"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <Checkbox
              label="Liberar Recibos Enviados para o eSocial"
              value={data.isLiberarRecibosESocial}
              onChange={(_, checked) => {
                const isLiberarRecibosESocial = checked
                setData((prev) => ({ ...prev, isLiberarRecibosESocial }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <AutoCompleteGrupo
              value={data.grupoId}
              onChange={(e, grupo) => {
                const grupoId = grupo?.id || null
                setData((prev) => ({ ...prev, grupoId }))
              }}
            />
          </Grid>
        </Grid>

        <Button
          variant="contained"
          isLoading={isLoading || isFetching}
          onClick={() => handleSearch()}
        >
          Buscar
        </Button>
      </Box>

      <ContainerTable>
        <Table
          data={collection}
          query={query}
          isLoading={isLoading}
          isFetching={isFetching}
          rowsSelected={rowsSelecteds}
          handleRowSelected={setRowsSelecteds}
        />
      </ContainerTable>

      <Box display="flex" justifyContent="flex-end">
        <Button
          isLoading={isProcessing}
          variant="contained"
          size="small"
          color="primary"
          onClick={handleValidate}
          disabled={!(rowsSelecteds.length > 0)}
        >
          Liberar Recibos
        </Button>
      </Box>
    </StackContainer>
  )
}
