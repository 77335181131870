import { RefObject, useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { CurrencyTextField, DatePickerNew, TextField } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { VinculoSalarioBase } from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'

const schema = yup.object().shape({
  dtSalario: yup
    .date()
    .required('Informe Data Salário')
    .typeError('Informe uma data válida')
    .nullable(),
  dtEfetiva: yup
    .date()
    .required('Informe Data Efetiva')
    .typeError('Informe uma data válida')
    .nullable(),
  vrSalario: yup.number().required('Informe o Valor').min(0.0001, 'Informe o Valor'),
})

interface SalarioContratualFormSimpleProps {
  data: VinculoSalarioBase
  onSubmit: (data: VinculoSalarioBase) => void
  buttonRef: RefObject<HTMLButtonElement>
}

export default function SalarioContratualFormSimple({
  data: _data,
  onSubmit,
  buttonRef,
}: SalarioContratualFormSimpleProps) {
  const [data, setData] = useState(_data)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleSubmit() {
    onSubmit(data)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <DatePickerNew
          label="Data Salário"
          size="small"
          value={data.dtSalario || null}
          required
          validationErrors={validationErrors}
          name="dtSalario"
          onChange={(date) => {
            const dtSalario = date || ''
            setData((prev) => ({ ...prev, dtSalario }))
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <DatePickerNew
          label="Data Efetiva"
          size="small"
          value={data.dtEfetiva || null}
          required
          validationErrors={validationErrors}
          name="dtEfetiva"
          onChange={(date) => {
            const dtEfetiva = date || ''
            setData((prev) => ({ ...prev, dtEfetiva }))
          }}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <CurrencyTextField
          label="Valor"
          fullWidth
          value={data?.vrSalario || ''}
          variant="outlined"
          size="small"
          onChange={(_, obj) => {
            const vrSalario = obj || 0
            setData((oldState) => ({
              ...oldState,
              vrSalario,
            }))
          }}
          required
          validationErrors={validationErrors}
          name="vrSalario"
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          label="Observação"
          fullWidth
          value={data.observacao || ''}
          size="small"
          multiline
          inputProps={{
            maxLength: 200,
          }}
          onChange={(e) => {
            const observacao = e.target.value
            setData({ ...data, observacao })
          }}
          disabled={data?.id ? true : false}
        />
        <button
          ref={buttonRef}
          onClick={handleValidate}
          style={{
            display: 'none',
          }}
        />
      </Grid>
    </Grid>
  )
}
