import { useState } from 'react'

import { formatToCPF } from 'brazilian-values'

import { CopyLabel, ToolsDataTable } from '~/components'

import RecibosTable from './RecibosTable'

import { RelConsultaLancamentoVinculoDataClientDTO } from '~/hooks/queries/RelConsultaLancamentoVinculo/dtos/RelConsultaLancamentoVinculoDataClientDTO'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatSimpleDate } from '~/utils/utils'

interface TableProps {
  data: RelConsultaLancamentoVinculoDataClientDTO[]
  isLoading: boolean
  isFetching: boolean
  query: string
}

export default function Table({ data, isLoading, isFetching, query }: TableProps) {
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([])

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'vinculoNrInscricao',
      label: 'CPF',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel>,
      },
    },
    {
      name: 'vinculoNome',
      label: 'Nome',
    },
    {
      name: 'vinculoDtNascimento',
      label: 'Data de Nascimento',
      options: {
        customBodyRender: (value) => formatSimpleDate(value),
      },
    },
    {
      name: 'vinculoIdade',
      label: 'Idade',
    },
  ]

  return (
    <ToolsDataTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      optionsExpandable={{
        onRowExpanded: setRowsExpanded,
        rowsExpanded: rowsExpanded,
        type: 'single',
        expandedChildren: (index) => <RecibosTable data={data[index].recibos} />,
      }}
      sherlock={{
        query,
        columns: ['vinculoNome', 'vinculoNrInscricao'],
      }}
    />
  )
}
