import { CloudDownload } from '@material-ui/icons'

import { ActionDialog, Button, DragDropFile, Stack } from '~/components'

import { useGerarPlanilha } from '~/hooks/queries/PisoEnfermagem/useGerarPlanilha'
import { useImportarPlanilha } from '~/hooks/queries/PisoEnfermagem/useImportarPlanilha'

import { PisoEnfermagemFuncionarioDTO } from '~/hooks/queries/PisoEnfermagem/dtos/PisoEnfermagemFuncionarioDTO'

interface DialogImportacaoProps {
  isOpen: boolean
  onClose: () => void
  params: {
    anoMes: string
    estabelecimentoId: string
    cargoId: string | null
    vrMultiplicador: number
    vrFator: number
  }
  onAfterImport: (data: PisoEnfermagemFuncionarioDTO[]) => void
}

export default function DialogImportacao({
  isOpen,
  onClose,
  params,
  onAfterImport,
}: DialogImportacaoProps) {
  const { mutateAsync: mutateAsyncGerarPlanilha, isLoading: isLoadingGerarPlanilha } =
    useGerarPlanilha()

  const { mutateAsync: mutateAsyncImportarPlanilha, isLoading: isLoadingImportarPlanilha } =
    useImportarPlanilha()

  async function handleGerarPlanilha() {
    await mutateAsyncGerarPlanilha({
      params,
    })
  }

  async function onDropped(files: File[]) {
    const formData = new FormData()
    formData.append('file', files[0])
    const response = await mutateAsyncImportarPlanilha({
      data: formData,
      params,
    })
    onAfterImport(response)
    onClose()
  }

  return (
    <ActionDialog
      title="Importação de Planilha"
      isOpen={isOpen}
      onClose={onClose}
      onCancelClick={onClose}
      customActions={<></>}
      dialogProps={{
        fullWidth: true,
      }}
      renderRight={
        <Button
          variant="contained"
          startIcon={<CloudDownload />}
          onClick={handleGerarPlanilha}
          isLoading={isLoadingGerarPlanilha}
        >
          Baixar Planilha
        </Button>
      }
    >
      <Stack mb={2}>
        <DragDropFile
          onDropped={onDropped}
          acceptFiles={['.xlsx']}
          isLoading={isLoadingImportarPlanilha}
        />
      </Stack>
    </ActionDialog>
  )
}
