import { useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, Paper } from '@material-ui/core'
import {
  AnoMesTextField,
  Button,
  Checkbox,
  ContainerTable,
  Finder,
  PageHeader,
  PDFViewer,
  Stack,
  StackContainer,
} from '~/components'

import Table from './Table'

import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialog from '~/hooks/useDialog'

import { useObterData } from '~/hooks/queries/RelConsultaLancamentoVinculo/useObterData'

import { RelConsultaLancamentoVinculoRequestDTO } from '~/hooks/queries/RelConsultaLancamentoVinculo/dtos/RelConsultaLancamentoVinculoRequestDTO'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { Print } from '@material-ui/icons'

const schema = yup.object().shape({
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início'),
  anoMesFim: yup.string().required('Informe o Ano/Mês Fim'),
})

export default function ConsultaLancamentoVinculo() {
  const url = window.location.href
  const params = url.split('/')
  const ultimoParametro = params[params.length - 1]

  const vinculoTipo =
    ultimoParametro === 'consulta-lancamento-autonomo'
      ? VinculoTipoEnum.Autonomo_4
      : VinculoTipoEnum.Empregador_2

  const titlePage =
    'Consulta de Lançamentos por ' +
    (vinculoTipo === VinculoTipoEnum.Autonomo_4 ? 'Autônomo' : 'Empregador')

  const [query, setQuery] = useState('')

  const { anoMes } = useAmbiente()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<RelConsultaLancamentoVinculoRequestDTO>(
    {} as RelConsultaLancamentoVinculoRequestDTO,
  )

  const [dataForm, setDataForm] = useState<RelConsultaLancamentoVinculoRequestDTO>({
    anoMesInicio: anoMes.substring(0, 4) + '01',
    anoMesFim: anoMes.substring(0, 4) + '12',
    isFiltrarFatoGerador: false,
    vinculoTipo,
  })

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data: dataForm,
  })

  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useObterData({
    data: dataForm,
  })
  const data = _d || []

  async function handleSubmit(event: 'print' | 'search') {
    if (event === 'print') {
      openPDFViewer(dataForm)
    } else {
      refetch()
    }
  }

  return (
    <StackContainer>
      <PageHeader title={titlePage}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Stack component={Paper} p={1} orientation="horizontal" alignItems="center">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <Stack orientation="horizontal">
              <AnoMesTextField
                label="Ano/Mês Início"
                required
                name="anoMesInicio"
                validationErrors={validationErrors}
                value={dataForm.anoMesInicio}
                onChange={(e) => {
                  const anoMesInicio = e.target.value.replace('/', '')
                  setDataForm((prev) => ({
                    ...prev,
                    anoMesInicio,
                  }))
                }}
              />
              <AnoMesTextField
                label="Ano/Mês Fim"
                required
                name="anoMesFim"
                validationErrors={validationErrors}
                value={dataForm.anoMesFim}
                onChange={(e) => {
                  const anoMesFim = e.target.value.replace('/', '')
                  setDataForm((prev) => ({
                    ...prev,
                    anoMesFim,
                  }))
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box>
              <Checkbox
                label="Filtrar por Fato Gerador"
                value={dataForm.isFiltrarFatoGerador}
                onChange={(_, checked) => {
                  const isFiltrarFatoGerador = checked
                  setDataForm((prev) => ({ ...prev, isFiltrarFatoGerador }))
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Button
          variant="contained"
          onClick={() => handleValidate('search')}
          isLoading={isLoading || isFetching}
        >
          Buscar
        </Button>
      </Stack>

      <ContainerTable>
        <Table data={data} isFetching={isFetching} isLoading={isLoading} query={query} />
      </ContainerTable>

      <Stack alignItems="end" component={Paper} p={1}>
        <Button variant="contained" onClick={() => handleValidate('print')} startIcon={<Print />}>
          Imprimir
        </Button>
      </Stack>

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          reportKey="ConsultaLancamentoVinculo"
          title={titlePage}
          axiosConfig={{
            method: 'post',
            url: '/RelConsultaLancamentoVinculo/ObterRelatorio',
            data: dataPDFViewer,
          }}
        />
      )}
    </StackContainer>
  )
}
