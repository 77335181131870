import { useEffect, useState } from 'react'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'
import { Button, Finder } from 'mio-library-ui'

import { PageHeader, Checkbox } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import { formatIdfReciboToReportKey } from '~/hooks/useUtils'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import { ReciboIDFValues, ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'

import api from '~/services/api-pessoal'
import * as yup from 'yup'

const recibosIdfsValids = [
  ReciboIDFEnum.Mensal_1,
  ReciboIDFEnum.Complementar_3,
  ReciboIDFEnum.Ferias_2,
  ReciboIDFEnum.Adiantamento_4,
  ReciboIDFEnum.DecimoTerceiro1aParcela_7,
  ReciboIDFEnum.DecimoTerceiro2aParcela_8,
  ReciboIDFEnum.Autonomo_16,
  ReciboIDFEnum.Cooperado_19,
  ReciboIDFEnum.Ferias_Coletivas_21,
]

const newReciboIDFValues = ReciboIDFValues.filter((reciboIdf) =>
  recibosIdfsValids.includes(reciboIdf.value),
)

const schema = yup.object().shape({
  idfRecibo: yup.number().required('Informe a Identificação do Recibo'),
})

export default function DemonstrativoRPEmail() {
  const [collection, setCollection] = useState([])
  const [data, setData] = useState({
    idfRecibo: ReciboIDFEnum.Mensal_1,
    isBuscarEnviadosEmail: false,
  })
  const [isLoading, setLoading] = useState(false)
  const [isFetching, setFetching] = useState(false)
  const [query, setQuery] = useState('')
  const [rowsSelected, setRowsSelecteds] = useState([])
  const [isLoadingPrint, setLoadingPrint] = useState(false)
  const [report, setReport] = useState({})

  const dialogNotification = useDialogNotification()
  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })
  const { estabelecimento, anoMes } = useAmbiente()
  const notification = useNotification()
  const theme = useTheme()

  useEffect(() => {
    function resetFields() {
      setCollection([])
      setData({
        idfRecibo: ReciboIDFEnum.Mensal_1,
        isBuscarEnviadosEmail: false,
      })
      setRowsSelecteds([])
    }
    resetFields()
  }, [estabelecimento, anoMes])

  useEffect(() => {
    async function getReportKey() {
      let key = formatIdfReciboToReportKey(data.idfRecibo)
      const response = await api.get('/Relatorio/GetByChaveId', {
        params: {
          chaveId: key,
        },
      })
      setReport(response?.data?.data[0] || {})
    }
    getReportKey()
  }, [data.idfRecibo])

  async function handleSubmit() {
    setLoading(true)
    try {
      const response = await api.get('/RP/ObterDemonstrativo', {
        params: {
          estabelecimentoId: estabelecimento.id,
          identificacaoRecibo: data.idfRecibo,
          anoMes,
          isBuscarEnviadosEmail: data.isBuscarEnviadosEmail,
        },
      })
      setCollection(response?.data?.data || [])
      setRowsSelecteds([])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  async function handleSend() {
    setLoadingPrint(true)
    try {
      if (!(rowsSelected.length > 0)) throw new Error('Selecione pelo menos um Funcionário')
      const rpIds = rowsSelected.map((rowIndex) => collection[rowIndex].rpId)
      await api.post('/RP/EnviarEmail', rpIds, {
        headers: {
          'dc-printmode': 'PDF',
          'dc-printrelatorioid': report?.id,
        },
      })
      handleValidate()
      setRowsSelecteds([])
      notification.success('Os demonstrativos foram enviados com sucesso')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingPrint(false)
    }
  }

  function isRowSelectable(dataIndex) {
    const dataCurrent = collection[dataIndex]
    if (!dataCurrent?.email || !dataCurrent?.isFinalizado) return false
    return true
  }

  async function handleAfterSubmit() {
    setFetching(true)
    try {
      const response = await api.get('/RP/ObterDemonstrativo', {
        params: {
          estabelecimentoId: estabelecimento.id,
          identificacaoRecibo: data.idfRecibo,
          anoMes,
          isBuscarEnviadosEmail: data.isBuscarEnviadosEmail,
        },
      })
      setCollection(response?.data?.data || [])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setFetching(false)
    }
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Demonstrativo por Email">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} padding={2}>
        <Grid container spacing={2} style={{ paddingBottom: theme.spacing(1) }}>
          <Grid item xs={12} sm={10} md={11}>
            <Box display="flex" gridGap={theme.spacing(1)}>
              <Box flex={1}>
                <MUIAutoComplete
                  label="Identificação do Recibo"
                  name="idfRecibo"
                  validationErrors={validationErrors}
                  options={newReciboIDFValues}
                  optionId="value"
                  renderOption={(option) => option.name}
                  value={data?.idfRecibo}
                  onChange={(e, obj) => {
                    setCollection([])
                    setRowsSelecteds([])
                    const idfRecibo = obj?.value
                    setData((oldState) => ({ ...oldState, idfRecibo }))
                  }}
                />
              </Box>
              <Box>
                <Checkbox
                  label="Buscar recibos já enviados por email"
                  value={data.isBuscarEnviadosEmail}
                  onChange={(_, checked) =>
                    setData((prev) => ({ ...prev, isBuscarEnviadosEmail: checked }))
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={1}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleValidate}
              isLoading={isLoading}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Table
        data={collection}
        isLoading={isLoading}
        isFetching={isFetching}
        query={query}
        onRowSelected={(indexs) => {
          if (rowsSelected.length === 0 && indexs.length === 0) {
            const arrayIndexs = []
            collection.forEach((d, index) =>
              d?.isFinalizado && d?.email ? arrayIndexs.push(index) : null,
            )
            setRowsSelecteds(arrayIndexs)
            return
          }
          setRowsSelecteds(indexs)
        }}
        rowsSelected={rowsSelected}
        isRowSelectable={isRowSelectable}
        afterChangeItemTable={handleAfterSubmit}
      />

      <Box display="flex" alignItems="center" justifyContent="flex-end" pt={1}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleSend}
          isLoading={isLoadingPrint}
        >
          Enviar Demonstrativos
        </Button>
      </Box>
    </Box>
  )
}
