import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { ToolsDataTable, ButtonBox } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { VinculoHorarioItem } from '~/hooks/queries/VinculoHorarioItem/VinculoHorarioItem'
import { diaHorarioValues } from '~/values/diaHorarioValues'

const formatDiaHorario = (diaHorario: number) => {
  const objectFinded = diaHorarioValues.find((obj) => obj.value === diaHorario)
  return objectFinded?.name || ''
}

const formatNumberInHour = (number: string) => {
  if (!number) return ''
  return `${number.substring(0, 2)}:${number.substring(2)}`
}

interface TableProps {
  data: VinculoHorarioItem[]
  onItemClick: (event: 'edit' | 'delete' | 'intervalos', id: string) => void
  isLoading: boolean
  isFinalizado: boolean
}

const Table = ({ data: _data, onItemClick, isLoading, isFinalizado }: TableProps) => {
  const data = _data.map((item) => {
    return {
      ...item,
      diaHorario: formatDiaHorario(item?.diaHorario),
      horarioTrabalhoNome:
        (item?.horarioTrabalho?.codigo || '') + ' - ' + (item?.horarioTrabalho?.nome || ''),
      horaEntrada: formatNumberInHour(item?.horarioTrabalho?.horaEntrada || ''),
      horaSaida: formatNumberInHour(item?.horarioTrabalho?.horaSaida || ''),
    }
  })

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'diaHorario',
      label: 'Dia da Semana Trabalhado',
    },
    {
      name: 'horarioTrabalhoNome',
      label: 'Horário de Trabalho',
    },
    {
      name: 'horaEntrada',
      label: 'Entrada',
    },
    {
      name: 'horaSaida',
      label: 'Saída',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Visualizar Intervalos"
                color="primary"
                aria-label="Intervalos"
                onClick={() => {
                  const dtCurrent = _data.find((d) => d.id === value)
                  if (!dtCurrent?.horarioTrabalhoId) return
                  onItemClick('intervalos', dtCurrent.horarioTrabalhoId)
                }}
              >
                <VisibilityIcon fontSize="small" color="primary" />
              </IconButton>

              {!isFinalizado && (
                <>
                  <IconButton
                    size="small"
                    title="Editar este registro"
                    color="primary"
                    aria-label="Editar"
                    onClick={() => {
                      onItemClick('edit', value)
                    }}
                  >
                    <EditIcon fontSize="small" color="primary" />
                  </IconButton>

                  <IconButton
                    size="small"
                    title="Deletar este registro"
                    color="primary"
                    aria-label="Deletar"
                    onClick={() => {
                      onItemClick('delete', value)
                    }}
                  >
                    <DeleteIcon fontSize="small" color="primary" />
                  </IconButton>
                </>
              )}
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      columns={columns}
      isLoading={isLoading}
      pagination={false}
    />
  )
}

export default Table
