export enum DetalhamentoContribuicaoSocialColunaEnum {
  NormalBS,
  Aposentadoria15BS,
  Aposentadoria20BS,
  Aposentadoria25BS,
  SalarioFamilia,
  SalarioMaternidade,
  Sest,
  Senat,
}
