import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ModeloRelatorioSimplesDTO } from './dtos/ModeloRelatorioSimplesDTO'

const query = '/ModeloRelatorio/ObterModelos'

export function useObterModelos() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ModeloRelatorioSimplesDTO[] }>(query)
    return response.data.data
  }

  return useQuery(query, handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useClientObterModelos() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
