import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ModeloRelatorioTagDTO } from './dtos/ModeloRelatorioTagDTO'

const query = '/ModeloRelatorio/ObterTags'

export function useObterTags() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ModeloRelatorioTagDTO[] }>(query)
    return response.data.data
  }

  return useQuery(query, handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useClientObterTags() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
