import React from 'react'

import moment from 'moment'

import { Grid } from '@material-ui/core'

import StepperForm from '~/components/StepperForm'

import FormAdmissao from './FormAdmissao'
import FormLotacao from '~/pages/Funcionario/FlowCreate/FormLotacao'
import FormSalarioContratual from '~/pages/Funcionario/FlowCreate/FormSalarioContratual'
import FormAmbienteTrabalho from '~/pages/Funcionario/FlowCreate/FormAmbienteTrabalho'
import FormObservacao from '~/pages/Funcionario/FlowCreate/FormObservacao'

import { useVinculoCadastroCompleto } from '~/hooks/queries/Vinculo/useVinculoCadastroCompleto'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoPartial } from '~/hooks/queries/Vinculo/Vinculo'
import { VinculoLotacaoPartial } from '~/hooks/queries/VinculoLotacao/VinculoLotacao'
import { VinculoSalarioBasePartial } from '~/hooks/queries/VinculoSalarioBase/VinculoSalarioBase'
import { VinculoLocalPartial } from '~/hooks/queries/VinculoLocal/VinculoLocal'
import { VinculoObservacaoPartial } from '~/hooks/queries/VinculoObservacao/VinculoObservacao'

import { IndSalarioContratualEnum } from '~/@types/enums/IndSalarioContratualEnum'

export interface MainDataForm {
  dataInitialForm: VinculoPartial
  dataLotacao: VinculoLotacaoPartial
  dataSalarioContratual: VinculoSalarioBasePartial
  dataAmbienteTrabalho: VinculoLocalPartial
  dataObservacao?: VinculoObservacaoPartial
}

interface FlowCreateProps {
  data: VinculoPartial
  onCancel: () => void
}

export default function FlowCreate(props: FlowCreateProps) {
  const { data: dataInitialForm, onCancel } = props

  const { isLoading, mutateAsync } = useVinculoCadastroCompleto()

  const { estabelecimento } = useAmbiente()

  const mainData: MainDataForm = {
    dataInitialForm,
    dataLotacao: {
      anoMes: moment(dataInitialForm.dtAdmissao).format('yyyyMM'),
      lotacaoId: estabelecimento.lotacaoId || undefined,
    },
    dataSalarioContratual: {
      dtSalario: dataInitialForm.dtAdmissao,
      dtEfetiva: dataInitialForm.dtAdmissao,
      indSalario: IndSalarioContratualEnum.Minimo,
    },
    dataAmbienteTrabalho: {
      dtLocal: dataInitialForm.dtAdmissao,
    },
  }

  async function handleSubmit(dt: MainDataForm) {
    delete dt.dataInitialForm.pessoaFisica
    delete dt.dataInitialForm.sindicato
    delete dt.dataInitialForm.titulacao
    delete dt.dataAmbienteTrabalho.setor
    delete dt.dataAmbienteTrabalho.departamento

    await mutateAsync({
      vinculo: dt.dataInitialForm,
      vinculoLotacao: dt.dataLotacao,
      vinculoSalarioBase: dt.dataSalarioContratual,
      vinculoLocal: dt.dataAmbienteTrabalho,
      vinculoObservacao: dt.dataObservacao,
    })
    onCancel()
  }

  return (
    <StepperForm.Provider<MainDataForm>
      onSubmit={handleSubmit}
      onCancel={onCancel}
      data={mainData}
      isLoading={isLoading}
      steps={[
        {
          label: 'Admissão',
          children: <FormAdmissao />,
          icon: 'person',
        },
        {
          label: 'Lotação',
          children: <FormLotacao />,
          icon: 'group',
        },
        {
          label: 'PRÓ-LABORE',
          children: <FormSalarioContratual title="Cadastro de PRÓ-LABORE" />,
          icon: 'attach_money',
        },
        {
          label: 'Ambiente Trabalho',
          children: <FormAmbienteTrabalho />,
          icon: 'work',
        },
        {
          label: 'Observação',
          children: <FormObservacao />,
          icon: 'message',
        },
      ]}
    >
      <Grid container spacing={0} style={{ marginBottom: '3rem' }}>
        <Grid item xs={1} md={2}>
          <StepperForm.Steps />
        </Grid>
        <Grid item xs={11} md={10}>
          <StepperForm.Content />
        </Grid>
      </Grid>
    </StepperForm.Provider>
  )
}
