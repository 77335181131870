import React from 'react'

const PageInDevelopement = () => {
  return (
    <h2
      style={{
        padding: '10px 30px',
      }}
    >
      Está funcionalidade está em desenvolvimento, por favor, aguarde...
    </h2>
  )
}

export default PageInDevelopement
