import { useMemo, useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import {
  PageHeader,
  Finder,
  DatePickerNew,
  Button,
  MultipleSelectCheck,
  PrintButton,
  PDFViewer,
  Checkbox,
} from '~/components'

import Table from './Table'

import { useObterConsultaVencimentos } from '~/hooks/queries/ConsultaVencimentos/useObterConsultaVencimentos'
import {
  IndConsultaVencimentosEnum,
  IndConsultaVencimentosValues,
} from '~/@types/enums/IndConsultaVencimentosEnum'

import useValidationErrors from '~/hooks/useValidationErrors'
import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import { getDateMinAnoMes, getDateMaxAnoMes } from '~/utils/utils'
import {
  ConsultaVencimentosSortEnum,
  ConsultaVencimentosSortValues,
} from '~/@types/enums/ConsultaVencimentosSortEnum'
import { MUIAutoComplete } from '~/components/AutoComplete'

const TODOS_VALUE_IND_CONSULTA = 999

const todosOptionConsulta = { name: 'Todos', value: TODOS_VALUE_IND_CONSULTA }

const newOptionsConsulta = [todosOptionConsulta, ...IndConsultaVencimentosValues]

const schema = yup.object().shape({
  dtVencimento: yup
    .date()
    .required('Informe a Data de Vencimento')
    .typeError('Informe uma data válida'),
})

const TITLE_PAGE = 'Consulta Vencimentos'

export default function EtiquetaContratoExperiencia() {
  const { anoMes } = useAmbiente()

  const [query, setQuery] = useState('')
  const [data, setData] = useState<{
    indConsultaVencimentosEnum: typeof newOptionsConsulta
    dtInicio: string
    dtVencimento: string
    sortField: ConsultaVencimentosSortEnum
    isRelatorioPaisagem: boolean
  }>({
    indConsultaVencimentosEnum: [],
    dtInicio: getDateMinAnoMes(anoMes),
    dtVencimento: getDateMaxAnoMes(anoMes),
    sortField: ConsultaVencimentosSortEnum.DataVencimento,
    isRelatorioPaisagem: true,
  })

  const theme = useTheme()

  const dataInds = useMemo(
    () =>
      data.indConsultaVencimentosEnum
        .map((d) => d.value)
        .filter((d) => d !== TODOS_VALUE_IND_CONSULTA),
    [data],
  ) as IndConsultaVencimentosEnum[]

  const {
    data: _collection,
    isLoading,
    isFetching,
    refetch,
  } = useObterConsultaVencimentos({
    params: { dtInicio: data.dtInicio, dtVencimento: data.dtVencimento, sortField: data.sortField },
    data: dataInds,
  })
  const collection = _collection || []

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const { isOpen: isOpenPDFViewer, close: closePDFViewer, open: openPDFViewer } = useDialog()

  async function handleSubmit() {
    await refetch()
  }

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      gridGap={theme.spacing(1)}
      flexDirection="column"
    >
      <PageHeader title={TITLE_PAGE}>
        <Box minWidth={220}>
          <Checkbox
            label="Imprimir em modo paisagem"
            value={data.isRelatorioPaisagem}
            onChange={(_, checked) => {
              const isRelatorioPaisagem = checked
              setData((prev) => ({ ...prev, isRelatorioPaisagem }))
            }}
          />
        </Box>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Box component={Paper} p={1} display="flex" alignItems="center" gridGap={theme.spacing(1)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <MultipleSelectCheck
              label="Tipo de Consulta"
              options={newOptionsConsulta}
              value={data.indConsultaVencimentosEnum}
              onChange={(obj) => {
                if (obj.some((d) => d.value === TODOS_VALUE_IND_CONSULTA)) {
                  setData((prev) => ({
                    ...prev,
                    indConsultaVencimentosEnum: [todosOptionConsulta],
                  }))
                } else {
                  setData((prev) => ({ ...prev, indConsultaVencimentosEnum: obj }))
                }
              }}
              getOptionLabel={(option) => option.name}
              fieldCompare="value"
              getOptionDisabled={(d) =>
                data.indConsultaVencimentosEnum.some((d) => d.value === TODOS_VALUE_IND_CONSULTA) &&
                d.value !== TODOS_VALUE_IND_CONSULTA
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <DatePickerNew
                  label="Data de Início"
                  value={data.dtInicio || null}
                  name="dtInicio"
                  validationErrors={validationErrors}
                  onChange={(date) => {
                    const dtInicio = date || ''
                    setData((prev) => ({
                      ...prev,
                      dtInicio,
                    }))
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <DatePickerNew
                  label="Data de Vencimento"
                  value={data.dtVencimento || null}
                  name="dtVencimento"
                  validationErrors={validationErrors}
                  onChange={(date) => {
                    const dtVencimento = date || ''
                    setData((prev) => ({
                      ...prev,
                      dtVencimento,
                    }))
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <MUIAutoComplete
                  label="Ordernar por"
                  options={ConsultaVencimentosSortValues}
                  optionId="value"
                  renderOption={(option) => option.name}
                  value={data.sortField}
                  onChange={(e, obj) => {
                    const sortField = obj?.value || ConsultaVencimentosSortEnum.DataVencimento
                    setData((prev) => ({ ...prev, sortField }))
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Button variant="contained" onClick={handleValidate}>
          Buscar
        </Button>
      </Box>
      <Box flex={1} overflow="auto" position="relative">
        <Box position="absolute" width="100%">
          <Table data={collection} isLoading={isLoading} isFetching={isFetching} query={query} />
        </Box>
      </Box>
      <Box component={Paper} p={1} display="flex" justifyContent="flex-end">
        <PrintButton
          disabled={collection.length > 0 ? false : true}
          onClick={() => openPDFViewer(null)}
        />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey={data.isRelatorioPaisagem ? 'ConsultaVencimentosPaisagem' : 'ConsultaVencimentos'}
        title={TITLE_PAGE}
        axiosConfig={{
          method: 'post',
          url: '/ConsultaVencimentos/ObterRelatorioConsultaVencimentos',
          data: dataInds,
          params: {
            dtInicio: data.dtInicio,
            dtVencimento: data.dtVencimento,
            sortField: data.sortField,
            isRelatorioPaisagem: data.isRelatorioPaisagem,
          },
        }}
      />
    </Box>
  )
}
