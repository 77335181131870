import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPut } from '~/utils/notification'

interface RequestProps {
  data: FormData
  params: {
    id: string
  }
}

export function useAtualizarArquivo() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    await api.put('/ModeloRelatorio/AtualizarArquivo', data, { params })
  }

  return useMutation(handleRequest, {
    onSuccess: () => notifyPut(),
    onError: dialogNotification.extractErrors,
  })
}
