import { useState } from 'react'

import { Box, Paper, Tab, Tabs } from '@material-ui/core'

import { PageHeader, StackContainer, TabPanel } from '~/components'

import TabRelatorios from './components/TabRelatorios'
import TabProcessar from './components/TabProcessar'

enum GeradorRelatoriosTabs {
  Relatorios,
  Processar,
}

export default function GeradorRelatorios() {
  const [tab, setTab] = useState(GeradorRelatoriosTabs.Relatorios)

  return (
    <StackContainer>
      <PageHeader title="Gerador de Relatórios">
        <Box component={Paper}>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => setTab(value)}
          >
            <Tab label="Relatórios" />
            <Tab label="Processar" />
          </Tabs>
        </Box>
      </PageHeader>

      <Box flex={1}>
        <TabPanel value={tab} index={GeradorRelatoriosTabs.Relatorios}>
          <TabRelatorios />
        </TabPanel>
        <TabPanel value={tab} index={GeradorRelatoriosTabs.Processar}>
          <TabProcessar />
        </TabPanel>
      </Box>
    </StackContainer>
  )
}
