import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'
import { useResetQuery } from './useObterVinculosSimulados'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPost } from '~/utils/notification'
import { AlteracaoSalarialColetivaProcessoDTO } from './dtos/AlteracaoSalarialColetivaProcessoDTO'

interface RequestProps {
  data: AlteracaoSalarialColetivaProcessoDTO
}

export function useProcessarVinculos() {
  const dialogNotification = useDialogNotification()
  const { handle } = useResetQuery()

  async function handleRequest({ data }: RequestProps) {
    await api.post('/AlteracaoSalarialColetiva/ProcessarVinculos', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handle()
    },
    onError: dialogNotification.extractErrors,
  })
}
