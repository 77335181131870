import { useState, useEffect, useCallback } from 'react'

import * as yup from 'yup'
import api from '~/services/api-pessoal'
import { cnpj, cpf } from 'cpf-cnpj-validator'

import { PageHeader, ButtonBox } from 'mio-library-ui'
import {
  Grid,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Tooltip,
  IconButton,
  Zoom,
  Box,
} from '@material-ui/core'
import {
  FiFolderPlus as CollapseOpenIcon,
  FiFolderMinus as CollapseCloseIcon,
} from 'react-icons/fi'

import {
  CollapseContent,
  DatePicker,
  NumeroInscricaoTextField,
  ContainerForm,
  Checkbox as CheckboxComponent,
  TextField,
  ContentDivider,
} from '~/components'
import {
  AutoCompleteContador,
  AutoCompleteCertificado,
  MUIAutoComplete,
  AutoCompleteGrupo,
} from '~/components/AutoComplete'

import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import { IndHomologacaoValues } from '~/@types/enums/IndHomologacaoEnum'
import { indPontoEletronicoValues } from '~/values/indPontoEletronicoValues'
import {
  tipoInscricaoConsts,
  tipoInscricaoValues as _tipoInscricaoValues,
} from '~/values/tipoInscricaoValues'
import { IndImprimirEventosValues } from '~/@types/enums/IndImprimirEventosEnum'

import { useEmpregadorCreate } from '~/hooks/queries/Empregador/useEmpregadorCreate'
import {
  IndCadastroMatriculaEnum,
  IndCadastroMatriculaValues,
} from '~/@types/enums/IndCadastroMatriculaEnum'
import { IndBaseInsalubridadeValues } from '~/@types/enums/IndBaseInsalubridadeEnum'

const { CNPJ_1, CPF_2 } = tipoInscricaoConsts

const tipoInscricaoValues = _tipoInscricaoValues.filter(
  (obj) => obj.value === CNPJ_1 || obj.value === CPF_2,
)

const schema = yup.object().shape({
  tipoInscricao: yup.string().required('Informe o Tipo de Inscrição'),
  nrInscricao: yup
    .string()
    .required('Informe Número de Inscrição')
    .when(['tipoInscricao'], (tipoInscricao, schema) =>
      schema.test('test', 'Informe um Número de Inscriçao válido', (nrInscricao) => {
        if (parseInt(tipoInscricao) === CNPJ_1) return cnpj.isValid(nrInscricao)
        if (parseInt(tipoInscricao) === CPF_2) return cpf.isValid(nrInscricao)
      }),
    ),
  nome: yup.string().required('Informe o Nome'),
  dtInicioAtividade: yup
    .date()
    .required('Informe o Início Atividade')
    .nullable()
    .typeError('Informe uma data válida'),
  indHomologacao: yup.string().required('Informe o Indicador de Homologação'),
  indPontoEletronico: yup.string().required('Informe o Indicador de Ponto Eletrônico'),
  contadorId: yup.string().required('Informe o Contador'),
  matriculaOrigem: yup
    .string()
    .when(['isMatriculaSequencial'], (isMatriculaSequencial, schema) => {
      if (isMatriculaSequencial) {
        return schema.required('Informe a Matrícula de Origem')
      } else {
        return schema
      }
    })
    .nullable(),

  indBaseCalculoInsalubridade: yup
    .string()
    .required('Informe o Indicador Base de Cálculo Insalubridade'),
})

const HEADER_HEIGHT = '70px'

const Form = (props) => {
  const { data: _data, onAfterSubmitForm, onClose, setWasModified } = props
  const [data, setData] = useState({})
  const [collapseItens, setCollapseItens] = useState({
    primaria: true,
    atividade: true,
    outras: true,
    eSocial: true,
    parametros: true,
  })
  const [_isLoading, setLoading] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  const { mutateAsync: mutateEmpregadorCreate, isLoading: isLoadingCreate } = useEmpregadorCreate()

  const isLoading = _isLoading || isLoadingCreate

  useEffect(() => {
    setWasModified(JSON.stringify(data) !== JSON.stringify(_data))
    //eslint-disable-next-line
  }, [data])

  useEffect(() => {
    setData(_data)
    //eslint-disable-next-line
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setLoading(true)
      try {
        data.nome = data.nome.trim()
        const response = await api.put(`/empregador/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setLoading(false)
    }

    const insert = async () => {
      const response = await mutateEmpregadorCreate({ data })
      onAfterSubmitForm('insert', response)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    // eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const toggleCollapse = (item) => {
    setCollapseItens({ ...collapseItens, [item]: !collapseItens[item] })
  }

  const handleCollapseAll = (open) => {
    let newItens = {}

    for (const item in collapseItens) {
      newItens = { ...newItens, [item]: open }
    }

    setCollapseItens({ ...newItens })
  }

  return (
    <Box height="100%">
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1} bottom={1}>
            <Tooltip title="Expandir todos" arrow TransitionComponent={Zoom}>
              <IconButton size="small" onClick={() => handleCollapseAll(true)}>
                <CollapseOpenIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Retrair todos" arrow TransitionComponent={Zoom}>
              <IconButton size="small" onClick={() => handleCollapseAll(false)}>
                <CollapseCloseIcon />
              </IconButton>
            </Tooltip>
          </ButtonBox>
        </PageHeader>
      </Box>

      <ContainerForm
        handleSubmit={handleValidate}
        onCancel={onClose}
        isLoading={isLoading}
        removeHeight={HEADER_HEIGHT}
      >
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Informações primárias"
              isOpen={collapseItens.primaria}
              onClick={() => toggleCollapse('primaria')}
            >
              <Grid container spacing={2}>
                <Grid xl={4} lg={4} md={4} sm={4} xs={12} item>
                  <MUIAutoComplete
                    label="Tipo de Inscrição"
                    name="tipoInscricao"
                    required
                    validationErrors={validationErrors}
                    options={tipoInscricaoValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    value={data?.tipoInscricao || ''}
                    onChange={(e, obj) => {
                      const tipoInscricao = obj?.value
                      setData((oldState) => ({
                        ...oldState,
                        tipoInscricao,
                      }))
                    }}
                  />
                </Grid>

                <Grid xl={8} lg={8} md={8} sm={8} xs={12} item>
                  <NumeroInscricaoTextField
                    size="small"
                    variant="outlined"
                    autoFocus
                    fullWidth
                    typeMask={data?.tipoInscricao}
                    label="Número de Inscrição"
                    value={data?.nrInscricao || ''}
                    name="nrInscricao"
                    getDataCNPJ={
                      !data?.id
                        ? (response) => {
                            setData((oldState) => ({
                              ...oldState,
                              nome: response.nome,
                            }))
                          }
                        : undefined
                    }
                    required
                    validationErrors={validationErrors}
                    onChange={(e, value) => {
                      const nrInscricao = value
                      setData((oldState) => ({
                        ...oldState,
                        nrInscricao,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                  <TextField
                    label="Código"
                    fullWidth
                    value={data?.codigo || ''}
                    variant="outlined"
                    size="small"
                    disabled
                  />
                </Grid>

                <Grid xl={10} lg={10} md={10} sm={9} xs={12} item>
                  <TextField
                    label="Nome"
                    name="nome"
                    validationErrors={validationErrors}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={data?.nome || ''}
                    required
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={(e) => {
                      const nome = e?.target?.value || ''
                      setData((oldState) => ({
                        ...oldState,
                        nome,
                      }))
                    }}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Atividade"
              isOpen={collapseItens.atividade}
              onClick={() => toggleCollapse('atividade')}
              top={1}
            >
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <DatePicker
                    label="Início Atividade"
                    size="small"
                    value={data.dtInicioAtividade || null}
                    required
                    validationErrors={validationErrors}
                    name="dtInicioAtividade"
                    onChange={(date) => {
                      const dtInicioAtividade = date ? date.format('yyyy-MM-DD') : null
                      if (!data?.id) {
                        const dtInicioContabilidade = dtInicioAtividade
                        setData({
                          ...data,
                          dtInicioAtividade,
                          dtInicioContabilidade,
                        })
                        return
                      }
                      setData({
                        ...data,
                        dtInicioAtividade,
                      })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <DatePicker
                    label="Fim Atividade"
                    size="small"
                    value={data.dtFimAtividade || null}
                    onChange={(date) => {
                      const dtFimAtividade = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtFimAtividade })
                    }}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <DatePicker
                    label="Início Contabilidade"
                    size="small"
                    value={data?.dtInicioContabilidade || null}
                    onChange={(date) => {
                      const dtInicioContabilidade = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtInicioContabilidade })
                    }}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <DatePicker
                    label="Fim Contabilidade"
                    size="small"
                    value={data.dtFimContabilidade || null}
                    onChange={(date) => {
                      const dtFimContabilidade = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtFimContabilidade })
                    }}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <DatePicker
                    label="Data Início da Inatividade"
                    size="small"
                    value={data.dtInativo || null}
                    onChange={(date) => {
                      const dtInativo = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtInativo })
                    }}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="eSocial"
              isOpen={collapseItens.eSocial}
              onClick={() => toggleCollapse('eSocial')}
              top={1}
            >
              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                  <DatePicker
                    name="dtInicioPrimeiraFaseESocial"
                    label="Início Primeira Fase"
                    size="small"
                    value={data?.dtInicioPrimeiraFaseESocial || null}
                    onChange={(date) => {
                      const dtInicioPrimeiraFaseESocial = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtInicioPrimeiraFaseESocial })
                    }}
                    validationErrors={validationErrors}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                  <DatePicker
                    name="dtInicioSegundaFaseESocial"
                    label="Início Segunda Fase"
                    size="small"
                    value={data?.dtInicioSegundaFaseESocial || null}
                    onChange={(date) => {
                      const dtInicioSegundaFaseESocial = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtInicioSegundaFaseESocial })
                    }}
                    validationErrors={validationErrors}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                  <DatePicker
                    name="dtInicioTerceiraFaseESocial"
                    label="Início Terceira Fase"
                    size="small"
                    value={data.dtInicioTerceiraFaseESocial || null}
                    onChange={(date) => {
                      const dtInicioTerceiraFaseESocial = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtInicioTerceiraFaseESocial })
                    }}
                    validationErrors={validationErrors}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Outras Informações"
              isOpen={collapseItens.outras}
              onClick={() => toggleCollapse('outras')}
              top={1}
            >
              <Grid container spacing={2}>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <TextField
                    label="Dias Contrato Experiencia"
                    fullWidth
                    value={data.diasContratoExperiencia || ''}
                    variant="outlined"
                    size="small"
                    onlyNumber
                    inputProps={{ maxLength: 9 }}
                    onChange={(e) => {
                      const diasContratoExperiencia = e.target.value
                      setData({ ...data, diasContratoExperiencia })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <TextField
                    label="Mês Pagamento 13° Primeira Parcela"
                    fullWidth
                    value={data.mesPagamentoDecimoTerceiroPrimeiraParcela || ''}
                    variant="outlined"
                    size="small"
                    onlyNumber
                    inputProps={{ maxLength: 2 }}
                    onChange={(e) => {
                      const mesPagamentoDecimoTerceiroPrimeiraParcela = e.target.value
                      setData({ ...data, mesPagamentoDecimoTerceiroPrimeiraParcela })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Indicador de Ponto Eletrônico"
                    options={indPontoEletronicoValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    required
                    validationErrors={validationErrors}
                    name="indPontoEletronico"
                    value={data.indPontoEletronico}
                    onChange={(e, obj) => {
                      const indPontoEletronico = obj ? obj.value : ''
                      setData({ ...data, indPontoEletronico })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label="Layout Ponto Eletrônico"
                    fullWidth
                    value={data?.pontoEletronicoLayout || 1}
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      const pontoEletronicoLayout = e?.target?.value || 1
                      setData({ ...data, pontoEletronicoLayout })
                    }}
                    select
                  >
                    <MenuItem value={1}>DataC</MenuItem>
                    <MenuItem value={9}>Terceiros</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <AutoCompleteContador
                    value={data?.contadorId || ''}
                    required
                    name="contadorId"
                    validationErrors={validationErrors}
                    onChange={(e, contador) => {
                      const contadorId = contador?.id || ''
                      setData((oldState) => ({
                        ...oldState,
                        contadorId,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label="Identificador Tabela de Rubricas"
                    fullWidth
                    value={data?.indTabelaRubricas || ''}
                    variant="outlined"
                    size="small"
                    disabled={_data?.indTabelaRubricas !== 'DataC' ? true : false}
                    inputProps={{ maxLength: 8 }}
                    onChange={(e) => {
                      const indTabelaRubricas = e?.target?.value || ''
                      setData((oldState) => ({
                        ...oldState,
                        indTabelaRubricas,
                      }))
                    }}
                  />
                </Grid>

                <Grid xl={6} lg={6} md={6} sm={12} xs={12} item>
                  <MUIAutoComplete
                    label="Indicador de Homologação"
                    options={IndHomologacaoValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    required
                    validationErrors={validationErrors}
                    name="indHomologacao"
                    value={data.indHomologacao}
                    onChange={(e, obj) => {
                      const indHomologacao = obj ? obj.value : ''
                      setData((oldState) => ({
                        ...oldState,
                        indHomologacao,
                      }))
                    }}
                  />
                </Grid>

                <Grid xl={6} lg={6} md={12} sm={12} xs={12} item>
                  <AutoCompleteCertificado
                    value={data?.certificadoDigitalId || ''}
                    optionId="id"
                    onChange={(e, certificadoDigital) => {
                      const certificadoDigitalId = certificadoDigital?.id || ''
                      setData((oldState) => ({
                        ...oldState,
                        certificadoDigitalId,
                      }))
                    }}
                    name="certificadoDigitalId"
                    validationErrors={validationErrors}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <DatePicker
                    label="Data de Conversão WEB"
                    size="small"
                    value={data.dtConversaoWeb || null}
                    onChange={(date) => {
                      const dtConversaoWeb = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtConversaoWeb })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <AutoCompleteGrupo
                    fullWidth
                    value={data?.grupoId || ''}
                    onChange={(e, grupo) => {
                      const grupoId = grupo?.id || ''
                      setData((oldState) => ({ ...oldState, grupoId }))
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Indicador Base de Cálculo Insalubridade"
                    options={IndBaseInsalubridadeValues}
                    renderOption={(option) => option?.name || ''}
                    optionId="value"
                    required
                    validationErrors={validationErrors}
                    name="indBaseCalculoInsalubridade"
                    value={data?.indBaseCalculoInsalubridade || ''}
                    onChange={(e, obj) => {
                      const indBaseCalculoInsalubridade = obj?.value || ''
                      setData((prev) => ({ ...prev, indBaseCalculoInsalubridade }))
                    }}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Informações sobre a Matrícula"
              isOpen={collapseItens.outras}
              onClick={() => toggleCollapse('outras')}
              top={1}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <MUIAutoComplete
                    label="Como a matrícula deve ser preenchida"
                    options={IndCadastroMatriculaValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    value={data.indCadastroMatricula}
                    onChange={(e, obj) => {
                      const indCadastroMatricula = obj.value
                      setData((oldState) => ({
                        ...oldState,
                        indCadastroMatricula,
                      }))
                    }}
                  />
                </Grid>

                {data.indCadastroMatricula === IndCadastroMatriculaEnum.Sequencial_01 && (
                  <Grid item xs={12} sm={4}>
                    <TextField
                      title="A matrícula informada será usada para calcular qual a próxima matrícula utilizada pelo Empregador"
                      label="Matrícula de Origem"
                      value={data.matriculaOrigem}
                      required
                      validationErrors={validationErrors}
                      name="matriculaOrigem"
                      onChange={(e) => {
                        const matriculaOrigem = e.target.value
                        setData((prev) => ({
                          ...prev,
                          matriculaOrigem,
                        }))
                      }}
                    />
                  </Grid>
                )}

                {(data.indCadastroMatricula === IndCadastroMatriculaEnum.Preencher_00 ||
                  data.indCadastroMatricula === IndCadastroMatriculaEnum.Sequencial_01) && (
                  <Grid item xs={12} sm={4}>
                    <TextField label="Matrícula Anterior" value={data.matricula} disabled />
                  </Grid>
                )}
              </Grid>
            </CollapseContent>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Parâmetros"
              isOpen={collapseItens.parametros}
              onClick={() => toggleCollapse('parametros')}
              top={1}
            >
              <Grid container spacing={1}>
                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Arredondamento"
                    control={
                      <Checkbox
                        checked={Boolean(data.isArredondamento)}
                        size="small"
                        onChange={(e) => {
                          const isArredondamento = e.target.checked
                          setData({ ...data, isArredondamento })
                        }}
                        color="secondary"
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Adiantamento Férias"
                    control={
                      <Checkbox
                        checked={Boolean(data.isAdiantamentoFerias)}
                        size="small"
                        onChange={(e) => {
                          const isAdiantamentoFerias = e.target.checked
                          setData({ ...data, isAdiantamentoFerias })
                        }}
                        color="secondary"
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Adiantamento Admissão"
                    control={
                      <Checkbox
                        checked={Boolean(data.isAdiantamentoAdmissao)}
                        size="small"
                        onChange={(e) => {
                          const isAdiantamentoAdmissao = e.target.checked
                          setData({ ...data, isAdiantamentoAdmissao })
                        }}
                        color="secondary"
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Adiantamento Proporcional"
                    control={
                      <Checkbox
                        checked={Boolean(data.isAdiantamentoProporcional)}
                        size="small"
                        onChange={(e) => {
                          const isAdiantamentoProporcional = e.target.checked
                          setData({
                            ...data,
                            isAdiantamentoProporcional,
                          })
                        }}
                        color="secondary"
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Adiantamento Vínculo Afastado"
                    control={
                      <Checkbox
                        checked={Boolean(data.isAdiantamentoVinculoAfastado)}
                        size="small"
                        onChange={(e) => {
                          const isAdiantamentoVinculoAfastado = e.target.checked
                          setData({
                            ...data,
                            isAdiantamentoVinculoAfastado,
                          })
                        }}
                        color="secondary"
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Descontar Faltas do Vale Transporte"
                    control={
                      <Checkbox
                        checked={Boolean(data.isDescontarFaltasVT)}
                        size="small"
                        onChange={(e) => {
                          const isDescontarFaltasVT = e.target.checked
                          setData({ ...data, isDescontarFaltasVT })
                        }}
                        color="secondary"
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Descontar Atestado do Vale Transporte"
                    control={
                      <Checkbox
                        checked={Boolean(data.isDescontarAtestadoVT)}
                        size="small"
                        onChange={(e) => {
                          const isDescontarAtestadoVT = e.target.checked
                          setData({ ...data, isDescontarAtestadoVT })
                        }}
                        color="secondary"
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Descontar Suspensão do Vale Transporte"
                    control={
                      <Checkbox
                        checked={Boolean(data.isDescontarSuspencaoVT)}
                        size="small"
                        onChange={(e) => {
                          const isDescontarSuspencaoVT = e.target.checked
                          setData({ ...data, isDescontarSuspencaoVT })
                        }}
                        color="secondary"
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Dia Máximo Mês"
                    control={
                      <Checkbox
                        checked={Boolean(data.isDiaMaximoMes)}
                        size="small"
                        onChange={(e) => {
                          const isDiaMaximoMes = e.target.checked
                          setData({ ...data, isDiaMaximoMes })
                        }}
                        color="secondary"
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Transmitir DCTF Web"
                    value={data.isDCTFWeb}
                    onChange={(e, value) => {
                      const isDCTFWeb = value
                      setData({
                        ...data,
                        isDCTFWeb,
                      })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Descontar DSR ao Atrasar"
                    value={data.isDSRAtraso}
                    onChange={(e, value) => {
                      const isDSRAtraso = value
                      setData({
                        ...data,
                        isDSRAtraso,
                      })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Descontar DSR ao Faltar"
                    value={data.isDSRFalta}
                    onChange={(e, value) => {
                      const isDSRFalta = value
                      setData({
                        ...data,
                        isDSRFalta,
                      })
                    }}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Validar Fechamento do eSocial"
                    value={data.isValidarFechamentoeSocial}
                    onChange={(e, value) => {
                      const isValidarFechamentoeSocial = value
                      setData({
                        ...data,
                        isValidarFechamentoeSocial,
                      })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Fazer Projeção de Aviso Prévio Indenizado para 13° Salário e Férias"
                    value={data?.isProjecaoAvisoPrevio || false}
                    onChange={(_, isProjecaoAvisoPrevio) => {
                      setData((prevState) => ({
                        ...prevState,
                        isProjecaoAvisoPrevio,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Usar 6% de desconto no Vale Transporte"
                    value={data?.isDescontarPercentValeTransporte || false}
                    onChange={(_, isDescontarPercentValeTransporte) => {
                      setData((prevState) => ({
                        ...prevState,
                        isDescontarPercentValeTransporte,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Buscar Guia DCTFWeb ao Processar Folha Automática"
                    value={data?.isFolhaAutoBuscaGuiaDCTFWebAuto || false}
                    onChange={(_, isFolhaAutoBuscaGuiaDCTFWebAuto) => {
                      setData((prevState) => ({
                        ...prevState,
                        isFolhaAutoBuscaGuiaDCTFWebAuto,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MUIAutoComplete
                    label="Indicador Impressão Eventos Recibo"
                    options={IndImprimirEventosValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    value={data.indImprimirEvento}
                    onChange={(e, obj) => {
                      const indImprimirEvento = obj ? obj.value : ''
                      setData((oldState) => ({
                        ...oldState,
                        indImprimirEvento,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ContentDivider title="Impressão" />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FormControlLabel
                    label="Imprimir PRÓ-LABORE com Data de Pagamento"
                    control={
                      <Checkbox
                        checked={Boolean(data.isPrintDtPgtoProLabore)}
                        size="small"
                        onChange={(e) => {
                          const isPrintDtPgtoProLabore = e.target.checked
                          setData({ ...data, isPrintDtPgtoProLabore })
                        }}
                        color="secondary"
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <CheckboxComponent
                    label="Não Imprimir Demonstrativo no PRÓ-LABORE"
                    value={data.isNaoImprimirDemonstrativoProLabore}
                    onChange={(e, value) => {
                      const isNaoImprimirDemonstrativoProLabore = value
                      setData({
                        ...data,
                        isNaoImprimirDemonstrativoProLabore,
                      })
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <CheckboxComponent
                    label="Contracheque, imprimir somente o demonstrativo"
                    title="Imprimir somente o demonstrativo ao fazer a impressão dos contracheques"
                    value={data?.isSomenteImprimirDemonstrativo || false}
                    onChange={(date, isSomenteImprimirDemonstrativo) => {
                      setData((prevState) => ({
                        ...prevState,
                        isSomenteImprimirDemonstrativo,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <CheckboxComponent
                    label="Unificar contracheque mensal"
                    title="Unificar os contracheques de um mesmo funcionário em um uníco contracheque"
                    value={data?.isJuntarContraCheques || false}
                    onChange={(date, isJuntarContraCheques) => {
                      setData((prevState) => ({
                        ...prevState,
                        isJuntarContraCheques,
                      }))
                    }}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>
        </Grid>
      </ContainerForm>
    </Box>
  )
}

export default Form
