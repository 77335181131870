import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'

import { ProcessarAlteracaoSalarialRequestDTO } from './dtos/ProcessarAlteracaoSalarialRequestDTO'

interface RequestProps {
  data: ProcessarAlteracaoSalarialRequestDTO
}

export function useProcessarAlteracaoSalarial() {
  const dialogNotification = useDialogNotification()
  async function handleRequest({ data }: RequestProps) {
    await api.post('/VinculoAlteracaoContratoCadastro/ProcessarAlteracaoSalarial', data)
  }
  return useMutation(handleRequest, {
    onSuccess: () => notifySuccess('Alterações Salariais processadas com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
