import { useState, useEffect } from 'react'

import api from '~/services/api-pessoal'

import { Divider, LinearProgress } from '@material-ui/core'

import { Button, Stack, StackContainer } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Form from './components/Form'
import Table from './components/Table'

import useDialog from '~/hooks/useDialog'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import { calcularDuracaoJornada } from '../Form'

import {
  HorarioTrabalhoIntervalo,
  HorarioTrabalhoIntervaloForm,
} from '~/hooks/queries/HorarioTrabalhoIntervalo/HorarioTrabalhoIntervalo'
import { TipoHorarioIntervaloEnum } from '~/@types/enums/TipoHorarioIntervaloEnum'
import { IndHorarioTrabalhoIntervaloEnum } from '~/@types/enums/IndHorarioTrabalhoIntervaloEnum'
import { HorarioTrabalho } from '~/hooks/queries/HorarioTrabalho/HorarioTrabalho'

interface HorarioTrabalhoItemProps {
  horarioTrabalho: HorarioTrabalho
  onAfterSubmitForm: (event: 'update', data: HorarioTrabalho) => void
}

export default function HorarioTrabalhoItem({
  horarioTrabalho,
  onAfterSubmitForm,
}: HorarioTrabalhoItemProps) {
  const [collection, setCollection] = useState<HorarioTrabalhoIntervalo[]>([])

  const [isLoading, setLoading] = useState(false)
  const [isDeleting, setDeleting] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<HorarioTrabalhoIntervaloForm | null>(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<string | null>(null)

  useEffect(() => {
    const getCollection = async () => {
      if (!horarioTrabalho.id) return
      setLoading(true)
      try {
        const response = await api.get('/HorarioTrabalhoIntervalo/GetByHorarioTrabalho', {
          params: {
            horarioTrabalhoId: horarioTrabalho.id,
          },
        })
        setCollection(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horarioTrabalho.id])

  const handleClickItem = (event: 'edit' | 'delete', id: string) => {
    const handleClickEditItem = () => {
      const item = collection.find((i) => i.id === id)
      if (item) openForm(item)
    }

    const handleClickDeleteItem = () => {
      openConfirmDelete(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event]()
  }

  const handleDeleteIntervalo = async () => {
    setDeleting(true)
    try {
      await api.delete(`/horarioTrabalhoIntervalo/${dataConfirmDelete}`)
      const newItens = collection.filter((i) => i.id !== dataConfirmDelete)
      await atualizarDuracaoJornada(newItens)
      closeConfirmDelete()
      setCollection(newItens)
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setDeleting(false)
    }
  }

  const handleAfterSubmitFormIntervalo = (
    event: 'insert' | 'update',
    value: HorarioTrabalhoIntervalo,
  ) => {
    const handleAfterInsert = async () => {
      const itens = collection
      const newItens = [value, ...itens]
      await atualizarDuracaoJornada(newItens)
      setCollection(newItens)
    }

    const handleAfterUpdate = async () => {
      const itens = collection
      const newItens = itens.map((i) => (i.id === value.id ? value : i))
      await atualizarDuracaoJornada(newItens)
      setCollection(newItens)
    }

    const functions = {
      insert: handleAfterInsert,
      update: handleAfterUpdate,
    }

    closeForm()

    functions[event]()
  }

  const atualizarDuracaoJornada = async (intervalos: HorarioTrabalhoIntervalo[]) => {
    if (!horarioTrabalho.horaSaida || !horarioTrabalho.horaEntrada) return
    setSubmitting(true)
    try {
      const intervalosFiltrados = intervalos.filter(
        (d) => d.indIntervalo === IndHorarioTrabalhoIntervaloEnum.Refeicao_00,
      )
      const duracaoJornada = calcularDuracaoJornada(
        horarioTrabalho.horaSaida,
        horarioTrabalho.horaEntrada,
        intervalosFiltrados,
      )
      const response = await api.put(`/horarioTrabalho/${horarioTrabalho.id}`, {
        ...horarioTrabalho,
        duracaoJornada,
      })
      const responseCurrent = response.data.data
      onAfterSubmitForm('update', responseCurrent)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Stack>
      <Divider />
      <Stack alignItems="end">
        <Button
          size="small"
          color="primary"
          onClick={() =>
            openForm({
              horarioTrabalhoId: horarioTrabalho.id,
              tipoIntervalo: TipoHorarioIntervaloEnum.Fixo_01,
              indIntervalo: IndHorarioTrabalhoIntervaloEnum.Refeicao_00,
              duracaoIntervalo: null,
              inicioIntervalo: null,
              fimIntervalo: null,
              fbCodigo: null,
            })
          }
          variant="contained"
        >
          Adicionar
        </Button>
      </Stack>
      {isSubmitting && <LinearProgress />}
      <Divider />

      <StackContainer p={0} mb={1}>
        <Table data={collection} isLoading={isLoading} onItemClick={handleClickItem} />
      </StackContainer>

      {isOpenForm && (
        <Form
          isOpen={isOpenForm}
          data={dataForm}
          onClose={closeForm}
          onAfterSubmitFormIntervalo={handleAfterSubmitFormIntervalo}
        />
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isDeleting}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteIntervalo}
        />
      )}
    </Stack>
  )
}
