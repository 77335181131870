import { useState } from 'react'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import { Hora } from '~/components/Displays'
import { ButtonBox, ToolsDataTable } from '~/components'

import { HorarioTrabalho } from '~/hooks/queries/HorarioTrabalho/HorarioTrabalho'
import { MUIDataTableColumnDef } from 'mui-datatables'

interface TableProps {
  data: HorarioTrabalho[]
  onItemClick: (event: 'delete' | 'edit', value: string) => void
  isLoading: boolean
  query: string
  expandedChildren: (index: number) => void
}

const Table = ({ data, onItemClick, isLoading, query, expandedChildren }: TableProps) => {
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([])

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'horaEntrada',
      label: 'Entrada',
      options: {
        customBodyRender: (value) => <Hora value={value} />,
      },
    },
    {
      name: 'horaSaida',
      label: 'Saida',
      options: {
        customBodyRender: (value) => <Hora value={value} />,
      },
    },
    {
      name: 'duracaoJornada',
      label: 'Duração da Jornada',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      disableAutoHeight
      optionsExpandable={{
        onRowExpanded: setRowsExpanded,
        rowsExpanded: rowsExpanded,
        type: 'single',
        expandedChildren,
      }}
      sherlock={{
        query,
        columns: ['codigo', 'nome'],
      }}
    />
  )
}

export default Table
