import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

const query = '/ModeloRelatorio/ObterArquivo'

interface RequestProps {
  params: {
    id: string
  }
}

export function useObterArquivo({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: string }>(query, { params })
    return response.data.data
  }

  return useQuery(query, handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
