import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { FolhaAutomaticaDataEmpDTO } from './dtos/FolhaAutomaticaDataEmpDTO'

const query = '/FolhaAutomatica/ObterTarefasPeriodicasFolha'

interface RequestProps {
  params: { anoMes: string; grupoId?: string }
}

export function useObterTarefasPeriodicasFolha({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{
      data: FolhaAutomaticaDataEmpDTO[]
    }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useInvalidate() {
  const queryClient = useQueryClient()
  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }
  return { handleInvalidate }
}
