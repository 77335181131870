import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { AlteracaoSalarialColetivaSimularDTO } from './dtos/AlteracaoSalarialColetivaSimularDTO'
import { AlteracaoSalarialColetivaVinculoDTO } from './dtos/AlteracaoSalarialColetivaVinculoDTO'

const query = '/AlteracaoSalarialColetiva/ObterVinculosSimulados'

interface RequestProps {
  data: AlteracaoSalarialColetivaSimularDTO
}

export function useObterVinculosSimulados({ data }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{
      data: AlteracaoSalarialColetivaVinculoDTO[]
    }>(query, data)
    return response.data.data
  }

  return useQuery([query, data], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled: false,
  })
}

export function useResetQuery() {
  const queryClient = useQueryClient()
  function handle() {
    queryClient.resetQueries(query)
  }
  return { handle }
}
