import { formatToCPFOrCNPJ } from 'brazilian-values'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { CopyLabel, ToolsDataTable } from '~/components'

import { RelConsultaLancamentoVinculoDataRPDTO } from '~/hooks/queries/RelConsultaLancamentoVinculo/dtos/RelConsultaLancamentoVinculoDataClientDTO'
import { formatAnoMes } from '~/hooks/useUtils'
import { formatCurrency } from '~/utils/utils'

interface RecibosTableProps {
  data: RelConsultaLancamentoVinculoDataRPDTO[]
}
export default function RecibosTable({ data }: RecibosTableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'estabelecimentoCodigo',
      label: 'Código',
    },
    {
      name: 'estabelecimentoNrInscricao',
      label: 'Nr. Inscrição',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'estabelecimentoNome',
      label: 'Estabelecimento',
    },
    {
      name: 'periodo',
      label: 'Período',
      options: {
        customBodyRender: formatAnoMes,
      },
    },
    {
      name: 'vrServico',
      label: 'Valor do Serviço',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: 'vrBaseINSS',
      label: 'Valor Base INSS',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: 'vrINSS',
      label: 'Valor INSS',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
        customBodyRender: (value) => formatCurrency(value),
      },
    },
  ]

  return <ToolsDataTable columns={columns} data={data} disableAutoHeight pagination={false} />
}
