import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { PisoEnfermagemFuncionarioDTO } from './dtos/PisoEnfermagemFuncionarioDTO'
import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  data: PisoEnfermagemFuncionarioDTO[]
  params: {
    dtPagamento: Date | string
    eventoMensalCodigo: string
    eventoFeriasCodigo: string
  }
}

export function useProcessar() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    await api.post('/PisoEnfermagem/Processar', data, { params })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Piso Enfermagem foi processado com sucesso.')
    },
    onError: dialogNotification.extractErrors,
  })
}
