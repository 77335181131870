import { useEffect, useRef, useState, memo } from 'react'

import * as yup from 'yup'

import { Grid, Paper } from '@material-ui/core'

import { Button, ContainerTable, Finder, Stack, TextField } from '~/components'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

import Table from './Table'

import useAmbiente from '~/hooks/useAmbiente'
import useSocket from '~/hooks/useSocket'
import useDialogNotification from '~/hooks/useDialogNotification'
import useLocalStorage from '~/hooks/useLocalStorage'

import { useConfigurarAgendamentoPeriodico } from '~/hooks/queries/FolhaAutomatica/useConfigurarAgendamentoPeriodico'
import { useObterTarefasPeriodicasFolha } from '~/hooks/queries/FolhaAutomatica/useObterTarefasPeriodicasFolha'

import moment from 'moment'
import useValidationErrors from '~/hooks/useValidationErrors'
import constants from '~/utils/constants'

const schema = yup.object().shape({
  dtAgendamento: yup
    .date()
    .required('Informe a Data do Agendamento')
    .typeError('Informe uma Data válida')
    .nullable()
    .test('is-future-date', 'A data e hora devem ser maiores que a data e hora atual', (value) => {
      if (!value) return false
      return value > new Date()
    }),
})

const MemoTable = memo(Table)

export default function Agendamento() {
  const [dataForm, setDataForm] = useState<{ dtAgendamento: string | null }>({
    dtAgendamento: null,
  })
  // const [query, setQuery] = useState('')
  const [query, setQuery] = useLocalStorage(constants.query, '')
  const [grupoId, setGrupoId] = useLocalStorage(constants.grupoId, '')

  const tableRef = useRef<FixLater>(null)

  const { empresaId, anoMes, ano, mes } = useAmbiente()
  const dialogNotification = useDialogNotification()

  const [anoMesAntigo, setAnoMesAntigo] = useState<string | null>(null)

  const prevAnoMesRef = useRef<string | null>(null)
  useEffect(() => {
    if (anoMes !== prevAnoMesRef.current) {
      setAnoMesAntigo(prevAnoMesRef.current)
      prevAnoMesRef.current = anoMes
    }
  }, [anoMes])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data: dataForm,
  })

  const { mutateAsync, isLoading: isSubmitting } = useConfigurarAgendamentoPeriodico()
  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useObterTarefasPeriodicasFolha({
    params: {
      anoMes,
      grupoId,
    },
  })
  const data = _d || []

  useEffect(() => {
    setDataForm({
      dtAgendamento: moment()
        .set('year', ano)
        .set('month', parseInt(mes) - 1)
        .format('yyyy-MM-DDTHH:mm'),
    })
  }, [ano, mes])

  const socket = useSocket({
    urlHub: '/TaskQueueHub',
    onStartConnection: (socket) => {
      socket.send('AddToGroup', empresaId + anoMes)
    },
  }) // ATUALZIAR NOMES PARA AGENDAR e NAO  PERIODICO

  useEffect(() => {
    if (!socket) return
    socket.on('UpdateTask', () => refetch())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  useEffect(() => {
    if (!socket) return
    if (anoMesAntigo) {
      socket.send('RemoveFromGroup', empresaId + anoMesAntigo)
    }
    socket.send('AddToGroup', empresaId + anoMes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, empresaId, anoMesAntigo])

  async function handleSubmit() {
    const rowsSelected: number[] = tableRef?.current?.state?.selectedRows?.data.map(
      (d: FixLater) => d.dataIndex,
    )

    if (rowsSelected.length === 0) {
      return dialogNotification.warning({
        descriptions: ['Informe os Empregadores para prosseguir'],
      })
    }

    const empregadoresIds = rowsSelected.map((index) => data[index].empregadorId)

    await mutateAsync({
      data: empregadoresIds,
      params: {
        dtAgendamento: dataForm.dtAgendamento as string,
      },
    })
  }

  return (
    <Stack height="100%">
      <Stack
        component={Paper}
        p={1}
        orientation="horizontal"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              type="datetime-local"
              label="Data de Agendamento"
              InputLabelProps={{
                shrink: true,
              }}
              value={dataForm.dtAgendamento}
              onChange={(e) => {
                const dtAgendamento = e.target.value || null
                setDataForm((prev) => ({ ...prev, dtAgendamento }))
              }}
              validationErrors={validationErrors}
              name="dtAgendamento"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <AutoCompleteGrupo
              value={grupoId}
              onChange={(e, grupo) => {
                setGrupoId(grupo?.id || '')
              }}
            />
          </Grid>
        </Grid>
        <Finder
          isOpen={true}
          defaultValue={query}
          onSearch={setQuery}
          onClose={() => setQuery('')}
        />
      </Stack>

      <ContainerTable>
        <MemoTable
          data={data}
          query={query}
          isLoading={isLoading}
          isFetching={isFetching}
          tableRef={tableRef}
        />
      </ContainerTable>

      <Stack alignItems="end" component={Paper} p={1}>
        <Button variant="contained" onClick={handleValidate} isLoading={isSubmitting}>
          Agendar Empregadores
        </Button>
      </Stack>
    </Stack>
  )
}
