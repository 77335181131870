import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import { openDownloadData } from '~/hooks/useUtils'

export interface RequestProps {
  empregadorId: string
  anoBase: number
  cpfResponsavelEmpresa: string
  cpfResponsavelArquivo: string
}

export function useDIRFGerarArquivo() {
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  async function handleRequest(params: RequestProps) {
    const response = await api.post<Blob>('/DIRF/GerarArquivo', null, {
      responseType: 'arraybuffer',
      params,
    })

    // const contentDisposition = response.headers['content-disposition']
    const filename = 'DIRF.txt'

    // if (contentDisposition) {
    //   const utf8FilenameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/)
    //   const asciiFilenameMatch = contentDisposition.match(/filename="(.+?)"/)

    //   if (utf8FilenameMatch) {
    //     filename = decodeURIComponent(utf8FilenameMatch[1])
    //   } else if (asciiFilenameMatch) {
    //     filename = asciiFilenameMatch[1]
    //   }
    // }

    openDownloadData(filename, response.data)

    notification.success('Arquivo DIRF gerado com sucesso')
  }

  return useMutation(handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
