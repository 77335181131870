import { useState, useEffect } from 'react'

import { PageHeader, Finder, ButtonBox, StackContainer, ContainerTable, Button } from '~/components'

import Table from './components/Table'
import Form from './components/Form'
import HorarioTrabalhoItem from './components/HorarioTrabalhoItem'

import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import api from '~/services/api-pessoal'

import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'

import { HorarioTrabalho } from '~/hooks/queries/HorarioTrabalho/HorarioTrabalho'
import useDialog from '~/hooks/useDialog'

const HorarioTrabalhoPage = () => {
  const [isDeleting, setDeleting] = useState(false)
  const [collection, setCollection] = useState<{ isLoading: boolean; itens: HorarioTrabalho[] }>({
    isLoading: false,
    itens: [],
  })

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<HorarioTrabalho | null>(null)

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  const [query, setQuery] = useState('')

  const notification = useNotification()
  const { empregador } = useAmbiente()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    const getCollection = async () => {
      if (!empregador?.id) return
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))

      try {
        const response = await api.get(`/horarioTrabalho/${empregador.id}/selecionarEmpregador`)
        if (response.data.data) {
          setCollection((oldState) => ({
            ...oldState,
            itens: response.data.data,
            isLoading: false,
          }))
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
    getCollection()
    // eslint-disable-next-line
  }, [empregador?.id])

  const handleClickItem = (event: 'edit' | 'delete', value: string) => {
    const handleClickEditItem = () => {
      const { itens } = collection
      const item = itens.find((i) => i.id === value)
      if (item) openForm(item)
    }

    const handleClickDeleteItem = () => {
      openConfirmDelete(value)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event]()
  }

  const handleDeleteItem = async () => {
    const itens = collection.itens
    setDeleting(true)
    try {
      await api.delete(`/horarioTrabalho/${dataConfirmDelete}`)
      const newItens = itens.filter((i) => i.id !== dataConfirmDelete)
      closeConfirmDelete()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setDeleting(false)
    }
  }

  const handleAfterSubmitForm = (event: 'insert' | 'update', value: HorarioTrabalho) => {
    const handleAfterInsert = () => {
      const { itens } = collection
      const newItens = [value, ...itens]
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
    }

    const handleAfterUpdate = () => {
      const { itens } = collection
      const newItens = itens.map((i) => (i.id === value.id ? value : i))
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
    }

    const functions = {
      insert: handleAfterInsert,
      update: handleAfterUpdate,
    }

    closeForm()

    functions[event]()
  }

  return (
    <StackContainer>
      <PageHeader title="Horário de Trabalho">
        <ButtonBox>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
          <Button
            size="small"
            color="primary"
            onClick={() =>
              openForm({
                permiteHorarioFlexivel: false,
                empregadorId: empregador.id,
              } as HorarioTrabalho)
            }
            variant="contained"
          >
            Adicionar
          </Button>
        </ButtonBox>
      </PageHeader>

      <ContainerTable>
        <Table
          data={collection.itens}
          isLoading={collection.isLoading}
          query={query}
          onItemClick={handleClickItem}
          expandedChildren={(index) => (
            <HorarioTrabalhoItem
              horarioTrabalho={collection.itens[index]}
              onAfterSubmitForm={handleAfterSubmitForm}
            />
          )}
        />
      </ContainerTable>

      {isOpenForm && (
        <Form
          isOpen={isOpenForm}
          data={dataForm}
          onClose={closeForm}
          onAfterSubmitForm={handleAfterSubmitForm}
        />
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isDeleting}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
        />
      )}
    </StackContainer>
  )
}

export default HorarioTrabalhoPage
