import { useState, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { ActionDialog, ButtonBox, Button, TextField, DatePickerNew } from '~/components'

import * as yup from 'yup'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import api from '~/services/api-pessoal'
import { VinculoHorario } from '~/hooks/queries/VinculoHorario/VinculoHorario'

const schema = yup.object().shape({
  dtIncio: yup.string().required('Informe a Data de Início').nullable(),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoHorario | null
  onAfterSubmitForm: (event: 'update' | 'insert', data: VinculoHorario) => void
  isFinalizado: boolean
}

export default function Form({
  isOpen,
  onClose,
  data: _data,
  onAfterSubmitForm,
  isFinalizado,
}: FormProps) {
  const [data, setData] = useState(_data || ({} as VinculoHorario))
  const [isSubmitting, setSubmitting] = useState(false)
  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put<{ data: VinculoHorario }>(`/VinculoHorario/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/VinculoHorario', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()

    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Horário de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'xs',
        fullWidth: true,
      }}
      customActions={
        !isFinalizado ? (
          <ButtonBox bottom={1} right={1}>
            <Button color="primary" onClick={onClose}>
              Cancelar
            </Button>
            <Button color="primary" variant="contained" onClick={handleValidate}>
              Salvar
            </Button>
          </ButtonBox>
        ) : (
          <></>
        )
      }
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DatePickerNew
            label="Data de início"
            size="small"
            value={data?.dtIncio || null}
            validationErrors={validationErrors}
            name="dtIncio"
            required
            onChange={(date) => {
              const dtIncio = date || ''
              setData({ ...data, dtIncio })
            }}
            disabled={isFinalizado ? true : false}
            title={
              isFinalizado ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho' : ''
            }
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data?.observacao || ''}
            size="small"
            inputProps={{
              maxLength: 200,
            }}
            multiline
            rows={4}
            onChange={(e) => {
              const observacao = e?.target.value || ''
              setData({ ...data, observacao })
            }}
            disabled={isFinalizado ? true : false}
            title={
              isFinalizado ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho' : ''
            }
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
