import { convertToArrayDescription } from '~/utils/utils'

export enum CodigoIncidenciaPISPASEPEnum {
  NaoEBaseCalculoPISPASEP_0 = 0,
  BaseCalculoPISPASEPMensal_11 = 11,
  BaseCalculoPISPASEP13Salario_12 = 12,
  IncidenciaSuspensaDecorrenciaDecisaoJudicialPISPASEPMensal_91 = 91,
  IncidenciaSuspensaDecorrenciaDecisaoJudicialPISPASEP13Salario_92 = 92,
}

export const CodigoIncidenciaPISPASEPLabels = {
  [CodigoIncidenciaPISPASEPEnum.NaoEBaseCalculoPISPASEP_0]:
    '00 - Não é base de cálculo do PIS/PASEP',
  [CodigoIncidenciaPISPASEPEnum.BaseCalculoPISPASEPMensal_11]:
    '11 - Base de cálculo do PIS/PASEP mensal',
  [CodigoIncidenciaPISPASEPEnum.BaseCalculoPISPASEP13Salario_12]:
    '12 - Base de cálculo do PIS/PASEP 13° salário',
  [CodigoIncidenciaPISPASEPEnum.IncidenciaSuspensaDecorrenciaDecisaoJudicialPISPASEPMensal_91]:
    '91 - Incidência suspensa em decorrência de decisão judicial - PIS/PASEP mensal',
  [CodigoIncidenciaPISPASEPEnum.IncidenciaSuspensaDecorrenciaDecisaoJudicialPISPASEP13Salario_92]:
    '92 - Incidência suspensa em decorrência de decisão judicial - PIS/PASEP 13º salário',
}

export const CodigoIncidenciaPISPASEPValues = convertToArrayDescription(
  CodigoIncidenciaPISPASEPEnum,
  CodigoIncidenciaPISPASEPLabels,
)
