import { IconButton } from '@material-ui/core'
import {
  Description as DescriptionIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'

import { ButtonBox, ToolsDataTable } from '~/components'

import { useObterModelos } from '~/hooks/queries/ModeloRelatorio/useObterModelos'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { ModeloRelatorioSimplesDTO } from '~/hooks/queries/ModeloRelatorio/dtos/ModeloRelatorioSimplesDTO'

export type DataActions = 'delete' | 'edit' | 'open'

interface TableProps {
  query: string
  onItemClick: (event: DataActions, data: ModeloRelatorioSimplesDTO) => void
}

export default function Table({ query, onItemClick }: TableProps) {
  const { data: _d, isLoading, isFetching } = useObterModelos()

  const data = _d || []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'descricao',
      label: 'Descrição',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Abrir relatório"
                color="primary"
                aria-label="Relatorio"
                onClick={() => {
                  const dt = data.find((d) => d.id === value)
                  if (dt) onItemClick('open', dt)
                }}
                size="small"
              >
                <DescriptionIcon />
              </IconButton>

              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dt = data.find((d) => d.id === value)
                  if (dt) onItemClick('edit', dt)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  const dt = data.find((d) => d.id === value)
                  if (dt) onItemClick('delete', dt)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      disableAutoHeight
      sherlock={{
        query,
        columns: ['nome', 'descricao'],
      }}
    />
  )
}
