import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { PisoEnfermagemFuncionarioDTO } from './dtos/PisoEnfermagemFuncionarioDTO'

const query = '/PisoEnfermagem/ObterFuncionarios'

interface RequestProps {
  params: {
    anoMes: string
    estabelecimentoId: string
    cargoId: string | null
  }
}

export function useObterFuncionarios({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()
  const queryClient = useQueryClient()

  async function handleRequest() {
    const response = await api.get<{ data: PisoEnfermagemFuncionarioDTO[] }>(query, {
      params,
    })
    return response.data.data
  }

  function remountQuery(dtNova: PisoEnfermagemFuncionarioDTO) {
    queryClient.setQueriesData<PisoEnfermagemFuncionarioDTO[]>(query, (dataOld) => {
      if (!dataOld) return []
      return dataOld.map((oldRow) => {
        if (oldRow.id === dtNova.id) {
          return dtNova
        }
        return oldRow
      })
    })
  }

  function remountQueryList(dtNova: PisoEnfermagemFuncionarioDTO[]) {
    queryClient.setQueriesData<PisoEnfermagemFuncionarioDTO[]>(query, dtNova)
  }

  function resetQuery() {
    queryClient.resetQueries()
  }

  const queryResult = useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })

  return { ...queryResult, remountQuery, remountQueryList, resetQuery }
}
