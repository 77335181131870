import { useState } from 'react'

import {
  Box,
  CircularProgress,
  Divider,
  LinearProgress,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'

import { Finder, Stack } from '~/components'

import { useObterTags } from '~/hooks/queries/ModeloRelatorio/useObterTags'

const useStyles = makeStyles(() => ({
  rowRoot: {
    '&:hover': {
      transition: '0.5s',
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
  textFormat: {
    whiteSpace: 'nowrap',
  },
}))

interface TagViewerProps {
  onClick: (tag: string) => void
}

export default function TagViewer({ onClick }: TagViewerProps) {
  const [query, setQuery] = useState('')

  const classes = useStyles()
  const { data: _d, isLoading, isFetching } = useObterTags()
  const _data = _d || []

  const data = query
    ? _data.filter((d) => d.nome.toLowerCase().includes(query.toLowerCase()))
    : _data

  if (isLoading) {
    return (
      <Box height="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={70} />
      </Box>
    )
  }

  return (
    <Stack height="100%" gap={0}>
      <Stack alignItems="center" justifyContent="space-between" orientation="horizontal">
        <Typography
          style={{
            fontWeight: 'bold',
            color: 'gray',
          }}
        >
          TAGS
        </Typography>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </Stack>
      <Divider />
      <Box height={4}>{isFetching && <LinearProgress />}</Box>

      <Box flex={1} position="relative" minHeight={300} overflow="auto">
        <Box position="absolute" width="100%">
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.idf}
                    onClick={() => onClick(row.nome)}
                    className={classes.rowRoot}
                  >
                    <TableCell className={classes.textFormat}>
                      <Tooltip title={row.descricao}>
                        <Box width="100%">{row.nome}</Box>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Divider />
    </Stack>
  )
}
